import { useAppSelector, usePlatform } from '../redux-file/hooks';
import { RootState } from '../redux-file/store';
import {
  OrganizationDataOutSchema,
  ProductPlatform,
} from '../utils/api.interfaces';
import { RequiredStateError } from './exceptions';

// eslint-disable-next-line import/prefer-default-export, no-unused-vars
export const useRequiredSelector = <TProp>(
  // eslint-disable-next-line no-unused-vars
  selector: (state: RootState) => TProp | undefined
) =>
  useAppSelector((state) => {
    const result = selector(state);
    if (result === undefined) {
      throw new RequiredStateError();
    }
    return result;
  });

function assertPropertyRequired<T, K extends keyof T>(
  obj: T,
  key: K
): asserts obj is T & Record<K, NonNullable<T[K]>> {
  if (obj[key] === undefined || obj[key] === null) {
    throw new RequiredStateError();
  }
}

export const useOrganization = () => {
  const { activeOrganization } = usePlatform();
  if (activeOrganization === null) throw new RequiredStateError();
  return activeOrganization;
};

export const useProductOrganization = <T extends ProductPlatform>(
  product: T
): OrganizationDataOutSchema &
  Record<T, NonNullable<OrganizationDataOutSchema[T]>> => {
  const organization = useOrganization();
  assertPropertyRequired(organization, product);
  return organization;
};

export const useGhgOrganization = () =>
  useProductOrganization(ProductPlatform.Co2);
export const useEsgOrganization = () =>
  useProductOrganization(ProductPlatform.Esg);
export const useLcaOrganization = () =>
  useProductOrganization(ProductPlatform.Lca);
