import { useEffect } from 'react';
import { useAppSelector } from '../redux-file/hooks';
import { useTranslation } from 'react-i18next';

export default function LanguageProvider() {
  const { language } = useAppSelector((state) => state.platform);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return null;
}
