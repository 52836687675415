import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locale/en/en.json';
import pl from './locale/pl/pl.json';
import ghgEn from './locale/en/ghg.json';
import ghgPl from './locale/pl/ghg.json';
import esgEn from './locale/en/esg.json';
import esgPl from './locale/pl/esg.json';
import superadminEn from './locale/en/superadmin.json';
import superadminPl from './locale/pl/superadmin.json';
import registrationEn from './locale/en/registration.json';
import registrationPl from './locale/pl/registration.json';
import superdashboardGhgPl from './locale/pl/superdashboardGhg.json';
import superdashboardGhgEn from './locale/en/superdashboardGhg.json';
import registrationBnpPl from './locale/pl/registration_bnp.json';

const resources = {
  en: {
    translation: {
      ...en,
      esg: esgEn,
      ghg: ghgEn,
      superdashboardGhg: superdashboardGhgEn,
      superadmin: superadminEn,
      registration: registrationEn,
      registration_bnp: registrationBnpPl, // the same for both languages
    },
  },
  pl: {
    translation: {
      ...pl,
      esg: esgPl,
      ghg: ghgPl,
      superdashboardGhg: superdashboardGhgPl,
      superadmin: superadminPl,
      registration: registrationPl,
      registration_bnp: registrationBnpPl,
    },
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: localStorage.getItem('localLanguage') || 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
