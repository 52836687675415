import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux-file/hooks';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../../utils/timeConverter';
import IconSvg from '../IconSvg';
import {
  CalendarButtonWrapper,
  CalendarOverlay,
  CalendarPaneButton,
  CalendarPaneButtonsWrapper,
  CalendarPaneHeaderWrapper,
  CalendarPaneTitle,
  CalendarPaneWrapper,
  CalendarWeekDayLabel,
  CalendarWeekDayLabelWrapper,
  CalendarWrapper,
  IntuitiveCalendarCollapsableButton,
  IntuitiveCalendarCollapsableWrapper,
  IntuitiveCalendarModal,
} from './index.styles';
import {
  CalendarButtonProps,
  CalendarPaneProps,
  CalendarPeriodProps,
  CalendarProps,
} from './index.interfaces';

function IntuitiveCalendarButton({
  label,
  active,
  buttonDate,
  handleClick,
  selectingRange,
  setSelectedRangeEndDate,
  reportingPeriod,
}: CalendarButtonProps) {
  const handleMouseOver = (mouseOverDate: Date) => {
    setSelectedRangeEndDate(mouseOverDate);
  };
  return (
    <CalendarButtonWrapper active={active} reportingPeriod={reportingPeriod}>
      <button
        type="button"
        onClick={() => active !== 'disabled' && handleClick(buttonDate)}
        onMouseOver={() =>
          active !== 'disabled' && selectingRange && handleMouseOver(buttonDate)
        }
      >
        {label}
      </button>
    </CalendarButtonWrapper>
  );
}

function IntuitiveCalendarButtonEmpty() {
  return <CalendarButtonWrapper active="disabled" reportingPeriod="M" />;
}

function IntuitiveCalendarPane({
  paneLabel,
  paneDate,
  leftArrow,
  rightArrow,
  startDate,
  endDate,
  minDate,
  maxDate,
  selectedRangeEndDate,
  setSelectedRangeEndDate,
  handleClick,
  reportingPeriod,
  paneWidth,
}: CalendarPaneProps) {
  const { t } = useTranslation();
  const getDateActiveStatus = (
    buttonDate: Date,
    leftDate: Date | null,
    rightDate: Date | null,
    minPossibleDate: Date | null,
    maxPossibleDate: Date | null
  ): 'full' | 'half' | 'disabled' | null => {
    if (
      (minPossibleDate && buttonDate < minPossibleDate) ||
      (maxPossibleDate && buttonDate > maxPossibleDate)
    ) {
      return 'disabled';
    }

    const leftTime = leftDate?.getTime();
    const rightTime = rightDate?.getTime();
    const time = buttonDate.getTime();
    if (time === leftTime || time === rightTime) {
      return 'full';
    }
    if (leftTime && rightTime && time > leftTime && time < rightTime) {
      return 'half';
    }
    return null;
  };

  const getDates = () => {
    const months = [
      t('popup.short-months.January'),
      t('popup.short-months.February'),
      t('popup.short-months.March'),
      t('popup.short-months.April'),
      t('popup.short-months.May'),
      t('popup.short-months.June'),
      t('popup.short-months.July'),
      t('popup.short-months.August'),
      t('popup.short-months.September'),
      t('popup.short-months.October'),
      t('popup.short-months.November'),
      t('popup.short-months.December'),
    ];
    const daysInMonth = new Date(
      paneDate.getFullYear(),
      paneDate.getMonth() + 1,
      0
    ).getDate();

    switch (reportingPeriod) {
      case 'M':
        return months.map((month, ix) => ({
          label: month,
          date: new Date(paneDate.getFullYear(), ix),
        }));
      case 'Q':
        return Array.from(Array(4).keys()).map((quarter) => ({
          label: `${months[3 * quarter]} - ${months[3 * quarter + 2]}`,
          date: new Date(paneDate.getFullYear(), 3 * quarter),
        }));
      case 'Y':
        return Array.from(Array(10).keys()).map((decadeYear) => ({
          label: `${Math.floor(paneDate.getFullYear() / 10) * 10 + decadeYear}`,
          date: new Date(
            Math.floor(paneDate.getFullYear() / 10) * 10 + decadeYear,
            0
          ),
        }));
      default:
        return Array.from(Array(daysInMonth).keys()).map((dayNumber) => ({
          label: `${dayNumber + 1}`,
          date: new Date(
            paneDate.getFullYear(),
            paneDate.getMonth(),
            dayNumber + 1
          ),
        }));
    }
  };

  const dates = useMemo(getDates, [reportingPeriod, paneDate, t]);

  return (
    <CalendarPaneWrapper width={paneWidth}>
      <CalendarPaneHeaderWrapper>
        <CalendarPaneButton>
          {leftArrow && (
            <button type="button" onClick={() => leftArrow()}>
              <IconSvg name="arrow-left" className="arrow" />
            </button>
          )}
        </CalendarPaneButton>
        <CalendarPaneTitle>{paneLabel}</CalendarPaneTitle>
        <CalendarPaneButton>
          {rightArrow && (
            <button type="button" onClick={() => rightArrow()}>
              <IconSvg name="arrow-right" className="arrow" />
            </button>
          )}
        </CalendarPaneButton>
      </CalendarPaneHeaderWrapper>
      {!reportingPeriod && (
        <CalendarWeekDayLabelWrapper>
          <CalendarWeekDayLabel>
            {t('plotly.days.Mon').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Tue').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Wed').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Thu').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Fri').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Sat').charAt(0)}
          </CalendarWeekDayLabel>
          <CalendarWeekDayLabel>
            {t('plotly.days.Sun').charAt(0)}
          </CalendarWeekDayLabel>
        </CalendarWeekDayLabelWrapper>
      )}
      <CalendarPaneButtonsWrapper reportingPeriod={reportingPeriod}>
        {!reportingPeriod && [
          ...Array.from(Array((6 + paneDate.getDay()) % 7).keys()).map((ix) => (
            <IntuitiveCalendarButtonEmpty key={ix} />
          )),
          ...dates.map(({ label, date }, ix) => (
            <IntuitiveCalendarButton
              key={ix + 7}
              active={getDateActiveStatus(
                date,
                startDate,
                selectedRangeEndDate || endDate,
                minDate,
                maxDate
              )}
              label={label}
              buttonDate={date}
              handleClick={handleClick}
              selectingRange={!!startDate && !endDate}
              setSelectedRangeEndDate={setSelectedRangeEndDate}
              reportingPeriod={reportingPeriod ?? 'M'}
            />
          )),
        ]}
        {!!reportingPeriod &&
          dates.map(({ label, date }, ix) => (
            <IntuitiveCalendarButton
              key={ix}
              active={getDateActiveStatus(
                date,
                startDate,
                selectedRangeEndDate || endDate,
                minDate,
                maxDate
              )}
              label={label}
              buttonDate={date}
              handleClick={handleClick}
              selectingRange={!!startDate && !endDate}
              setSelectedRangeEndDate={setSelectedRangeEndDate}
              reportingPeriod={reportingPeriod ?? 'M'}
            />
          ))}
      </CalendarPaneButtonsWrapper>
    </CalendarPaneWrapper>
  );
}

export function IntuitiveCalendarPeriod({
  reportingPeriod,
  selectedDate,
  setSelectedDate,
}: CalendarPeriodProps) {
  const createPaneDate = (date: Date, offset: number) => {
    // Creates a date that is used to propagate button values in a pane
    // Offset specifies whether the pane date is used for a pane with earlier/later dates

    if (!reportingPeriod) {
      return null;
    }

    switch (reportingPeriod) {
      case 'M':
      case 'Q':
        return new Date(date.getFullYear() + offset, 0);
      case 'Y':
        return new Date(
          Math.floor(date.getFullYear() / 10) * 10 + 10 * offset,
          0
        );
      default:
        throw new Error(`Invalid reporting period when creating pane date`);
    }
  };

  const [selectedRangeEndDate, setSelectedRangeEndDate] = useState<Date | null>(
    null
  );
  const [paneDate, setPaneDate] = useState<Date>(
    createPaneDate(new Date(), 0) ?? new Date()
  );

  const handleClick = (date: Date) => {
    setSelectedDate(date);
  };

  const movePanes = (offset: number) => {
    setPaneDate(createPaneDate(paneDate, offset) ?? new Date());
  };

  const createLabel = (date: Date) => {
    if (!reportingPeriod) {
      return null;
    }
    const decadeYear = Math.floor(date.getFullYear() / 10) * 10;
    switch (reportingPeriod) {
      case 'M':
      case 'Q':
        return `${date.getFullYear()}`;
      case 'Y':
        return `${decadeYear} - ${decadeYear + 9}`;
      default:
        throw new Error(
          `Invalid reporting period when creating calendar pane label: ${reportingPeriod}`
        );
    }
  };

  useEffect(() => {
    setPaneDate(createPaneDate(new Date(), 0) ?? new Date());
  }, [reportingPeriod]);

  return (
    <CalendarWrapper>
      <IntuitiveCalendarPane
        paneLabel={createLabel(paneDate) ?? ''}
        rightArrow={() => movePanes(1)}
        leftArrow={() => movePanes(-1)}
        paneDate={paneDate}
        handleClick={handleClick}
        startDate={selectedDate}
        endDate={selectedDate}
        minDate={null}
        maxDate={new Date()}
        selectedRangeEndDate={selectedRangeEndDate}
        setSelectedRangeEndDate={setSelectedRangeEndDate}
        reportingPeriod={reportingPeriod}
        paneWidth={100}
      />
    </CalendarWrapper>
  );
}

export default function IntuitiveCalendar({
  reportingPeriod,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  maxDate,
}: CalendarProps) {
  const createPaneDate = (date: Date, offset: number) => {
    // Creates a date that is used to propagate button values in a pane
    // Offset specifies whether the pane date is used for a pane with earlier/later dates

    if (!reportingPeriod) {
      return new Date(date.getFullYear(), date.getMonth() + offset, 1);
    }

    switch (reportingPeriod) {
      case 'M':
      case 'Q':
        return new Date(date.getFullYear() + offset, 0);
      case 'Y':
        return new Date(
          Math.floor(date.getFullYear() / 10) * 10 + 10 * offset,
          0
        );
      default:
        throw new Error(`Invalid reporting period when creating pane date`);
    }
  };
  const getDefaultLeftPaneDate = () =>
    createPaneDate(new Date(), minDate ? 0 : -1) ?? new Date();
  const getDefaultRightPaneDate = () =>
    createPaneDate(new Date(), minDate ? 1 : 0) ?? new Date();

  const { language } = useAppSelector((state) => state.platform);
  const [selectedRangeEndDate, setSelectedRangeEndDate] = useState<Date | null>(
    null
  );
  const [leftPaneDate, setLeftPaneDate] = useState<Date>(
    getDefaultLeftPaneDate()
  );
  const [rightPaneDate, setRightPaneDate] = useState<Date>(
    getDefaultRightPaneDate()
  );

  const handleClick = (date: Date) => {
    if (!startDate || date < startDate) {
      setEndDate(null);
      setStartDate(date);
    } else if (!endDate) {
      setEndDate(date);
    } else {
      setEndDate(null);
      setStartDate(date);
    }
  };

  const movePanes = (offset: number) => {
    setLeftPaneDate(createPaneDate(leftPaneDate, offset) ?? new Date());
    setRightPaneDate(createPaneDate(rightPaneDate, offset) ?? new Date());
  };

  const createLabel = (date: Date) => {
    if (!reportingPeriod) {
      return dateFormat(date, 'M', language);
    }
    const decadeYear = Math.floor(date.getFullYear() / 10) * 10;
    switch (reportingPeriod) {
      case 'M':
      case 'Q':
        return `${date.getFullYear()}`;
      case 'Y':
        return `${decadeYear} - ${decadeYear + 9}`;
      default:
        throw new Error(
          `Invalid reporting period when creating calendar pane label: ${reportingPeriod}`
        );
    }
  };

  useEffect(() => {
    setLeftPaneDate(getDefaultLeftPaneDate());
    setRightPaneDate(getDefaultRightPaneDate());
  }, [reportingPeriod]);

  return (
    <CalendarWrapper>
      <IntuitiveCalendarPane
        paneLabel={createLabel(leftPaneDate) ?? ''}
        leftArrow={() => movePanes(-1)}
        paneDate={leftPaneDate}
        handleClick={handleClick}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate === undefined ? null : minDate}
        maxDate={maxDate === undefined ? new Date() : maxDate}
        selectedRangeEndDate={selectedRangeEndDate}
        setSelectedRangeEndDate={setSelectedRangeEndDate}
        reportingPeriod={reportingPeriod}
        paneWidth={50}
      />
      <IntuitiveCalendarPane
        paneLabel={createLabel(rightPaneDate) ?? ''}
        rightArrow={() => movePanes(1)}
        paneDate={rightPaneDate}
        handleClick={handleClick}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate === undefined ? null : minDate}
        maxDate={maxDate === undefined ? new Date() : maxDate}
        selectedRangeEndDate={selectedRangeEndDate}
        setSelectedRangeEndDate={setSelectedRangeEndDate}
        reportingPeriod={reportingPeriod}
        paneWidth={50}
      />
    </CalendarWrapper>
  );
}

export function IntuitiveCalendarCollapsable({
  reportingPeriod,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  calendarWidth,
  minDate,
  maxDate,
}: CalendarProps) {
  const { language } = useAppSelector((state) => state.platform);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <IntuitiveCalendarCollapsableWrapper width={calendarWidth}>
      <IntuitiveCalendarCollapsableButton
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
        width={calendarWidth}
      >
        <span>
          <span>
            {dateFormat(startDate, reportingPeriod, language)} -{' '}
            {dateFormat(endDate, reportingPeriod, language)}
          </span>
        </span>
        <IconSvg name="calendar" />
      </IntuitiveCalendarCollapsableButton>
      {!collapsed && (
        <>
          <CalendarOverlay onClick={() => setCollapsed(!collapsed)} />
          <IntuitiveCalendarModal>
            <IntuitiveCalendar
              reportingPeriod={reportingPeriod}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </IntuitiveCalendarModal>
        </>
      )}
    </IntuitiveCalendarCollapsableWrapper>
  );
}
