/* eslint-disable no-unused-vars */
import { PaletteColorOptions, createTheme } from '@mui/material';
import {
  lightenColor,
  themeColors as _themeColors,
} from '../views/components/QuestionnaireV3/helpers';

// eslint-disable-next-line import/prefer-default-export
export const esgTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#38414F',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#38414F',
    },
    '0': {
      main: '#e0e0e0',
      contrastText: '#38414F',
    },
    '1': {
      main: '#ECF6FE',
      contrastText: '#38414F',
    },
    '2': {
      main: '#CDEFFF',
      contrastText: '#38414F',
    },
    '3': {
      main: '#ACE8FF',
      contrastText: '#38414F',
    },
    '4': {
      main: '#A6D8F0',
      contrastText: '#38414F',
    },
    '5': {
      main: '#8CC6EA',
      contrastText: '#38414F',
    },
    true: {
      main: '#8CC6EA',
      contrastText: '#38414F',
    },
    false: {
      main: '#CDEFFF',
      contrastText: '#38414F',
    },
    affected_stakeholder: {
      main: '#CDEFFF',
      contrastText: '#38414F',
    },
    user: {
      main: '#CDEFFF',
      contrastText: '#38414F',
    },
    affected_stakeholder_and_user: {
      main: '#A6D8F0',
      contrastText: '#38414F',
    },
    not_applicable: {
      main: '#ECF6FE',
      contrastText: '#38414F',
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '16px ',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          gap: '10px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(2px) sepia(5%)',
          '& .MuiPaper-root': {
            borderRadius: '16px',
            minWidth: '800px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '28px',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '15px',
          textTransform: 'none',
          padding: '16px',
          height: '48px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },

          '&.primaryButton': {
            backgroundColor: _themeColors['esg.standard'],
            borderRadius: '28px',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            textTransform: 'none',
            padding: '10px 30px',
            color: '#fff',
            '&:hover': {
              backgroundColor: lightenColor(_themeColors['esg.standard'], -1),
            },
          },
          '&.MuiButton-containedSecondary': {
            border: '1px solid #D9D9D9',
            backgroundColor: '#fff',
            fontSize: '14px',
            alignSelf: 'center',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&:pressed': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '& .MuiButton-endIcon': {
              color: '#38414F',
              fontSize: '18px ',
              marginLeft: '3px',
            },
          },
        },
        sizeSmall: {
          height: '32px !important', // Todo remove important and remove height from root
          fontSize: '13px !important',
        },
        sizeMedium: {
          height: '40px !important',
          fontSize: '15px !important',
        },
        sizeLarge: {
          height: '48px !important',
          fontSize: '16px !important',
        },

        textSecondary: {
          color: '#38414F',
          border: '1px solid #D9D9D9',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface CustomPalette {
    '0'?: PaletteColorOptions;
    '1'?: PaletteColorOptions;
    '2'?: PaletteColorOptions;
    '3'?: PaletteColorOptions;
    '4'?: PaletteColorOptions;
    '5'?: PaletteColorOptions;
    true?: PaletteColorOptions;
    false?: PaletteColorOptions;
    affected_stakeholder?: PaletteColorOptions;
    user?: PaletteColorOptions;
    affected_stakeholder_and_user?: PaletteColorOptions;
    not_applicable?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    [key: string]: true;
  }
}
