import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { StyledPanel, StyledTitle } from '../../index.styles';
import { client } from '../../../utils/api-client';
import { UnitFormValidationContext } from './unit-utils';
import TextField from 'src/components/TextField';
import { mapError } from '../../../utils/validation';
import Autocomplete from 'src/components/Autocomplete';
import { useAppSelector } from 'src/redux-file/hooks';

type NaceCode = {
  code: string;
  description: string;
};

type PkdCode = {
  code: string;
  description: string;
};

export type BasicInformationState = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  naceCode: string | null;
  setNaceCode: React.Dispatch<React.SetStateAction<string | null>>;
  pkdCode: string | null;
  setPkdCode: React.Dispatch<React.SetStateAction<string | null>>;
  nip: string;
  setNip: React.Dispatch<React.SetStateAction<string>>;
  regon: string;
  setRegon: React.Dispatch<React.SetStateAction<string>>;
};

export const useBasicInformation = (): BasicInformationState => {
  const [name, setName] = React.useState<string>('');
  const [naceCode, setNaceCode] = React.useState<string | null>(null);
  const [pkdCode, setPkdCode] = React.useState<string | null>(null);
  const [nip, setNip] = React.useState<string>('');
  const [regon, setRegon] = React.useState<string>('');
  return {
    name,
    setName,
    naceCode,
    setNaceCode,
    nip,
    setNip,
    regon,
    setRegon,
    pkdCode,
    setPkdCode,
  };
};

type Props = BasicInformationState & {};

export default function BasicInformation(props: Props) {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.platform);
  const {
    name,
    setName,
    naceCode,
    setNaceCode,
    nip,
    setNip,
    regon,
    setRegon,
    pkdCode,
    setPkdCode,
  } = props;
  const errors = useContext(UnitFormValidationContext);
  const { data: naceCodes } = useQuery<NaceCode[]>(
    ['naceCodes', language],
    () =>
      client
        .get('web/industries/nace?hierarchy_level=nace_code')
        .then((res) => res?.data || [])
  );
  const { data: pkdCodes } = useQuery<PkdCode[]>(['pkdCodes', language], () =>
    client.get('web/industries/pkd').then((res) => res?.data || [])
  );
  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin.components.panels.unit.basicInformation.title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        <TextField
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={t(
            'superadmin.components.panels.unit.basicInformation.name.label'
          )}
          placeholder={t(
            'superadmin.components.panels.unit.basicInformation.name.placeholder'
          )}
          sx={{ mb: '24px' }}
          errorText={mapError(errors, 'name', name)}
        />
        <Autocomplete
          label={t(
            'superadmin.components.panels.unit.basicInformation.naceCode.label'
          )}
          placeholder={t(
            'superadmin.components.panels.unit.basicInformation.naceCode.placeholder'
          )}
          options={naceCodes?.map((option: NaceCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={naceCode}
          onChange={setNaceCode}
          sx={{ mb: '24px' }}
          errorText={mapError(errors, 'nace_code_id', naceCode)}
          handleClearClick={() => setNaceCode(null)}
        />

        <Autocomplete
          label={t(
            'superadmin.components.panels.unit.basicInformation.pkdCode.label'
          )}
          placeholder={t(
            'superadmin.components.panels.unit.basicInformation.pkdCode.placeholder'
          )}
          options={pkdCodes?.map((option: PkdCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={pkdCode}
          onChange={setPkdCode}
          sx={{ mb: '24px' }}
          errorText={mapError(errors, 'pkd_code_id', pkdCode)}
          handleClearClick={() => setPkdCode(null)}
        />

        <TextField
          fullWidth
          value={nip}
          onChange={(e) => setNip(e.target.value)}
          label={t(
            'superadmin.components.panels.unit.basicInformation.nip.label'
          )}
          placeholder={t(
            'superadmin.components.panels.unit.basicInformation.nip.placeholder'
          )}
          errorText={mapError(errors, 'vat_identifier', nip)}
          sx={{ mb: '24px' }}
        />

        <TextField
          fullWidth
          value={regon}
          onChange={(e) => setRegon(e.target.value)}
          label={t(
            'superadmin.components.panels.unit.basicInformation.regon.label'
          )}
          placeholder={t(
            'superadmin.components.panels.unit.basicInformation.regon.placeholder'
          )}
          errorText={mapError(errors, 'regon', regon)}
        />
      </StyledPanel>
    </div>
  );
}
