import React from 'react';
import Sidebar from '../../../views/components/Sidebar';
import Logo from '../../../images/svg/logo';
import LogoMin from '../../../images/svg/logoMin';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import HeaderWithLogo from '../../../views/components/Sidebar/HeaderWithLogo';
import LegacyNavItem from '../../../views/components/Sidebar/LegacyNavItem';
import ControllerItem from '../../../views/components/Sidebar/ControllerItem';
import IconSvg from '../../../views/components/IconSvg';
import { useTranslation } from 'react-i18next';
import RaportPopup from '../../../views/components/RaportPopup';
import { useAppSelector } from '../../../redux-file/hooks';
import ExpandableListItem from '../../../views/components/Sidebar/ExpandableItemList';
import { useGhgOrganization } from '../../../common/hooks';
import { OrganizationTag, hasTag } from '../../../utils/tags';
import { ApiDocsNavItem, HelpCenterNavItem } from '../../../common/sidebar';

function DownloadReportItem() {
  const { t } = useTranslation();
  const [popupOpen, setPopupOpen] = React.useState(false);
  const activeOrg = useAppSelector(
    (state) => state.platform.activeOrganization
  );
  const reportingFreq = activeOrg?.co2?.reporting_freq;

  return (
    <ListItem>
      {reportingFreq && (
        <RaportPopup
          opened={popupOpen}
          setOpened={setPopupOpen}
          reportingFreq={reportingFreq}
          organizationIds={[activeOrg.id]}
        />
      )}
      <ListItemButton onClick={() => setPopupOpen(true)}>
        <ListItemIcon>
          <IconSvg name="download" />
        </ListItemIcon>
        <ListItemText primary={t('menu.download-report')} />
      </ListItemButton>
    </ListItem>
  );
}

const GhgScope3Items = () => {
  const organization = useGhgOrganization();
  const showTransportationSummary = hasTag(
    organization,
    OrganizationTag.TransportationSummary
  );

  return (
    <>
      <LegacyNavItem
        name="goods-and-services"
        path="emissions/questionnairepage-goods-and-services"
      />
      <LegacyNavItem
        name="indirect-transportations"
        path="emissions/questionnairepage-indirect-transportations"
        icon="indirect-emissions"
      />
      {showTransportationSummary && (
        <LegacyNavItem
          name="transportation-summary"
          path="emissions/transportation-summary"
          icon="indirect-emissions"
        />
      )}
      <LegacyNavItem name="assets" path="emissions/questionnairepage-assets" />
      <LegacyNavItem
        name="waste-and-other"
        path="emissions/questionnairepage-waste-and-other"
      />
      <LegacyNavItem
        name="sold-products"
        path="emissions/questionnairepage-sold-products"
      />
      <LegacyNavItem
        name="employee-surveys"
        path="emissions/employees"
        icon="format"
      />
    </>
  );
};

export default function GhgOrganizationSidebar() {
  const { t } = useTranslation();
  const organization = useGhgOrganization();
  const showScope3 = !hasTag(organization, OrganizationTag.HideScope3);
  const canWriteData = organization.can_write_data;

  return (
    <Sidebar>
      <HeaderWithLogo openLogo={<Logo />} closedLogo={<LogoMin />} />

      <List>
        <LegacyNavItem name="dashboard" path="dashboard" />
        {canWriteData && (
          <>
            <LegacyNavItem name="recommendations" path="reduction-advice" />
            <LegacyNavItem
              name="reduction-targets"
              path="target"
              icon="targets_reduction"
            />

            <ExpandableListItem text={t('menu.emission-data')}>
              <LegacyNavItem
                name="direct-emissions"
                path="emissions/questionnairepage-direct-emissions"
              />
              <LegacyNavItem
                name="electricity-emissions"
                path="emissions/questionnairepage-electricity-emissions"
              />
              {showScope3 && <GhgScope3Items />}
              <Divider sx={{ m: '10px' }} />
            </ExpandableListItem>

            <LegacyNavItem name="about-company" path="about-company" />
          </>
        )}
        <DownloadReportItem />
      </List>
      <HelpCenterNavItem />
      <ApiDocsNavItem />
      <ControllerItem />
    </Sidebar>
  );
}
