import React, { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from 'react-query';
import { client } from '../../../../utils/api-client';
import { AddButton, CloseButton } from '../create/index.styles';
import CloseIcon from '../create/closeIcon';
import { Trans, useTranslation } from 'react-i18next';
import { StyledDialog } from './index.styles';
import { Button, Typography } from '@mui/material';
import { useEsgOrganization } from '../../../../common/hooks';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  setClose: () => void;
  id: string;
}

const Title = styled.p`
  color: #38414f;
  font-size: 24px;
  font-style: bold;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  text-align: center;
`;

const StyledInnerContainer = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 524px;
  height: 359px;
  align-self: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;

export default function DeleteReportModal({ setClose, open, id }: Props) {
  const organization = useEsgOrganization();
  const organizationId = organization.id;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [doubleCheckPhase, setDoubleCheckPhase] = useState(false);

  const _setClose = () => {
    setClose();
    setTimeout(() => {
      setDoubleCheckPhase(false);
    }, 500);
  };

  const { mutate } = useMutation({
    mutationFn: () => {
      return client.delete(`web/esg_v2/${organizationId}/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`reports`] });
      _setClose();
      toast.success(t('esg.removeReportModal.toast.success') as string);
    },
  });

  return (
    <StyledDialog
      sx={{
        '& .MuiPaper-root': {
          width: '650px',
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
      }}
      open={open}
      onClose={_setClose}
    >
      {!doubleCheckPhase ? (
        <>
          <Button
            type="button"
            onClick={setClose}
            sx={{ position: 'absolute', right: '0', top: '10px' }}
          >
            <CloseIcon />
          </Button>

          <StyledInnerContainer>
            <Title>{t('esg.removeReportModal.title')}</Title>
            <Typography
              sx={{
                fontSize: '18px',
                textAlign: 'center',
                fontStyle: 'regular',
                color: '#38414f',
              }}
            >
              <Trans i18nKey="esg.removeReportModal.subtitle" />
            </Typography>

            <ButtonsWrapper>
              <AddButton
                style={{ border: '1px solid #A03137', color: '#A03137' }}
                type="button"
                onClick={() => {
                  setDoubleCheckPhase(true);
                }}
              >
                {t('esg.removeReportModal.buttons.remove')}
              </AddButton>
              <CloseButton type="button" onClick={setClose}>
                {t('esg.removeReportModal.buttons.cancel')}
              </CloseButton>
            </ButtonsWrapper>
          </StyledInnerContainer>
        </>
      ) : (
        <>
          <Button
            type="button"
            onClick={_setClose}
            sx={{ position: 'absolute', right: '0', top: '10px' }}
          >
            <CloseIcon />
          </Button>
          <StyledInnerContainer>
            <Title>{t('esg.removeReportModalDoubleCheck.title')}</Title>
            <Typography
              sx={{
                fontSize: '18px',
                textAlign: 'center',
                fontStyle: 'regular',
                color: '#38414f',
              }}
            >
              <Trans i18nKey="esg.removeReportModalDoubleCheck.subtitle" />
            </Typography>
            <ButtonsWrapper>
              <AddButton
                style={{ border: '1px solid #A03137', color: '#A03137' }}
                type="button"
                onClick={() => {
                  mutate();
                }}
              >
                {t('esg.removeReportModalDoubleCheck.buttons.remove')}
              </AddButton>
              <CloseButton
                type="button"
                onClick={() => {
                  setDoubleCheckPhase(false);
                  setClose();
                }}
              >
                {t('esg.removeReportModalDoubleCheck.buttons.cancel')}
              </CloseButton>
            </ButtonsWrapper>
          </StyledInnerContainer>
        </>
      )}
    </StyledDialog>
  );
}
