import React from 'react';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import CommentBtn from './commentBtn';
import { useTranslation } from 'react-i18next';
import TableButton from '../../TableButton';

export default function GoodsPreview({
  state,
  id,
  setActive,
  setState,
}: {
  state: any;
  id: number;
  setActive: Function;
  setState: Function;
}) {
  const { t } = useTranslation();
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };

  return (
    <StyledHeader>
      <StyledTd width={33} color={companiesColors[id]}>
        <p>{state[id].name || ''}</p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={33} color={companiesColors[id]}>
        {t(`goods-and-services.${state[id].added_by}`)}
      </StyledTd>
      <StyledTd width={33} color={companiesColors[id]}>
        {(state[id].fraction * 100).toFixed(2) === '0.00'
          ? '0.01'
          : (state[id].fraction * 100).toFixed(2)}
      </StyledTd>
      <StyledTd
        width={0}
        color={companiesColors[id]}
        lastCallCustom
        numberOfIcons="two"
      >
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
