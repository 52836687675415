import React, { useEffect } from 'react';

import QuestionnaireAnswer from '../QuestionnaireAnswer';
import { QuestionnaireAnswer as QuestionnaireAnswerType } from '../QuestionnaireAnswer/index.types';

import { ModifiedQuestionAnswers, QuestionnaireV3 } from '../types';
import { injectNewAnswers } from '../helpers';
import { useAppSelector } from '../../../../redux-file/hooks';

interface Props extends QuestionnaireV3 {
  data?: QuestionnaireAnswerType;
  refetch: () => Promise<any>;
}

export type QuestionnaireContextType = {
  questionnaireAnswerId?: string;
  invalidateQuestionnaireQuery: (
    _modifiedQuestionAnswers: ModifiedQuestionAnswers
  ) => void;
  questionnaireAnswerState?: QuestionnaireAnswerType;
  setQuestionnaireAnswerState: React.Dispatch<
    React.SetStateAction<QuestionnaireAnswerType | undefined>
  >;
  tokenAuth: () => Promise<any>;
  modifiedQuestionAnswers: ModifiedQuestionAnswers;
  setModifiedQuestionAnswers: React.Dispatch<
    React.SetStateAction<ModifiedQuestionAnswers>
  >;
};

export const QuestionnaireContext =
  React.createContext<QuestionnaireContextType>({
    questionnaireAnswerState: undefined,
    setQuestionnaireAnswerState: (data) => data,
    questionnaireAnswerId: undefined,
    invalidateQuestionnaireQuery: (
      _modifiedQuestionAnswers?: ModifiedQuestionAnswers
    ) => {},
    tokenAuth: () => new Promise(() => {}),
    modifiedQuestionAnswers: {},
    setModifiedQuestionAnswers: (data) => data,
  });

export default function QuestionnaireContextWrapped(props: Props) {
  const { tokenAuth, refetch, data, questionnaireAnswerId } = props;

  const [questionnaireAnswerState, setQuestionnaireAnswerState] =
    React.useState<QuestionnaireAnswerType | undefined>(data);

  const [modifiedQuestionAnswers, setModifiedQuestionAnswers] =
    React.useState<ModifiedQuestionAnswers>({});

  const { language: activeLanguage } = useAppSelector(
    (state) => state.platform
  );

  const invalidateQuestionnaireQuery = React.useCallback(
    (_modifiedQuestionAnswers?: ModifiedQuestionAnswers) => {
      refetch().then((data) =>
        setQuestionnaireAnswerState(
          injectNewAnswers(data, _modifiedQuestionAnswers)
        )
      );
    },
    [refetch]
  );

  useEffect(() => {
    setQuestionnaireAnswerState(data);
  }, [data]);

  useEffect(() => {
    // handle changing app language
    // TODO: prompt the user before reloading the questionnaire
    invalidateQuestionnaireQuery();
  }, [invalidateQuestionnaireQuery, activeLanguage]);

  const memoizedValue = React.useMemo(
    () => ({
      questionnaireAnswerId,
      invalidateQuestionnaireQuery,
      questionnaireAnswerState,
      setQuestionnaireAnswerState,
      tokenAuth,
      modifiedQuestionAnswers,
      setModifiedQuestionAnswers,
    }),
    [
      questionnaireAnswerId,
      invalidateQuestionnaireQuery,
      questionnaireAnswerState,
      setQuestionnaireAnswerState,
      tokenAuth,
      modifiedQuestionAnswers,
      setModifiedQuestionAnswers,
    ]
  );

  if (!questionnaireAnswerState) return null;

  return (
    <QuestionnaireContext.Provider value={memoizedValue}>
      <QuestionnaireAnswer payload={questionnaireAnswerState} />
    </QuestionnaireContext.Provider>
  );
}
