import React from 'react';
import { Box, Button } from '@mui/material';
import {
  GhgSupplierCodeOfConductSurveyInSchema,
  GhgSupplierCodeOfConductSurveyOutSchema,
} from '../../../../CodeOfConductSurvey/types';
import { StyledButtonContainer, StyledInputContainer } from '../styles';
import TextField, { TextFieldProps } from '../../../../../components/TextField';
import { useTranslation } from 'react-i18next';
import { useCodeOfConductSurveys } from '../../hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { ErrorBuilder, SchemaErrors, mapError } from 'src/utils/validation';

type SurveyModalCreateProps = {
  onCreated: (survey: GhgSupplierCodeOfConductSurveyOutSchema) => void;
  onClose: () => void;
};
type FormState = GhgSupplierCodeOfConductSurveyInSchema;
type FormErrors = SchemaErrors<FormState>;

const validateForm = (data: Partial<FormState>): FormErrors => {
  const builder = new ErrorBuilder(data);
  builder.checkTruthy(['email', 'supplier_name'], 'validation.required');
  builder.checkRule(
    ['email'],
    (val) => val?.includes('@') ?? false,
    'validation.email'
  );
  return builder.build();
};

const defaultForm: FormState = {
  email: '',
  supplier_name: '',
};

export default function SurveyModalCreate(props: SurveyModalCreateProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.scope3GoodsAndServices.codeOfConduct',
  });

  const organization = useGhgOrganization();
  const { create } = useCodeOfConductSurveys(organization.id);

  const [form, setForm] = React.useState<FormState>(defaultForm);
  const [errors, setErrors] = React.useState<FormErrors>({});

  const createSurvey = () => {
    const validationErrors = validateForm(form);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return;
    create.mutateAsync(form).then((res) => props.onCreated(res.data));
  };

  const textFieldProps = (
    field: keyof FormState
  ): Partial<TextFieldProps<undefined>> => {
    return {
      value: form[field] || '',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setForm({ ...form, [field]: e.target.value }),
      errorText: mapError(errors, field, form[field]),
    };
  };

  return (
    <Box>
      <StyledInputContainer>
        <TextField
          fullWidth
          label={t('surveyModal.inputs.name')}
          {...textFieldProps('supplier_name')}
        />
        <TextField
          fullWidth
          label={t('surveyModal.inputs.email')}
          {...textFieldProps('email')}
        />
      </StyledInputContainer>
      <StyledButtonContainer>
        <Button variant="contained" color="primary" onClick={createSurvey}>
          {t('surveyModal.buttons.create')}
        </Button>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          {t('surveyModal.buttons.cancel')}
        </Button>
      </StyledButtonContainer>
    </Box>
  );
}
