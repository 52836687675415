import { ProductPlatform } from 'src/utils/api.interfaces';
import { OrganizationTreeNodeSchema } from './components/StructureTable/index.types';

export type ProductAvailabilitySchema = {
  product: ProductPlatform;
  is_active: boolean;
  is_billed?: boolean;
};

export type UnitTypeSchema = {
  id: string;
  name?: string;
};

export type OrganizationInFormSchema = {
  name: string;
  country: string | null;
  parent_id?: number;
  nace_code_id: string | null;
  pkd_code_id: string | null;
  vat_identifier: string | null;
  regon: string | null;
  city: string | null;
  active_products: ProductAvailabilitySchema[];
  unit_type: UnitTypeSchema | null;
};

export type OrganizationOutFormSchema = OrganizationInFormSchema & {
  id: number;
  organizations: OrganizationTreeNodeSchema[];
};

export type UserInFormSchema = {
  first_name: string;
  last_name: string;
  position: string;
  email: string;
  phone: string;
  password?: string;
  confirm_password?: string;
  initial_organization_id?: string | null;
};

export type UserOutFormSchema = {
  id: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  position?: string;
  email: string;
};

export enum MemberRole {
  view = 'view',
  edit = 'edit',
  admin = 'admin',
}

export type OrganizationMember = {
  id: string;
  role: MemberRole;
  organization_id: number;
  products: ProductPlatform[];
};

export type Member = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_logged_in: boolean;
  organizations: OrganizationMember[];
};

export interface CreateOrganizationMemberSchema {
  role: MemberRole;
  organizationId: number;
  products: ProductPlatform[];
  user_id: number;
}
export interface UpdateOrganizationMemberSchema
  extends CreateOrganizationMemberSchema {
  id: string;
}
