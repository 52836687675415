import React, { useEffect, useState } from 'react';
import { StyledContainer } from './index.styles';
import { FormErrors, RegistrationUBNPSchema } from './form';
import { PageState } from './types';
import Header from './components/Header';
import CommonParagraph from './components/CommonParagraph';
import NotAClient from './NotAClient';
import RegistrationFirstPage from './RegistrationFirstPage';
import RegistrationSecondPage from './RegistrationSecondPage';
import { ReportingFreq } from 'src/utils/api.interfaces';
import { setLanguage } from 'src/redux-file/platform/slice';
import { useAppDispatch } from 'src/redux-file/hooks';

export default function RegisterUBNP() {
  const dispatch = useAppDispatch();

  const [form, setForm] = useState<RegistrationUBNPSchema>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    organization_name: '',
    vat_identifier: '',
    regon: '',
    pkd_code: null,
    country: '',
    bnp_analytics: false,
    bnp_representation: false,
    password: '',
    reporting_frequency: ReportingFreq.M,
  });
  const [pageState, setPageState] = useState<PageState>('fresh-page');
  const [isClient, setIsClient] = useState<boolean | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});

  const onNextPage = () => {
    setPageState('registration-second-page');
    window.scrollTo(0, 0);
  };

  const onPreviousPage = () => {
    setPageState('registration-first-page');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(setLanguage('pl'));
  }, []);

  return (
    <StyledContainer>
      <Header />
      <form style={{ marginLeft: '10px', marginRight: '10px' }}>
        {(pageState === 'fresh-page' ||
          pageState === 'not-a-client' ||
          pageState === 'registration-first-page') && (
          <CommonParagraph
            setPageState={setPageState}
            isClient={isClient}
            setIsClient={setIsClient}
          />
        )}
        {pageState === 'not-a-client' && <NotAClient />}
        {pageState === 'registration-first-page' && (
          <RegistrationFirstPage
            form={form}
            setForm={setForm}
            errors={errors}
            setErrors={setErrors}
            onNext={onNextPage}
          />
        )}
        {pageState === 'registration-second-page' && (
          <RegistrationSecondPage
            form={form}
            setForm={setForm}
            errors={errors}
            setErrors={setErrors}
            onPrevious={onPreviousPage}
          />
        )}
      </form>
    </StyledContainer>
  );
}
