import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { Overlay } from '../Title/index.styles';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import { AutocompleteFreesolo, Select } from '../fields';
import { other_units } from './data';
import FormButtons from '../FormButtons';
import {
  getElecricityEmissionsData,
  getElecricityEmissionsDataEmissionShares,
  postElecricityEmissionsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';

export default function EvConsumptionForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  setNewForm: Function;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  let spending_pln_or_kwh_per_period_error = false;
  let unit_error = false;
  let emission_factor_error = false;
  let amount_error = false;
  let unit_label_error = false;

  const [
    spending_pln_or_kwh_per_period_error_state,
    set_spending_pln_or_kwh_per_period_error_state,
  ] = useState(false);
  const [unit_error_state, set_unit_error_state] = useState(false);
  const [emission_factor_error_state, set_emission_factor_error_state] =
    useState(false);
  const [amount_error_state, set_amount_error_state] = useState(false);
  const [unit_label_error_state, set_unit_label_error_state] = useState(false);

  const spending_pln_or_kwh_per_period_validation = (value: string) => {
    if (value === '') spending_pln_or_kwh_per_period_error = true;
    return spending_pln_or_kwh_per_period_error;
  };
  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };
  const emission_factor_validation = (value: string) => {
    if (value === '') emission_factor_error = true;
    return emission_factor_error;
  };
  const amount_validation = (value: string) => {
    if (value === '') amount_error = true;
    return amount_error;
  };
  const unit_label_validation = (value: string) => {
    if (value === '') unit_label_error = true;
    return unit_label_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: !state[id].emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown: state[id]
        .emission_factor_is_known
        ? {
            name_label: '',
            unit: '',
            spending_pln_or_kwh_per_period: '',
          }
        : commutingType.electric_vehicle_energy_consumption_data_factor_unknown,
      electric_vehicle_energy_consumption_data_factor_known: state[id]
        .emission_factor_is_known
        ? commutingType.electric_vehicle_energy_consumption_data_factor_known
        : {
            emission_factor: '',
            amount: '',
            unit_label: '',
          },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setKnownAmount = (value: string) => {
    amount_validation(value);
    set_amount_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown:
        commutingType.electric_vehicle_energy_consumption_data_factor_unknown,
      electric_vehicle_energy_consumption_data_factor_known: {
        emission_factor:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .emission_factor,
        amount: value,
        unit_label:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .unit_label,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_amount_error_state(true);
  };

  const handleFactor = (value: string) => {
    emission_factor_validation(value);
    set_emission_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown:
        commutingType.electric_vehicle_energy_consumption_data_factor_unknown,
      electric_vehicle_energy_consumption_data_factor_known: {
        emission_factor: value,
        amount:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .amount,
        unit_label:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .unit_label,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_emission_factor_error_state(true);
  };

  const handleUnitLabel = (value: string) => {
    unit_label_validation(value);
    set_unit_label_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown:
        commutingType.electric_vehicle_energy_consumption_data_factor_unknown,
      electric_vehicle_energy_consumption_data_factor_known: {
        emission_factor:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .emission_factor,
        amount:
          commutingType.electric_vehicle_energy_consumption_data_factor_known
            .amount,
        unit_label: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_unit_label_error_state(true);
  };
  const handleName = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown: {
        name_label: value,
        number_of_mobile_equipment:
          commutingType.electric_vehicle_energy_consumption_data_factor_unknown
            .number_of_mobile_equipment,
        spending_pln_or_kwh_per_period:
          commutingType.electric_vehicle_energy_consumption_data_factor_unknown
            .spending_pln_or_kwh_per_period,
        unit: commutingType
          .electric_vehicle_energy_consumption_data_factor_unknown.unit,
      },
      electric_vehicle_energy_consumption_data_factor_known:
        commutingType.electric_vehicle_energy_consumption_data_factor_known,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const handleSpending = (value: string) => {
    spending_pln_or_kwh_per_period_validation(value);
    set_spending_pln_or_kwh_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown: {
        name_label:
          commutingType.electric_vehicle_energy_consumption_data_factor_unknown
            .name_label,
        spending_pln_or_kwh_per_period: value,
        unit: commutingType
          .electric_vehicle_energy_consumption_data_factor_unknown.unit,
      },
      electric_vehicle_energy_consumption_data_factor_known:
        commutingType.electric_vehicle_energy_consumption_data_factor_known,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_spending_pln_or_kwh_per_period_error_state(true);
  };

  const handleUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      electric_vehicle_energy_consumption_data_factor_unknown: {
        name_label:
          commutingType.electric_vehicle_energy_consumption_data_factor_unknown
            .name_label,
        spending_pln_or_kwh_per_period:
          commutingType.electric_vehicle_energy_consumption_data_factor_unknown
            .spending_pln_or_kwh_per_period,
        unit: value,
      },
      electric_vehicle_energy_consumption_data_factor_known:
        commutingType.electric_vehicle_energy_consumption_data_factor_known,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_unit_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !spending_pln_or_kwh_per_period_error &&
      !unit_error &&
      !emission_factor_error &&
      !unit_label_error &&
      !amount_error
    ) {
      dispatch(
        postElecricityEmissionsData({
          data,
          period: timeConverter(selectedDate, reportingPeriod),
        })
      ).then(() => {
        dispatch(
          getElecricityEmissionsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getElecricityEmissionsDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].emission_factor_is_known === false) {
      if (
        state[id].electric_vehicle_energy_consumption_data_factor_unknown
          .spending_pln_or_kwh_per_period === ''
      ) {
        set_spending_pln_or_kwh_per_period_error_state(true);
        spending_pln_or_kwh_per_period_error = true;
      }

      if (
        state[id].electric_vehicle_energy_consumption_data_factor_unknown
          .unit === ''
      ) {
        set_unit_error_state(true);
        unit_error = true;
      }
    }
    if (state[id].emission_factor_is_known === true) {
      if (
        state[id].electric_vehicle_energy_consumption_data_factor_known
          .emission_factor === ''
      ) {
        set_emission_factor_error_state(true);
        emission_factor_error = true;
      }
      if (
        state[id].electric_vehicle_energy_consumption_data_factor_known
          .amount === ''
      ) {
        set_amount_error_state(true);
        amount_error = true;
      }
      if (
        state[id].electric_vehicle_energy_consumption_data_factor_known
          .unit_label === ''
      ) {
        set_unit_label_error_state(true);
        unit_label_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      electricity_usage: combinedData.electricity_usage,
      steam_usage: combinedData.steam_usage,
      heat_usage: combinedData.heat_usage,
      cooling_usage: combinedData.cooling_usage,
      electric_vehicles_consumptionwise_usage: tempState,
      electric_vehicles_distancewise_usage:
        combinedData.electric_vehicles_distancewise_usage,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('electricity-emission.form.check-factor')}
              value={state[id].emission_factor_is_known}
              setValue={() => setKnowEmission()}
            />
          </CheckboxContainer>
          {state[id].emission_factor_is_known ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`electricity-emission.vehicles.consumption.amount`)}
                  active
                  error={amount_error_state}
                  index={1}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_known
                      .amount
                  }
                  setValue={(e: string) => setKnownAmount(e)}
                />
              </FormBox>

              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={unit_label_error_state}
                  index={1}
                  label={t(
                    `electricity-emission.vehicles.consumption.unit_label`
                  )}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_known
                      .unit_label
                  }
                  setValue={(value: string) => handleUnitLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>

              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t(
                    `electricity-emission.vehicles.consumption.emission_factor`
                  )}
                  active
                  error={emission_factor_error_state}
                  index={1}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_known
                      .emission_factor
                  }
                  setValue={(e: string) => handleFactor(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(
                    `electricity-emission.vehicles.consumption.name_label`
                  )}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_unknown
                      .name_label
                  }
                  setValue={(value: string) => handleName(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(
                    `electricity-emission.vehicles.consumption.spending_pln_or_kwh_per_period`
                  )}
                  active
                  error={spending_pln_or_kwh_per_period_error_state}
                  index={1}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_unknown
                      .spending_pln_or_kwh_per_period
                  }
                  setValue={(e: string) => handleSpending(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  error={unit_error_state}
                  index={1}
                  label={t(`electricity-emission.vehicles.consumption.unit`)}
                  value={
                    state[id]
                      .electric_vehicle_energy_consumption_data_factor_unknown
                      .unit
                  }
                  setValue={(value: string) => handleUnit(value)}
                  max={123}
                  options={other_units}
                />
              </FormBox>
            </>
          )}
        </div>

        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_factor_is_known &&
                state[id]
                  .electric_vehicle_energy_consumption_data_factor_unknown
                  .spending_pln_or_kwh_per_period !== '' &&
                state[id]
                  .electric_vehicle_energy_consumption_data_factor_unknown
                  .unit !== '') ||
              (state[id].emission_factor_is_known &&
                state[id].electric_vehicle_energy_consumption_data_factor_known
                  .emission_factor !== '' &&
                state[id].electric_vehicle_energy_consumption_data_factor_known
                  .amount !== '' &&
                state[id].electric_vehicle_energy_consumption_data_factor_known
                  .unit_label !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
