import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import {
  AutocompleteCategories,
  AutocompleteFreesolo,
  Select,
} from '../fields';
import { choices_without_others, other_units, units } from './data';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { Overlay } from '../Title/index.styles';
import FormButtons from '../FormButtons';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import {
  getAssetsFranchisesAndInvestmentsData,
  getAssetsFranchisesAndInvestmentsEmissionSharesData,
  putAssetsFranchisesAndInvestmentsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import { ProductPlatform } from 'src/utils/api.interfaces';

export default function LeasedAssetsForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  variant,
  setNewForm,
}: {
  setNewForm: Function;
  state: {
    comment?: string | null;
    name: string;
    emission_amount_is_known: boolean;
    leased_asset_data_emission_unknown: {
      type_of_fuel: string;
      unit_of_fuel: string;
      amount_of_fuel_in_period: string;
      spending_on_electricity_pln_or_kwh_in_period: string;
      unit_of_energy_usage: string;
    };
    leased_asset_data_emission_known: {
      amount_kg_eq_co2_in_period: string;
    };
  }[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  variant: 'u' | 'd';
}) {
  // TODO: tłumaczenia ostatnich pól
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const { t } = useTranslation();
  const translationVariant =
    variant === 'u' ? 'upstream_leased_assets' : 'downstream_leased_assets';
  const dispatch = useAppDispatch();

  const [
    amount_kg_eq_co2_in_period_error_state,
    set_amount_kg_eq_co2_in_period_error_state,
  ] = useState(false);
  let amount_kg_eq_co2_in_period_error = false;
  const amount_kg_eq_co2_in_period_validation = (value: string) => {
    if (value === '') amount_kg_eq_co2_in_period_error = true;
    return amount_kg_eq_co2_in_period_error;
  };

  const [type_of_fuel_error_state, set_type_of_fuel_error_state] =
    useState(false);
  let type_of_fuel_error = false;
  const type_of_fuel_validation = (value: string) => {
    if (value === '') type_of_fuel_error = true;
    return type_of_fuel_error;
  };

  const [unit_of_fuel_error_state, set_unit_of_fuel_error_state] =
    useState(false);
  let unit_of_fuel_error = false;
  const unit_of_fuel_validation = (value: string) => {
    if (value === '') unit_of_fuel_error = true;
    return unit_of_fuel_error;
  };

  const [
    unit_of_energy_usage_error_state,
    set_unit_of_energy_usage_error_state,
  ] = useState(false);
  let unit_of_energy_usage_error = false;
  const unit_of_energy_usage_validation = (value: string) => {
    if (value === '') unit_of_energy_usage_error = true;
    return unit_of_energy_usage_error;
  };

  const [
    spending_on_electricity_pln_or_kwh_in_period_error_state,
    set_spending_on_electricity_pln_or_kwh_in_period_error_state,
  ] = useState(false);
  let spending_on_electricity_pln_or_kwh_in_period_error = false;
  const spending_on_electricity_pln_or_kwh_in_period_validation = (
    value: string
  ) => {
    if (value === '') spending_on_electricity_pln_or_kwh_in_period_error = true;
    return spending_on_electricity_pln_or_kwh_in_period_error;
  };

  const [
    amount_of_fuel_in_period_error_state,
    set_amount_of_fuel_in_period_error_state,
  ] = useState(false);
  let amount_of_fuel_in_period_error = false;
  const amount_of_fuel_in_period_validation = (value: string) => {
    if (value === '') amount_of_fuel_in_period_error = true;
    return amount_of_fuel_in_period_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      name: tempForm.name,
      emission_amount_is_known: !tempForm.emission_amount_is_known,
      leased_asset_data_emission_unknown: tempForm.emission_amount_is_known
        ? {
            type_of_fuel: '',
            unit_of_fuel: '',
            amount_of_fuel_in_period: '',
            spending_on_electricity_pln_or_kwh_in_period: '',
            unit_of_energy_usage: '',
          }
        : tempForm.leased_asset_data_emission_unknown,
      leased_asset_data_emission_known: tempForm.emission_amount_is_known
        ? tempForm.leased_asset_data_emission_known
        : {
            amount_kg_eq_co2_in_period: '',
          },
    };
    newForms[id] = tempForm;
    setState(newForms);
  };
  const handleName = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      ...tempForm,
      name: value,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleAmount = (value: string) => {
    amount_kg_eq_co2_in_period_validation(value);
    set_amount_kg_eq_co2_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known: {
        amount_kg_eq_co2_in_period: value,
      },
      leased_asset_data_emission_unknown:
        tempForm.leased_asset_data_emission_unknown,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_kg_eq_co2_in_period_error_state(true);
  };

  const handleTypeOfFuel = (value: string) => {
    type_of_fuel_validation(value);
    set_type_of_fuel_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known:
        tempForm.leased_asset_data_emission_known,
      leased_asset_data_emission_unknown: {
        spending_on_electricity_pln_or_kwh_in_period:
          tempForm.leased_asset_data_emission_unknown
            .spending_on_electricity_pln_or_kwh_in_period,
        type_of_fuel: value,
        amount_of_fuel_in_period:
          tempForm.leased_asset_data_emission_unknown.amount_of_fuel_in_period,
        unit_of_energy_usage:
          tempForm.leased_asset_data_emission_unknown.unit_of_energy_usage,
        unit_of_fuel: tempForm.leased_asset_data_emission_unknown.unit_of_fuel,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_type_of_fuel_error_state(true);
  };

  const handleUnitOfFuel = (value: string) => {
    unit_of_fuel_validation(value);
    set_unit_of_fuel_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known:
        tempForm.leased_asset_data_emission_known,
      leased_asset_data_emission_unknown: {
        spending_on_electricity_pln_or_kwh_in_period:
          tempForm.leased_asset_data_emission_unknown
            .spending_on_electricity_pln_or_kwh_in_period,
        type_of_fuel: tempForm.leased_asset_data_emission_unknown.type_of_fuel,
        amount_of_fuel_in_period:
          tempForm.leased_asset_data_emission_unknown.amount_of_fuel_in_period,
        unit_of_energy_usage:
          tempForm.leased_asset_data_emission_unknown.unit_of_energy_usage,
        unit_of_fuel: value,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_unit_of_fuel_error_state(true);
  };
  const handleAmountOfFuel = (value: string) => {
    amount_of_fuel_in_period_validation(value);
    set_amount_of_fuel_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known:
        tempForm.leased_asset_data_emission_known,
      leased_asset_data_emission_unknown: {
        spending_on_electricity_pln_or_kwh_in_period:
          tempForm.leased_asset_data_emission_unknown
            .spending_on_electricity_pln_or_kwh_in_period,
        type_of_fuel: tempForm.leased_asset_data_emission_unknown.type_of_fuel,
        amount_of_fuel_in_period: value,
        unit_of_energy_usage:
          tempForm.leased_asset_data_emission_unknown.unit_of_energy_usage,
        unit_of_fuel: tempForm.leased_asset_data_emission_unknown.unit_of_fuel,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_of_fuel_in_period_error_state(true);
  };
  const handleSpending = (value: string) => {
    spending_on_electricity_pln_or_kwh_in_period_validation(value);
    set_spending_on_electricity_pln_or_kwh_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known:
        tempForm.leased_asset_data_emission_known,
      leased_asset_data_emission_unknown: {
        spending_on_electricity_pln_or_kwh_in_period: value,
        type_of_fuel: tempForm.leased_asset_data_emission_unknown.type_of_fuel,
        amount_of_fuel_in_period:
          tempForm.leased_asset_data_emission_unknown.amount_of_fuel_in_period,
        unit_of_energy_usage:
          tempForm.leased_asset_data_emission_unknown.unit_of_energy_usage,
        unit_of_fuel: tempForm.leased_asset_data_emission_unknown.unit_of_fuel,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '')
      set_spending_on_electricity_pln_or_kwh_in_period_error_state(true);
  };
  const handleUnitOfEnergy = (value: string) => {
    unit_of_energy_usage_validation(value);
    set_unit_of_energy_usage_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_asset_data_emission_known:
        tempForm.leased_asset_data_emission_known,
      leased_asset_data_emission_unknown: {
        spending_on_electricity_pln_or_kwh_in_period:
          tempForm.leased_asset_data_emission_unknown
            .spending_on_electricity_pln_or_kwh_in_period,
        type_of_fuel: tempForm.leased_asset_data_emission_unknown.type_of_fuel,
        amount_of_fuel_in_period:
          tempForm.leased_asset_data_emission_unknown.amount_of_fuel_in_period,
        unit_of_energy_usage: value,
        unit_of_fuel: tempForm.leased_asset_data_emission_unknown.unit_of_fuel,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_unit_of_energy_usage_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !unit_of_energy_usage_error &&
      !spending_on_electricity_pln_or_kwh_in_period_error &&
      !amount_kg_eq_co2_in_period_error &&
      !type_of_fuel_error &&
      !amount_of_fuel_in_period_error
    ) {
      dispatch(
        putAssetsFranchisesAndInvestmentsData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getAssetsFranchisesAndInvestmentsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getAssetsFranchisesAndInvestmentsEmissionSharesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (!state[id].emission_amount_is_known) {
      if (state[id].leased_asset_data_emission_unknown.type_of_fuel === '') {
        set_type_of_fuel_error_state(true);
        type_of_fuel_error = true;
      }
      if (state[id].leased_asset_data_emission_unknown.unit_of_fuel === '') {
        set_unit_of_fuel_error_state(true);
        unit_of_fuel_error = true;
      }
      if (
        state[id].leased_asset_data_emission_unknown
          .amount_of_fuel_in_period === ''
      ) {
        set_amount_of_fuel_in_period_error_state(true);
        amount_of_fuel_in_period_error = true;
      }
      if (
        state[id].leased_asset_data_emission_unknown
          .spending_on_electricity_pln_or_kwh_in_period === ''
      ) {
        set_spending_on_electricity_pln_or_kwh_in_period_error_state(true);
        spending_on_electricity_pln_or_kwh_in_period_error = true;
      }
      if (
        state[id].leased_asset_data_emission_unknown.unit_of_energy_usage === ''
      ) {
        set_unit_of_energy_usage_error_state(true);
        unit_of_energy_usage_error = true;
      }
    }
    if (state[id].emission_amount_is_known) {
      if (
        state[id].leased_asset_data_emission_known
          .amount_kg_eq_co2_in_period === ''
      ) {
        set_amount_kg_eq_co2_in_period_error_state(true);
        amount_kg_eq_co2_in_period_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      upstream_leased_buildings: combinedData.upstream_leased_buildings,
      upstream_leased_assets:
        variant === 'u' ? tempState : combinedData.upstream_leased_assets,
      downstream_leased_buildings: combinedData.downstream_leased_buildings,
      downstream_leased_assets:
        variant === 'd' ? tempState : combinedData.downstream_leased_assets,
      franchises: combinedData.franchises,
      investments: combinedData.investments,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('assets-and-other.upstream_leased_assets.form.check')}
              setValue={() => setKnowEmission()}
              value={state[id].emission_amount_is_known || false}
            />
          </CheckboxContainer>
          {state[id].emission_amount_is_known ? (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t('assets-and-other.upstream_leased_assets.form.name')}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  label={t(
                    `assets-and-other.${translationVariant}.form.amount_kg_eq_co2_in_period`
                  )}
                  active
                  error={amount_kg_eq_co2_in_period_error_state}
                  index={1}
                  value={
                    state[id].leased_asset_data_emission_known
                      .amount_kg_eq_co2_in_period
                  }
                  setValue={(e: string) => handleAmount(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t('assets-and-other.upstream_leased_assets.form.name')}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteCategories
                  label={t(
                    'assets-and-other.upstream_leased_assets.form.type-of-fuel'
                  )}
                  // @ts-ignore
                  options={choices_without_others}
                  value={
                    state[id].leased_asset_data_emission_unknown.type_of_fuel
                  }
                  setValue={(e: string) => handleTypeOfFuel(e)}
                  error={type_of_fuel_error_state}
                  active
                  index={1}
                  max={99}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  label={t(
                    'assets-and-other.upstream_leased_assets.form.fuel-unit'
                  )}
                  options={units}
                  value={
                    state[id].leased_asset_data_emission_unknown.unit_of_fuel
                  }
                  setValue={(e: string) => handleUnitOfFuel(e)}
                  error={unit_of_fuel_error_state}
                  active
                  index={4}
                  max={99}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  label={t(
                    'assets-and-other.upstream_leased_assets.form.fuel-amount'
                  )}
                  active
                  error={amount_of_fuel_in_period_error_state}
                  index={1}
                  value={
                    state[id].leased_asset_data_emission_unknown
                      .amount_of_fuel_in_period
                  }
                  setValue={(e: string) => handleAmountOfFuel(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(
                    'assets-and-other.upstream_leased_assets.form.spending'
                  )}
                  active
                  error={
                    spending_on_electricity_pln_or_kwh_in_period_error_state
                  }
                  index={1}
                  value={
                    state[id].leased_asset_data_emission_unknown
                      .spending_on_electricity_pln_or_kwh_in_period
                  }
                  setValue={(e: string) => handleSpending(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  label={t(
                    'assets-and-other.upstream_leased_assets.form.spending-unit'
                  )}
                  options={other_units}
                  value={
                    state[id].leased_asset_data_emission_unknown
                      .unit_of_energy_usage
                  }
                  setValue={(e: string) => handleUnitOfEnergy(e)}
                  error={unit_of_energy_usage_error_state}
                  active
                  index={4}
                  max={99}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_amount_is_known &&
                state[id].leased_asset_data_emission_unknown.type_of_fuel !==
                  '' &&
                state[id].leased_asset_data_emission_unknown.unit_of_fuel !==
                  '' &&
                state[id].leased_asset_data_emission_unknown
                  .amount_of_fuel_in_period !== '' &&
                state[id].leased_asset_data_emission_unknown
                  .spending_on_electricity_pln_or_kwh_in_period !== '' &&
                state[id].leased_asset_data_emission_unknown
                  .unit_of_energy_usage !== '') ||
              (state[id].emission_amount_is_known &&
                state[id].leased_asset_data_emission_known
                  .amount_kg_eq_co2_in_period !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
