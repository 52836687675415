// TODO: move to src/theme dir after merging parent branch

export const body1 = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '26px',
  letterSpacing: '0.02em',
};

export const heading6 = {
  fontSize: '15px',
  fontWeight: '600',
  lineHeight: '23px',
  letterSpacing: '0.02em',
};

export const captionText = {
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.02em',
};
