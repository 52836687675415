import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import { CalendarWrapper } from './index.styles';
import { CalendarProps, CalendarRangeProps } from './index.interfaces';
import { useAppSelector } from '../../../redux-file/hooks';

registerLocale('en', en);
registerLocale('pl', pl);

export default function BigCalendar({
  startDate,
  currDate,
  setDate,
  reportingPeriod,
}: CalendarProps) {
  const { language } = useAppSelector((state) => state.platform);
  const [lang, setLang] = useState<string>(language);

  const handleChange = (date: Date) => {
    //@ts-ignore
    setDate(date);
  };

  useEffect(() => {
    setLang(language);
  }, [language]);

  return (
    <CalendarWrapper period={reportingPeriod}>
      {reportingPeriod !== 'Q' ? (
        <DatePicker
          selected={currDate}
          onChange={handleChange}
          showMonthYearPicker={reportingPeriod === 'M'}
          showYearPicker={reportingPeriod === 'Y'}
          maxDate={startDate ? undefined : new Date()}
          minDate={startDate ? new Date() : undefined}
          dateFormat="MMMM yyyy"
          inline
          locale={lang}
          calendarClassName="calendar"
        />
      ) : (
        <DatePicker
          calendarClassName="calendar"
          selected={currDate}
          onChange={handleChange}
          dateFormat="QQQ yyyy"
          showQuarterYearPicker
          locale={lang}
          maxDate={startDate ? undefined : new Date()}
          minDate={startDate ? new Date() : undefined}
          inline
        />
      )}
    </CalendarWrapper>
  );
}

export function BigCalendarRange({
  startDate,
  endDate,
  handleChange,
  reportingPeriod,
}: CalendarRangeProps) {
  const { language } = useAppSelector((state) => state.platform);
  const [lang, setLang] = useState<string>(language);

  useEffect(() => {
    setLang(language);
  }, [language]);

  return (
    <CalendarWrapper period={reportingPeriod}>
      {reportingPeriod !== 'Q' ? (
        <DatePicker
          //@ts-ignore
          onChange={handleChange}
          showMonthYearPicker={reportingPeriod === 'M'}
          showYearPicker={reportingPeriod === 'Y'}
          maxDate={new Date()}
          minDate={undefined}
          dateFormat="MMMM yyyy"
          startDate={startDate}
          endDate={endDate}
          inline
          selectsRange
          locale={lang}
          calendarClassName="calendar"
        />
      ) : (
        <DatePicker
          calendarClassName="calendar"
          //@ts-ignore
          onChange={handleChange}
          selectsRange
          dateFormat="QQQ yyyy"
          startDate={startDate}
          endDate={endDate}
          showQuarterYearPicker
          locale={lang}
          maxDate={new Date()}
          minDate={undefined}
          inline
        />
      )}
    </CalendarWrapper>
  );
}
