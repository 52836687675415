import React, { useState } from 'react';
import {
  Box,
  BoxContainer,
  BoxDate,
  BoxItemInner,
  BoxName,
  BoxYear,
  Container,
  HiddenIconButton,
  NewReportBox,
  Subtitle,
  TitleWrapper,
} from './index.styles';
import ButtonLink from '../../../components/ButtonLink';
import { useQuery } from 'react-query';
import { useEsgOrganization } from 'src/common/hooks';
import PageLoading from '../../../../components/PageLoading';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { useTranslation } from 'react-i18next';
import { FormType } from '../Report/index.styles';
import IconSvg from '../../../components/IconSvg';
import { getOrganizationReports } from '../../../../Esg/api';
import { EsgReportSchemaOut } from '../../../../Esg/api.types';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditNameModal from '../Report/editNameModal';
import DeleteReportModal from '../Report/deleteReportModal';
import { StyledFlex } from '../../../../components/StyledFlex';
import EsgExportToolsButton from 'src/components/EsgExportToolsButton';

function BoxItem({ item }: { item: EsgReportSchemaOut }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const organization = useEsgOrganization();
  const canEdit = organization.can_write_data;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setButtonVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setButtonVisible(false);
    setOpen(false);
  };
  if (!!item) {
    return (
      <BoxItemInner buttonVisible={buttonVisible}>
        <Box to={`/esg/reports/${item.id}`}>
          <BoxName>{item.name}</BoxName>
          <BoxYear>{item.period}</BoxYear>
          <div
            style={{ flexGrow: 1, display: 'flex', gap: 4, flexWrap: 'wrap' }}
          />
          {item.categories.map((category) => (
            <FormType bgc={category.color} key={category.key}>
              {category.name}
            </FormType>
          ))}
        </Box>
        <StyledFlex
          style={{
            width: '100%',
            borderTop: '1px solid #dadada',
            marginTop: '10px',
          }}
        >
          <BoxDate>{`${t('esg.last-update')} ${new Date(
            item.last_update!
          ).toLocaleDateString()}`}</BoxDate>

          {canEdit && (
            <HiddenIconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </HiddenIconButton>
          )}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => setOpen(true)}>
              {t('esg.editNamePeriod')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              {t('esg.deleteReport')}
            </MenuItem>
          </Menu>
        </StyledFlex>
        <EditNameModal
          open={open}
          setClose={handleClose}
          shouldRedirect={false} // temporary solution before refactor
          report={item}
        />
        <DeleteReportModal
          open={deleteModalOpen}
          setClose={() => setDeleteModalOpen(false)}
          id={item.id.toString()}
        />
      </BoxItemInner>
    );
  } else {
    return <div />;
  }
}
const link_to_new_report = '/esg/reports/new';

export default function ReportsIndex() {
  const { t } = useTranslation();
  const organization = useEsgOrganization();
  const canWrite = organization.can_write_data;

  const { data, isError } = useQuery(
    ['reports', { organizationId: organization.id }],
    () => getOrganizationReports({ organizationId: organization.id })
  );

  if (isError) {
    return <SomethingWentWrong />;
  }

  if (data === undefined) {
    return <PageLoading />;
  }

  const lastUsedItem = data?.filter(
    (el) => el.id.toString() === localStorage.getItem('esg_report')
  );
  return (
    <Container>
      <TitleWrapper>
        <h1>{t('esg.reports')}</h1>
        <div style={{ display: 'flex', gap: '20px' }}>
          <EsgExportToolsButton
            organizationIds={[organization.id]}
            size="large"
          />
          {canWrite && (
            <ButtonLink url={link_to_new_report} text={t('esg.new-report')} />
          )}
        </div>
      </TitleWrapper>
      {data.length ? (
        <div>
          {!!lastUsedItem && (
            <div style={{ marginBottom: '56px' }}>
              <Subtitle>{t('esg.recently-opened')}</Subtitle>
              <BoxItem item={lastUsedItem[0]} />
            </div>
          )}
          <Subtitle>{t('esg.all-reports')}</Subtitle>
          <BoxContainer>
            {data
              ?.sort((a, b) => {
                // @ts-ignore
                return new Date(b.last_update) - new Date(a.last_update);
              })
              .map((el) => {
                return <BoxItem key={el.id} item={el} />;
              })}
          </BoxContainer>
        </div>
      ) : (
        <div>
          <Subtitle>{t('esg.empty')}</Subtitle>
          <NewReportBox to={link_to_new_report}>
            <p>
              {t('esg.create-new')} {'>'}
            </p>
            <IconSvg name="esg-new-report" />
          </NewReportBox>
        </div>
      )}
    </Container>
  );
}
