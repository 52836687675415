import { IconButton, MenuItem, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { StyledMenu, StyledTypography } from '../index.styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { putLanguage } from '../../../redux-file/thunk';
import { setLanguage } from '../../../redux-file/platform/slice';

enum Languages {
  en = 'en',
  pl = 'pl',
}
const languages = Object.values(Languages);

type Props = {
  localOnly?: boolean;
};

export default function ChangeLanguage(props: Props) {
  const { language } = useAppSelector((state) => state.platform);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const changeLanguage = (newLang: string) => {
    if (!props.localOnly) {
      dispatch(putLanguage(newLang));
    }
    dispatch(setLanguage(newLang));
    handleClose();
  };

  return (
    <>
      <IconButton sx={{ m: '5px', width: '40px' }} onClick={handleOpen}>
        <StyledTypography>{language.toUpperCase()}</StyledTypography>
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {languages.map((lang) => (
          <MenuItem
            key={lang}
            selected={lang === language}
            onClick={() => {
              changeLanguage(lang);
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {lang.toUpperCase()}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
