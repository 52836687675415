import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux-file/hooks';
import { ProductPlatform } from './api.interfaces';
import React from 'react';
import { RoutingError } from '../common/exceptions';
import { setActiveOrganizationId } from '../redux-file/platform/slice';

export const productPathMap = {
  '/ghg': ProductPlatform.Co2,
  '/esg': ProductPlatform.Esg,
  '/lca': ProductPlatform.Lca,
};
export const parseNumericId = (idStr: string | undefined): number => {
  if (!idStr) throw new RoutingError();
  return parseInt(idStr, 10);
};

const LAST_VISITED_KEY = 'lastVisitedProduct';
export const setLastVisitedProduct = (product: ProductPlatform | null) => {
  if (!product) return localStorage.removeItem(LAST_VISITED_KEY);
  return localStorage.setItem(LAST_VISITED_KEY, product);
};

export const getLastVisitedProduct = () => {
  const product = localStorage.getItem(LAST_VISITED_KEY);
  if (product && Object.values(ProductPlatform).includes(product as any)) {
    return product as ProductPlatform;
  }
  return null;
};

const getPathToProduct = (product: ProductPlatform) => {
  const path = Object.entries(productPathMap).find(
    ([_, p]) => p === product
  )?.[0];
  if (!path) throw new RoutingError();
  return path;
};

export const usePlatformNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { organizations, activeOrganizationId: organizationId } =
    useAppSelector((state) => state.platform);

  const activeProduct = React.useMemo(
    () =>
      Object.entries(productPathMap).find(([prefix, _]) =>
        location.pathname.startsWith(prefix)
      )?.[1] ?? null,
    [location.pathname]
  );

  const navigateToProduct = React.useCallback((product: ProductPlatform) => {
    navigate(getPathToProduct(product));
  }, []);

  const getPathToOrganization = React.useCallback(
    (organizationId: number | null, forceProduct?: ProductPlatform) => {
      let product = forceProduct ?? activeProduct;
      const org = organizations?.find((org) => org.id === organizationId);
      if (!product) {
        // pick the first product the org has access to
        product = org?.active_products?.[0] ?? null;
      }
      if (!product) {
        // we can't do anything here
        return '/';
      }
      return (
        getPathToProduct(product) +
        (organizationId !== null ? '/dashboard' : '')
      );
    },
    [organizations, activeProduct]
  );

  const navigateToOrganization = React.useCallback(
    (organizationId: number | null, forceProduct?: ProductPlatform) => {
      dispatch(setActiveOrganizationId(organizationId));
      navigate(getPathToOrganization(organizationId, forceProduct));
    },
    [getPathToOrganization]
  );

  const productOrganizations = React.useMemo(
    () =>
      organizations?.filter(
        (x) => !activeProduct || x.active_products.includes(activeProduct)
      ) || [],
    [organizations, activeProduct]
  );

  return {
    navigate,
    navigateToProduct,
    navigateToOrganization,
    getPathToProduct,
    activeProduct,
    organizationId,
    productOrganizations,
  };
};
