import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { client } from 'src/utils/api-client';
import {
  StyledHeader,
  StyledCell,
  StyledRow,
  StyledDataCell,
} from './index.styles';
import { useTranslation } from 'react-i18next';
import { TableRow } from './index.types';
import CustomSelect from 'src/Esg/Dma/components/CustomSelect';
import ReasoningModal from './ReasoningModal';
import SendFormModal from './SendFormModal';
import { useEsgOrganization } from 'src/common/hooks';
import {
  getBooleanOptions,
  getNumericOptions,
  getStakeholderNatureOptions,
} from 'src/Esg/Dma/utils';

export default function SelectingStakeholdersTable() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id: organizationId } = useEsgOrganization();

  const { reportId } = useParams();

  const queryKey = ['dma-stakeholders-table', organizationId, reportId];
  const getUrl = `/web/esg_stakeholders/${organizationId}/${reportId}/stakeholders_table`;
  const putUrl = `/web/esg_stakeholders/${organizationId}/${reportId}/stakeholders_table_row`;

  const { data } = useQuery(queryKey, () =>
    client.get(getUrl).then((res) => res.data)
  );
  const updateTableRow = (row: TableRow) => {
    client.put(putUrl, row).then(() => queryClient.invalidateQueries(queryKey));
  };

  const translatedColumn = (key: string) =>
    t(`esg.dma.selectingStakeholders.table.columns.${key}`);

  const stakeholderTypesCellStyle = {
    width: '20%',
    minWidth: '200px',
    backgroundColor: '#F6F6F6',
  };

  const NUMERIC_OPTIONS = getNumericOptions(t);
  const BOOLEAN_OPTIONS = getBooleanOptions(t);
  const STAKEHOLDER_NATURE_OPTIONS = getStakeholderNatureOptions(t);
  return (
    <div style={{ width: '100%' }}>
      <StyledRow sx={{ borderWidth: '2px' }}>
        <StyledHeader style={stakeholderTypesCellStyle}>
          {translatedColumn('stakeholderTypes')}
        </StyledHeader>
        <StyledHeader>{translatedColumn('stakeholders_nature')}</StyledHeader>
        <StyledHeader>{translatedColumn('severity')}</StyledHeader>
        <StyledHeader>{translatedColumn('selected_for_survey')}</StyledHeader>
        <StyledHeader>{translatedColumn('reasoning')}</StyledHeader>
        <StyledHeader sx={{ backgroundColor: '#F6F6F6' }}>
          {translatedColumn('send')}
        </StyledHeader>
      </StyledRow>

      {(data || []).map((row: TableRow) => {
        const {
          stakeholder_key: key,
          stakeholder_name: stakeholderName,
          stakeholder_nature,
          severity,
          selected_for_survey: selectedForSurvey,
          reasoning,
        } = row;
        return (
          <StyledRow key={key}>
            <StyledCell style={stakeholderTypesCellStyle}>
              {stakeholderName}
            </StyledCell>
            <StyledDataCell>
              <CustomSelect
                key={key}
                fullWidth
                value={stakeholder_nature}
                options={STAKEHOLDER_NATURE_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, stakeholder_nature: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={key}
                value={severity}
                options={NUMERIC_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, severity: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={key}
                value={selectedForSurvey}
                options={BOOLEAN_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, selected_for_survey: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <ReasoningModal
                descriptionTranslationKey="esg.dma.selectingStakeholders.table.modals.reasoning.subtitle"
                reasoning={reasoning}
                attention={
                  (selectedForSurvey !== null && reasoning === null) ||
                  reasoning === ''
                }
                onChange={(value) =>
                  updateTableRow({ ...row, reasoning: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell sx={{ backgroundColor: '#F6F6F6' }}>
              <SendFormModal
                organizationId={String(organizationId)}
                reportId={reportId}
                stakeholderKey={key}
                stakeholderName={stakeholderName}
                disabled={
                  reasoning === null ||
                  reasoning === '' ||
                  selectedForSurvey !== true
                }
              />
            </StyledDataCell>
          </StyledRow>
        );
      })}
    </div>
  );
}
