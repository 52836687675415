import { Helmet } from 'react-helmet';
import { useAppSelector } from '../redux-file/hooks';
import React from 'react';

/**
 * Provider for setting the <head> metadata using `react-helmet`.
 */
export default function HelmetProvider() {
  const lang = useAppSelector((state) => state.platform.language);
  return <Helmet htmlAttributes={{ lang, translate: 'no' }} />;
}
