import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux-file/hooks';
import { client } from 'src/utils/api-client';
import {
  setLoading,
  setInitialRequest,
  setLanguage,
} from 'src/redux-file/platform/slice';

import Loader, { Container } from 'src/components/Loader';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useQuery } from 'react-query';

export default function InitialRequest(props: any) {
  const dispatch = useDispatch();
  const { isLoading, error } = useAppSelector((state) => state.platform);

  const langKey = 'localLanguage';
  // always initialize UI with last saved language
  const localLanguage = localStorage.getItem(langKey);

  const fetchInitialRequest = async () => {
    const res = await client.get('/web/users/me');
    return res.data;
  };

  const query = useQuery(['platform', 'initial-request'], fetchInitialRequest, {
    onSuccess: (data) => {
      dispatch(setInitialRequest(data));
      if (localLanguage === null) {
        // we don't have a local language, so sync it from remote
        dispatch(setLanguage(data.language));
      }
    },
    refetchOnMount: 'always',
  });

  React.useEffect(() => {
    dispatch(setLoading(query.isLoading));
  }, [query.isLoading]);

  if (error)
    return (
      <Container>
        <SomethingWentWrong errorMessege={error.msg} />
      </Container>
    );

  if (isLoading) return <Loader />;

  return props.children;
}
