import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button } from '@mui/material';
import { StyledTitle } from '../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import BackButton from 'src/components/BackButton';
import OrganizationalStructure, {
  useOrganizationalStructure,
} from '../components/Panels/OrganizationalStructure';
import BasicInformation, {
  useBasicInformation,
} from '../components/Panels/BasicInformation';
import AvailableProducts, {
  useAvailableProducts,
} from '../components/Panels/AvailableProducts';
import Location, { useLocation } from '../components/Panels/Location';
import { OrganizationOutFormSchema } from '../index.types';
import {
  UnitFormErrors,
  UnitFormValidationContext,
  getSchemaFromStates,
  setStatesFromResponseBody,
  validateForm,
} from '../components/Panels/unit-utils';
import PageLoading from '../../components/PageLoading';
import SomethingWentWrong from '../../components/SomethingWentWrong';
import GroupOrganizations from '../components/Panels/GroupOrganizations';
import { extractSchemaErrors } from '../../utils/validation';
import { withEmptyStringsAsNull } from 'src/utils/helpers';
import { client } from 'src/utils/api-client';
import ConfirmationModal from 'src/components/ConfirmationModal';

export default function EditUnit() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const organizationalStructureState = useOrganizationalStructure();
  const basicInformationState = useBasicInformation();
  const locationState = useLocation();
  const availableProductsState = useAvailableProducts();
  const formState = {
    availableProductsState,
    basicInformationState,
    locationState,
    organizationalStructureState,
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  const [changedCountry, setChangedCountry] = React.useState<
    string | undefined
  >(undefined);
  const [formStateErrors, setFormStateErrors] = React.useState<UnitFormErrors>(
    {}
  );

  const {
    data: organization,
    isLoading,
    isError,
  } = useQuery(['unit', id], () =>
    client
      .get<OrganizationOutFormSchema>(`/web/settings_v2/organizations/${id}`)
      .then((response) => {
        const { data } = response;
        if (data) {
          setStatesFromResponseBody(formState, data);
        }
        return data;
      })
  );

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  const handleSave = () => {
    const schema = getSchemaFromStates(formState);
    const formErrors = validateForm(schema, false);
    setFormStateErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    client
      .put(
        `/web/settings_v2/organizations/${id}`,
        withEmptyStringsAsNull(schema)
      )
      .then((response) => {
        toast.success(String(t('superadmin.toast.unit-saved')));
        return response.data;
      })
      .catch((err) => {
        toast.error(String(t('superadmin.toast.validation-error')));
        setFormStateErrors(extractSchemaErrors(err, schema));
      });
  };

  const handleDelete = () =>
    client
      .delete(`/web/settings_v2/organizations/${id}`)
      .then(() => navigate(-1));

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const setCountry = (country: string | null) => {
    if (country) {
      // if any country is selected, show confirmation dialog
      setChangedCountry(country);
    } else {
      locationState.setCountry(null);
    }
  };

  return (
    <>
      <StyledFlex>
        <StyledTitle>
          <BackButton navigateTo="/superadmin" />
          {basicInformationState.name || t('superadmin.unit.editUnit')}
        </StyledTitle>
        <Button variant="contained" onClick={handleSave}>
          {t('superadmin.unit.buttons.save')}
        </Button>
      </StyledFlex>
      <UnitFormValidationContext.Provider value={formStateErrors}>
        <OrganizationalStructure {...organizationalStructureState} orgId={id} />
        <BasicInformation {...basicInformationState} />
        <Location {...locationState} setCountry={setCountry} />
        <AvailableProducts {...availableProductsState} />
        {(organization?.organizations.length ?? 0) > 0 && (
          <GroupOrganizations organizations={organization!.organizations} />
        )}
      </UnitFormValidationContext.Provider>
      <StyledFlex sx={{ mt: '50px' }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDeleteClick}
        >
          {t('superadmin.unit.buttons.delete')}
        </Button>
        <Button variant="contained" onClick={handleSave}>
          {t('superadmin.unit.buttons.save')}
        </Button>
      </StyledFlex>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        titleKey={t('superadmin.unit.deleteConfirmation.title')}
        textKey={t('superadmin.unit.deleteConfirmation.content')}
        trueButtonTextKey={t('superadmin.unit.deleteConfirmation.buttonA')}
        falseButtonTextKey={t('superadmin.unit.deleteConfirmation.buttonB')}
        onTrue={handleDelete}
        onFalse={() => setDeleteConfirmationOpen(false)}
      />
      <ConfirmationModal
        open={changedCountry !== undefined}
        onClose={() => setChangedCountry(undefined)}
        titleKey={t('superadmin.unit.changeCountry.title')}
        textKey={t('superadmin.unit.changeCountry.content')}
        trueButtonTextKey={t('superadmin.unit.changeCountry.buttonA')}
        falseButtonTextKey={t('superadmin.unit.changeCountry.buttonB')}
        onTrue={() => {
          if (changedCountry) locationState.setCountry(changedCountry);
          setChangedCountry(undefined);
        }}
        onFalse={() => setChangedCountry(undefined)}
      />
    </>
  );
}
