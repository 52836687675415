import React from 'react';
import { List, ListItemIcon, ListItemText } from '@mui/material';
import LegacyNavItem from '../../../views/components/Sidebar/LegacyNavItem';
import { useAppSelector } from '../../../redux-file/hooks';
import { useQuery } from 'react-query';
import { getOrganizationReports } from '../../api';
import SidebarNavItem from '../../../views/components/Sidebar/NavItem';
import { ListAlt } from '@mui/icons-material';
import EsgSidebar from '../Sidebar';

export default function EsgOrganizationSidebar() {
  const organization = useAppSelector(
    (state) => state.platform.activeOrganization
  );

  const { data } = useQuery(
    ['reports', { organizationId: organization?.id }],
    () => getOrganizationReports({ organizationId: organization!.id }),
    {
      enabled: organization !== null,
    }
  );

  const reports = data || [];
  // order reports, newest first
  const orderedReports = [...reports].sort((a, b) => +b.period - +a.period);

  return (
    <EsgSidebar>
      <List>
        <LegacyNavItem name="esg-reports" path="/esg/reports" />
        {orderedReports.map((report) => (
          <SidebarNavItem
            key={report.id}
            to={`/esg/reports/${report.id}`}
            matchTo={`/esg/reports/${report.id}/*`}
          >
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary={`${report.name} (${report.period})`} />
          </SidebarNavItem>
        ))}
      </List>
    </EsgSidebar>
  );
}
