import React from 'react';

import { StyledContainer, Descriptions } from './index.styles';
import QuestionnaireTitle from './QuestionnaireTitle';
import Question from '../Question';
import { Questionnaire as QuestionnaireType } from './index.types';
import { ThemeProvider } from '@mui/material';
import { getThemeColors } from '../helpers';
import { getFieldTheme } from '../Field/fieldTheme';
import IconAndSectionTitle from '../Question/IconAndSectionTitle';

type Props = {
  payload: QuestionnaireType;
};

export default function Questionnaire(props: Props) {
  const {
    id: questionnaireId,
    title,
    description,
    questions,
    subtitle,
  } = props.payload;

  const themeColors = getThemeColors();
  const filteredDescription = description?.replace(/<p>&nbsp;<\/p>/g, '');

  return (
    <ThemeProvider theme={getFieldTheme(themeColors)}>
      <QuestionnaireTitle title={title} subtitle={subtitle} />
      <StyledContainer>
        {!!description && (
          <>
            <IconAndSectionTitle sectionTypeKey="intro" />
            <Descriptions
              dangerouslySetInnerHTML={{
                __html: filteredDescription || description,
              }}
            />
          </>
        )}
        {Object.entries(questions)?.map(([_, question]) => (
          <Question
            key={`questionnaire-${questionnaireId}-question-${question.id}`}
            payload={question}
          />
        ))}
      </StyledContainer>
    </ThemeProvider>
  );
}
