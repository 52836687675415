import { Box, Button } from '@mui/material';
import { GhgSupplierCodeOfConductSurveyOutSchema } from '../../../../CodeOfConductSurvey/types';
import { StyledButtonContainer, StyledInputContainer } from '../styles';
import TextField from '../../../../../components/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCodeOfConductSurveys } from '../../hooks';
import { useGhgOrganization } from 'src/common/hooks';

type SurveyModalEditProps = {
  survey: GhgSupplierCodeOfConductSurveyOutSchema;
  onClose: () => void;
};

export default function SurveyModalEdit(props: SurveyModalEditProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.scope3GoodsAndServices.codeOfConduct',
  });

  const organization = useGhgOrganization();
  const { sendEmail } = useCodeOfConductSurveys(organization.id);

  const {survey} = props;
  const surveyLink = `${window.location.origin}/code-of-conduct-survey/${survey.access_token}`;

  const onSend = () => {
    sendEmail.mutateAsync(survey.id).then(() => {
      toast.success(t('toasts.emailSent') as string);
      props.onClose();
    });
  };

  return (
    <Box>
      <StyledInputContainer>
        <TextField
          fullWidth
          label={t('surveyModal.inputs.name')}
          value={survey.supplier_name}
          disabled
        />
        <TextField
          fullWidth
          label={t('surveyModal.inputs.email')}
          value={survey.email}
          disabled
        />
        <TextField
          fullWidth
          label={t('surveyModal.inputs.link')}
          value={surveyLink}
          disabled
        />
      </StyledInputContainer>

      <StyledButtonContainer>
        <Button variant="contained" color="primary" onClick={onSend}>
          {t('surveyModal.buttons.send')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            navigator.clipboard.writeText(surveyLink);
            toast.success(t('toasts.linkCopied') as string);
          }}
        >
          {t('surveyModal.buttons.copy')}
        </Button>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          {t('surveyModal.buttons.cancel')}
        </Button>
      </StyledButtonContainer>
    </Box>
  );
}
