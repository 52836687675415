import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { genEmptyAnswer, paths, resolvedDependencyFields } from '../helpers';

import { QuestionAnswer as QuestionAnswerType } from '../types';
import { StyledContainer } from './index.styles';
import Field from '../Field';
import { Field as FieldType } from '../Field/index.types';
import { QuestionnaireContext } from '../Data/QuestionnaireContext';
import { client } from '../../../../utils/api-client';
import { Errors } from '../Field/Fields/types';
import IconSvg from '../../IconSvg';
import { toast } from 'react-toastify';
import { createQuestionAnswer, updateQuestionAnswer } from '../api';
import { extractErrors } from '../../../../utils/validation';
import { StyledFlex } from 'src/components/StyledFlex';
import ConfirmationModal from 'src/components/ConfirmationModal';
import _ from 'lodash';

type Props = {
  questionAnswer: QuestionAnswerType;
  fields: FieldType[];
  containerStyle?: { [key: string]: string };
  question: any;
};

export default function QuestionAnswer(props: Props) {
  const { questionAnswer, question, fields, containerStyle } = props;
  const { id } = questionAnswer || {};

  const { t } = useTranslation();

  const isNew = String(id).startsWith('temp-id-');

  const {
    setQuestionnaireAnswerState,
    invalidateQuestionnaireQuery,
    tokenAuth,
    questionnaireAnswerId,
    setModifiedQuestionAnswers,
    modifiedQuestionAnswers,
  } = useContext(QuestionnaireContext);

  const [errors, setErrors] = React.useState<Errors>();

  const [deleteQuestionAnswerOpen, setDeleteQuestionAnswerOpen] =
    React.useState(false);

  const parseResponseMessage = (
    errors: { [key: string]: string } | undefined
  ) => {
    const responseMessages: { [key: string]: string } = {
      'none is not an allowed value': t('questionnaireV3.field.fieldRequired'),
    };
    return Object.fromEntries(
      Object.entries(errors || {}).map(([key, value]) => [
        key,
        responseMessages[value] || value,
      ])
    );
  };

  const catchErrors = (err: any) => {
    const errors = parseResponseMessage(extractErrors(err));
    setErrors(errors);
    for (const [_, value] of Object.entries(errors)) {
      toast.error(value);
    }
  };

  const handleSuccess = () => {
    removeModifiedQuestionAnswer();
    invalidateQuestionnaireQuery(modifiedQuestionAnswers);
    toast.success(String(t('questionnaireV3.questionAnswer.changesSaved')));
  };

  const handleCreateQuestionAnswer = () =>
    createQuestionAnswer(
      tokenAuth,
      questionnaireAnswerId,
      questionAnswer,
      question,
      fields
    )
      .then(handleSuccess)
      .catch(catchErrors);

  const handleUpdateQuestionAnswer = () =>
    updateQuestionAnswer(tokenAuth, questionAnswer, fields)
      .then(handleSuccess)
      .catch(catchErrors);

  // remove from modifiedQuestionAnswers
  const removeModifiedQuestionAnswer = () =>
    setModifiedQuestionAnswers((prevState) => {
      const newState = { ...prevState };
      delete newState[question.id][questionAnswer.id];
      return newState;
    });

  const handleDeleteQuestionAnswer = () => {
    tokenAuth().then((token) =>
      client
        .delete(`${paths.questionnaireAnswerRecords}/${id}`, {
          headers: {
            'X-Questionnaires-Token': token,
          },
        })
        .then(() => invalidateQuestionnaireQuery({}))
        .catch((err) => console.error(err))
    );
    removeModifiedQuestionAnswer();
    setDeleteQuestionAnswerOpen(false);
  };

  const handleCancelQuestionAnswer = () => {
    setQuestionnaireAnswerState((prevState) => {
      // Escaping undefined state
      if (!prevState) return prevState;

      // copying newState from prevState
      const newState = _.cloneDeep(prevState);

      // creating questionAnswer if does not exist
      const questionAnswers =
        newState.questionnaire.questions[question.key].questionanswers || {};

      delete questionAnswers[id];

      // creating new empty answer if allow_many is false
      // to always have at least one set of fields
      if (!question.allow_many) {
        const emptyAnswer = genEmptyAnswer(question, questionnaireAnswerId);
        questionAnswers[emptyAnswer.id] = emptyAnswer;
      }

      // Update the question answers in the new state
      newState.questionnaire.questions[question.key].questionanswers =
        questionAnswers;

      return newState;
    });
    removeModifiedQuestionAnswer();
  };

  const dependencyAwareFields = resolvedDependencyFields(
    fields,
    questionAnswer
  );
  return (
    <StyledContainer style={containerStyle}>
      {dependencyAwareFields?.map((field) => (
        <Field
          key={`question-${id}-${id || 'new'}-field-${field.id}`}
          questionAnswer={questionAnswer}
          field={field}
          question={question}
          errors={errors}
          onForceQuestionAnswerSave={() =>
            isNew ? handleCreateQuestionAnswer() : handleUpdateQuestionAnswer()
          }
        />
      ))}
      {Object.keys(modifiedQuestionAnswers[question.id] || {}).includes(
        String(questionAnswer.id)
      ) && (
        <StyledFlex style={{ margin: '30px 0px' }}>
          {isNew && (
            <Button
              className="Esg EsgSecondary"
              size="small"
              variant="contained"
              color="error"
              onClick={handleCancelQuestionAnswer}
              disableElevation
            >
              {t('questionnaireV3.questionAnswer.cancelButton')}
            </Button>
          )}
          {isNew && (
            <Button
              className="Esg EsgPrimary"
              size="small"
              onClick={handleCreateQuestionAnswer}
              disableElevation
            >
              {t('questionnaireV3.questionAnswer.createButton')}
            </Button>
          )}
          {!isNew && (
            <Button
              className="Esg EsgSecondary"
              size="small"
              onClick={() => setDeleteQuestionAnswerOpen(true)}
              startIcon={<IconSvg name="trash-icon" />}
              variant="contained"
              disableElevation
            >
              {t('questionnaireV3.questionAnswer.deleteButton')}
            </Button>
          )}

          {!isNew && (
            <Button
              className="Esg EsgPrimary"
              size="small"
              onClick={handleUpdateQuestionAnswer}
              variant="contained"
              disableElevation
            >
              {t('questionnaireV3.questionAnswer.saveButton')}
            </Button>
          )}
        </StyledFlex>
      )}
      <ConfirmationModal
        open={deleteQuestionAnswerOpen}
        titleKey={t('questionnaireV3.questionAnswer.confirmDeleteTitle')}
        textKey={t('questionnaireV3.questionAnswer.confirmDeleteText')}
        onTrue={handleDeleteQuestionAnswer}
        onFalse={() => setDeleteQuestionAnswerOpen(false)}
        onClose={() => setDeleteQuestionAnswerOpen(false)}
      />
    </StyledContainer>
  );
}
