import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { useAppSelector } from 'src/redux-file/hooks';
import { useEsgOrganization } from 'src/common/hooks';

// eslint-disable-next-line import/prefer-default-export
export const getIndicatorsData = (period: string, id: string) => {
  const organization = useEsgOrganization();

  const { language } = useAppSelector((state) => state.platform);
  const { data, isLoading, isError } = useQuery(
    ['indicators', organization.id, period, language],
    () => {
      return client.get(`/web/esg_v2/${organization.id}/${id}/indicators`);
    }
  );
  return {
    data,
    isLoading,
    isError,
  };
};
