import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../ReportsIndex/index.styles';
import { AddButton, CloseButton } from '../create/index.styles';
import { useEsgOrganization } from 'src/common/hooks';
import QuestionnaireV3 from '../../../components/QuestionnaireV3';
import { useEsgQuestionnaireAnswerId } from './Hooks/useQuestionnaireAnswerId';
import { createEsgTokenFetcher } from './Hooks/tokenFetcher';

export default function ESGForm() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const nav = useNavigate();
  const { t } = useTranslation();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: questionnaireAnswerId } = useEsgQuestionnaireAnswerId({
    organizationId: organization.id,
    reportId,
    questionnaireKey,
  });

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  return (
    <Container>
      <QuestionnaireV3
        key={questionnaireAnswerId}
        questionnaireAnswerId={questionnaireAnswerId}
        tokenAuth={tokenFetcher}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '40px 0 80px',
        }}
      >
        <AddButton
          onClick={() => nav(-1)}
          type="button"
          style={{ width: 'auto', padding: '0 24px' }}
        >
          {'<'} {t('esg.back')}
        </AddButton>
        <CloseButton type="button">{t('esg.draft')}</CloseButton>
      </div>
    </Container>
  );
}
