export const emptyProductForm = {
  id: 0,
  name: '',
  description: '',
  production_country: {
    iso_code: '',
    name: '',
  },
  production_location: '',
  certifications_description: '',
  cpc_industry: {
    code: '',
    description: '',
  },
  functional_unit: '',
  data_collection_period: '',
  weight: null,
  weight_unit: '',
  dimensions: '',
  dimensions_unit: '',
  volume: null,
  volume_unit: '',
  production_process_description: '',
  assumptions_description: '',
  reference_service_life: '',
  cutoff_rules: '',
  additional_information: '',
  lifecycle_boundaries: '',
  distribution_regions: [
    {
      iso_code: '',
      name: '',
    },
  ],
  materials: [
    { tempId: 0, material_name: '', percentage: 0, is_basic: true },
    {
      tempId: 0,
      material_name: '',
      percentage: 0,
      is_basic: false,
    },
  ],
  goals: {
    intended_applications: '',
    study_reasons: '',
    target_audience: '',
    comissioner_name: '',
    external_experts_involved: true,
    external_experts_description: '',
    results_disclosed: false,
    assertions_disclosed: false,
    results_confidential: false,
    assertions_confidential: false,
    disclosure_description: '',
    comment: '',
  },
  nace_industries: [{ code: '', description: '' }],
};

const weightResolver = (item: null | string | number) => {
  if (item === null) {
    return null;
  } else if (typeof item === 'number') {
    return item;
  } else if (typeof item === 'string') {
    return +item.replace(',', '.');
  }
};

export const sanitizedValues = (
  values: typeof emptyProductForm,
  organization: any
) => {
  const hasValue = (x: any) => x !== undefined && x !== null && x !== '';
  // sanitize object with regard to its pk, and map it to an object: {pk}
  const sanitizedSingle = <T>(item: T, pk_name: keyof T) => {
    return hasValue(item[pk_name]) ? { [pk_name]: item[pk_name] } : null;
  };
  const sanitizedList = <T>(items: T[], pk_name: keyof T) => {
    return items
      .map((x) => sanitizedSingle(x, pk_name))
      .filter((x) => x !== null);
  };
  return {
    ...values,
    organization_id: organization.id,
    weight: weightResolver(values.weight),
    materials: values.materials.filter((el) => hasValue(el.material_name)),
    nace_industries: sanitizedList(values.nace_industries, 'code'),
    distribution_regions: sanitizedList(
      values.distribution_regions,
      'iso_code'
    ),
    production_country: sanitizedSingle(values.production_country, 'iso_code'),
    cpc_industry: sanitizedSingle(values.cpc_industry, 'code'),
  };
};
