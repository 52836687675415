import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import themeColors from '../utils/theme';
import dialogTheme from './dialog';

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: themeColors.greenBrand,
      contrastText: themeColors.pureWhite,
    },
    secondary: {
      main: themeColors.pureWhite,
      contrastText: themeColors.greenBrand,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 28,
          fontSize: 18,
          height: 56,
          padding: '0 24px',
          ':hover': {
            boxShadow: 'none',
          },
          lineHeight: 1.25,
          ':active': { backgroundColor: themeColors.greenPressed },
          '&.MuiButton-containedSecondary': {
            // without a border, it's usually white-on-white
            border: '1px solid #D9D9D9',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&:pressed': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontFamily: 'Poppins',
            '& a': {
              // color links with primary color
              color: theme.palette.primary.main,
            },
          }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            // if Checkbox is used within FormControlLabel dissallow shrinking its outline
            flexShrink: 0,
          },
        },
      },
    },
  },
});

const theme = createTheme(deepmerge(baseTheme, dialogTheme));

export default theme;
