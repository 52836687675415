import React from 'react';
import * as _ from 'lodash';

import { AvailableProductsState } from './AvailableProducts';
import { BasicInformationState } from './BasicInformation';
import { LocationState } from './Location';
import { OrganizationalStructureState } from './OrganizationalStructure';
import {
  OrganizationInFormSchema,
  OrganizationOutFormSchema,
} from '../../index.types';
import { ErrorBuilder, SchemaErrors } from '../../../utils/validation';

type FormState = {
  organizationalStructureState: OrganizationalStructureState;
  basicInformationState: BasicInformationState;
  locationState: LocationState;
  availableProductsState: AvailableProductsState;
};

export type UnitFormErrors = SchemaErrors<OrganizationInFormSchema>;

type ValidationContextType = UnitFormErrors;

export const UnitFormValidationContext =
  React.createContext<ValidationContextType>({});

export const validateForm = (
  data: OrganizationInFormSchema,
  is_creating: boolean
): UnitFormErrors => {
  const builder = new ErrorBuilder(data).checkTruthy(
    ['name', 'country'],
    'validation.required'
  );

  if (is_creating) {
    builder.checkTruthy(['parent_id'], 'validation.required');
  }

  return builder.build();
};

export function getSchemaFromStates(
  state: FormState
): OrganizationInFormSchema {
  const body: OrganizationInFormSchema = {
    parent_id: state.organizationalStructureState.groupId,
    unit_type: state.organizationalStructureState.unitType,
    name: state.basicInformationState.name,
    nace_code_id: state.basicInformationState.naceCode,
    pkd_code_id: state.basicInformationState.pkdCode,
    vat_identifier: state.basicInformationState.nip,
    regon: state.basicInformationState.regon,
    city: state.locationState.city,
    country: state.locationState.country,
    active_products: state.availableProductsState.availableProducts,
  };
  return body;
}

// set everything from the template, except 'name'
export function setStatesFromTemplate(
  state: FormState,
  template: OrganizationInFormSchema
) {
  state.organizationalStructureState.setUnitType(template.unit_type);
  state.organizationalStructureState.setGroupId(template.parent_id);
  state.basicInformationState.setNaceCode(template.nace_code_id);
  state.basicInformationState.setPkdCode(template.pkd_code_id);
  state.basicInformationState.setNip(template.vat_identifier || '');
  state.basicInformationState.setRegon(template.regon || '');
  state.locationState.setCity(template.city || '');
  state.locationState.setCountry(template.country);
  state.availableProductsState.setAvailableProducts(template.active_products);
}

export function setStatesFromResponseBody(
  state: FormState,
  organization: OrganizationOutFormSchema
) {
  setStatesFromTemplate(state, organization);
  state.basicInformationState.setName(organization.name);
}

export const getYearPages = (
  startYear: number,
  endYear: number,
  perPage = 4
) => {
  const chunks = _.chunk(_.range(startYear, endYear + 1), perPage);
  return Object.fromEntries(
    chunks.map((years) => {
      const first = years[0];
      const last = years[years.length - 1];
      return [`${first}-${last}`, years];
    })
  );
};
