import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

import { ModulesButton, ProductItem } from './index.styles';
import { StyledMenu, StyledTypography } from '../index.styles';
import IconSvg from '../../../views/components/IconSvg';
import { usePlatform } from 'src/redux-file/hooks';
import { ProductPlatform } from 'src/utils/api.interfaces';
import { usePlatformNavigation } from '../../../utils/navigation';

const Modules = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const { availableProducts } = usePlatform();
  const { getPathToProduct } = usePlatformNavigation();

  return (
    <>
      <ModulesButton startIcon={<AppsIcon />} onClick={handleOpen}>
        <StyledTypography>{t('topBar.buttons.modules')}</StyledTypography>
      </ModulesButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            padding: '0px 24px',
          },
        }}
      >
        <Typography
          sx={{
            m: '10px 0px',
            fontSize: '14px',
            fontWeight: 600,
            color: '#5c5c5c',
          }}
        >
          {t('switch.text')}
        </Typography>
        {Object.entries(ProductPlatform).map(([_, value]) => {
          const isProductAvailable = availableProducts.includes(value);

          const menuItemProps = isProductAvailable
            ? {
                to: getPathToProduct(value),
                style: { cursor: 'pointer' },
                onClick: handleClose,
              }
            : { to: '#', style: { opacity: 0.2 } };

          return (
            <ProductItem key={`product.${value}`} {...menuItemProps}>
              <IconSvg name={`product.${value}`} />
              <Trans i18nKey={t(`switch.${value}`)} />
            </ProductItem>
          );
        })}
      </StyledMenu>
    </>
  );
};
export default Modules;
