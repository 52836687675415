import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Summary, SurveyData } from '../index.types';
import { StyledPanel } from '../../../../../styles';
import { StyledTextLight } from '../index.styles';
import HelpIcon from '@mui/icons-material/Help';
import {
  GridRow,
  GridHeader,
  GridItem,
  StyledText,
  StyledTitle,
  FunctionalHeader,
  SubTitleText,
} from './index.styles';
import { Button, Dialog, IconButton } from '@mui/material';
import CustomMuiSelect from '../../../../../../components/CustomMuiSelect';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../redux-file/hooks';
import { ReactSVG } from 'react-svg';

const formatValue = (
  value: number | null | undefined,
  fallback: string = '-'
) => value?.toFixed(2) ?? fallback;

/**
 * Calculates an RGBA color string based on the given value and RGB color.
 * Returns fully transparent color for null values.
 *
 * @param {number | null} value - Used to determine opacity, ranging from 0.2 (for 0) to 1 (for 10).
 * @param {string} colorRGB - Base RGB values as "99, 211, 164,".
 * @returns {string} RGBA color string.
 */
const getBackgroundColor = (value: number | null, colorRGB: string): string => {
  const startAlpha = 0.2;
  const endAlpha = 1;

  if (value === null) {
    return 'rgba(0, 0, 0, 0)';
  }

  const alpha = startAlpha + ((endAlpha - startAlpha) * value) / 10;

  return `rgba(${colorRGB} ${alpha})`;
};

interface ScoreGridItemProps {
  score: number | null;
  colorRGB: string;
}

function ScoreGridItem({ score, colorRGB }: ScoreGridItemProps) {
  return (
    <GridItem
      style={{
        width: '56px',
        height: '32px',
        borderRadius: '8px',
        backgroundColor: getBackgroundColor(score, colorRGB),
        color: '#38414f',
        fontFamily: 'Poppins',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        letterSpacing: '0.26px',
        border: 'none',
      }}
    >
      {formatValue(score)}
    </GridItem>
  );
}

export default function StakeholdersImpactTable({
  data,
}: {
  data: SurveyData;
}) {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.platform);

  const sortOptions = [
    {
      value: 'positive',
      label: t(
        'esg.dma.stakeholderAnalytics.dashboard.impactTable.sortingOptions.mostPositive'
      ),
    },
    {
      value: 'negative',
      label: t(
        'esg.dma.stakeholderAnalytics.dashboard.impactTable.sortingOptions.mostNegative'
      ),
    },
  ];
  const [open, setOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<string>('positive');
  const handleSortChange = (value: any) => {
    setSort(value);
  };

  const sortedData = useMemo(() => {
    const newData = [...data.summary];
    if (sort === 'positive') {
      newData.sort(
        (a, b) =>
          (b.calculated_positive_score ?? 0) -
          (a.calculated_positive_score ?? 0)
      );
    } else if (sort === 'negative') {
      newData.sort(
        (a, b) =>
          (b.calculated_negative_score ?? 0) -
          (a.calculated_negative_score ?? 0)
      );
    }
    return newData;
  }, [sort, data.summary]);

  return (
    <>
      <StyledPanel>
        <StyledTitle>
          {t('esg.dma.stakeholderAnalytics.dashboard.barchart.title')}
        </StyledTitle>
        <StyledTextLight>
          {t('esg.dma.stakeholderAnalytics.dashboard.barchart.subtitle')}
        </StyledTextLight>
        <FunctionalHeader>
          <CustomMuiSelect
            label={t(
              'esg.dma.stakeholderAnalytics.dashboard.impactTable.sorting'
            )}
            options={sortOptions}
            value={sort}
            onChange={(value) => handleSortChange(value)}
          />
          <Button
            sx={{ marginRight: '-2px' }}
            startIcon={<HelpIcon sx={{ color: '#38414f' }} />}
            onClick={() => setOpen(true)}
          >
            <SubTitleText>
              {t('esg.dma.stakeholderAnalytics.dashboard.howWeCalculate')}
            </SubTitleText>
          </Button>
        </FunctionalHeader>
        <div
          style={{
            paddingTop: '28px',
            width: '100%',
            display: 'block',
          }}
        >
          <GridHeader>
            <GridItem style={{ backgroundColor: '#F8F8F8' }}>
              <StyledText>
                {t('esg.dma.stakeholderAnalytics.dashboard.impactTable.number')}
              </StyledText>
            </GridItem>
            <GridItem
              style={{ backgroundColor: '#F8F8F8', justifyContent: 'start' }}
            >
              <StyledText>
                {t('esg.dma.stakeholderAnalytics.dashboard.impactTable.topic')}
              </StyledText>
            </GridItem>
            <GridItem>
              <StyledText>
                {t(
                  'esg.dma.stakeholderAnalytics.dashboard.impactTable.positiveImpactScale'
                )}
              </StyledText>
            </GridItem>
            <GridItem>
              <StyledText>
                {t(
                  'esg.dma.stakeholderAnalytics.dashboard.impactTable.negativeImpactScale'
                )}
              </StyledText>
            </GridItem>
          </GridHeader>

          {sortedData.map((item: Summary) => (
            <GridRow key={item.stakeholder_topic.key}>
              <GridItem style={{ backgroundColor: '#F8F8F8' }}>
                <StyledText style={{ textAlign: 'start' }}>
                  {item.stakeholder_topic.key}
                </StyledText>
              </GridItem>
              <GridItem
                style={{ backgroundColor: '#F8F8F8', justifyContent: 'start' }}
              >
                <StyledText style={{ textAlign: 'start' }}>
                  {item.stakeholder_topic.name}
                </StyledText>
              </GridItem>
              <GridItem>
                <StyledText>
                  <ScoreGridItem
                    score={item.calculated_positive_score}
                    colorRGB="99, 211, 164,"
                  />
                </StyledText>
              </GridItem>
              <GridItem>
                <StyledText>
                  <ScoreGridItem
                    score={item.calculated_negative_score}
                    colorRGB="245, 110, 110,"
                  />
                </StyledText>
              </GridItem>
            </GridRow>
          ))}
        </div>
      </StyledPanel>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <ReactSVG
          src={`https://assets.envirly.pl/dma/stakeholders/${language}/impact_calculation.svg`}
        />
      </Dialog>
    </>
  );
}
