import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { Overlay } from '../Title/index.styles';
import { Form, FormBox } from './index.styles';
import { AutocompleteFreesolo, Select } from '../fields';
import {
  type_of_vehicle,
  unit_of_distance,
  vehicle_names_en,
  vehicle_names_pl,
} from './data';
import FormButtons from '../FormButtons';
import {
  getElecricityEmissionsData,
  getElecricityEmissionsDataEmissionShares,
  postElecricityEmissionsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { autoCompleteOptions } from '../../../../utils/helpers';

export default function EvDistanceForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  setNewForm: Function;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const { language } = useAppSelector((state) => state.platform);
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  let type_of_vehicle_error = false;
  let unit_of_distance_error = false;
  let distance_travelled_error = false;
  let manual_factor_error = false;

  const [type_of_vehicle_error_state, set_type_of_vehicle_error_state] =
    useState(false);
  const [unit_of_distance_error_state, set_unit_of_distance_error_state] =
    useState(false);
  const [distance_travelled_error_state, set_distance_travelled_error_state] =
    useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);

  const type_of_vehicle_validation = (value: string) => {
    if (value === '') type_of_vehicle_error = true;
    return type_of_vehicle_error;
  };
  const unit_of_distance_validation = (value: string) => {
    if (value === '') unit_of_distance_error = true;
    return unit_of_distance_error;
  };
  const distance_travelled_validation = (value: string) => {
    if (value === '') distance_travelled_error = true;
    return distance_travelled_error;
  };
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const handleTypeOfVehicleLabel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
      type_of_vehicle_label: value,
      unit_of_distance: commutingType.unit_of_distance,
      distance_travelled: commutingType.distance_travelled,
      manual_factor: commutingType.manual_factor,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setTypeOfVehicle = (value: string) => {
    type_of_vehicle_validation(value);
    set_type_of_vehicle_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      type_of_vehicle: value,
      type_of_vehicle_label: commutingType.type_of_vehicle_label,
      unit_of_distance: commutingType.unit_of_distance,
      distance_travelled: commutingType.distance_travelled,
      manual_factor: commutingType.manual_factor,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_type_of_vehicle_error_state(true);
  };
  const setUnitOfDistance = (value: string) => {
    unit_of_distance_validation(value);
    set_unit_of_distance_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
      type_of_vehicle_label: commutingType.type_of_vehicle_label,
      unit_of_distance: value,
      distance_travelled: commutingType.distance_travelled,
      manual_factor: commutingType.manual_factor,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_unit_of_distance_error_state(true);
  };
  const handleDistance = (value: string) => {
    distance_travelled_validation(value);
    set_distance_travelled_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
      type_of_vehicle_label: commutingType.type_of_vehicle_label,
      unit_of_distance: commutingType.unit_of_distance,
      distance_travelled: value,
      manual_factor: commutingType.manual_factor,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_distance_travelled_error_state(true);
  };
  const handleFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
      type_of_vehicle_label: commutingType.type_of_vehicle_label,
      unit_of_distance: commutingType.unit_of_distance,
      distance_travelled: commutingType.distance_travelled,
      manual_factor: value,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '' && newForms[id].type_of_vehicle === 'other.manual_factor')
      set_manual_factor_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !type_of_vehicle_error &&
      !unit_of_distance_error &&
      !distance_travelled_error &&
      !manual_factor_error
    ) {
      dispatch(
        postElecricityEmissionsData({
          data,
          period: timeConverter(selectedDate, reportingPeriod),
        })
      ).then(() => {
        dispatch(
          getElecricityEmissionsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getElecricityEmissionsDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].type_of_vehicle === '') {
      set_type_of_vehicle_error_state(true);
      type_of_vehicle_error = true;
    }
    if (state[id].unit_of_distance === '') {
      set_unit_of_distance_error_state(true);
      unit_of_distance_error = true;
    }
    if (state[id].distance_travelled === '') {
      set_distance_travelled_error_state(true);
      distance_travelled_error = true;
    }

    if (
      state[id].type_of_vehicle === 'other.manual_factor' &&
      !state[id].manual_factor
    ) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      electricity_usage: combinedData.electricity_usage,
      steam_usage: combinedData.steam_usage,
      heat_usage: combinedData.heat_usage,
      cooling_usage: combinedData.cooling_usage,
      electric_vehicles_consumptionwise_usage:
        combinedData.electric_vehicles_consumptionwise_usage,
      electric_vehicles_distancewise_usage: tempState,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              error={false}
              index={1}
              label={t(
                `electricity-emission.vehicles.distance.type_of_vehicle_label`
              )}
              value={state[id].type_of_vehicle_label}
              setValue={(value: string) =>
                handleTypeOfVehicleLabel(autoCompleteOptions(value))
              }
              max={123}
              options={language === 'en' ? vehicle_names_en : vehicle_names_pl}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              index={1}
              max={12}
              options={type_of_vehicle}
              label={t(
                `electricity-emission.vehicles.distance.type_of_vehicle`
              )}
              error={type_of_vehicle_error_state}
              value={state[id].type_of_vehicle}
              setValue={(e: string) => setTypeOfVehicle(e)}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              index={1}
              max={12}
              options={unit_of_distance}
              label={t(
                `electricity-emission.vehicles.distance.unit_of_distance`
              )}
              error={unit_of_distance_error_state}
              value={state[id].unit_of_distance}
              setValue={(e: string) => setUnitOfDistance(e)}
            />
          </FormBox>

          <FormBox variant="100">
            <TextFieldNumber
              label={t(
                `electricity-emission.vehicles.distance.distance_travelled`
              )}
              active
              error={distance_travelled_error_state}
              index={1}
              value={state[id].distance_travelled}
              setValue={(e: string) => handleDistance(e)}
            />
          </FormBox>

          {state[id].type_of_vehicle === 'other.manual_factor' && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t(
                  `electricity-emission.vehicles.distance.manual_factor`
                )}
                active
                error={manual_factor_error_state}
                index={1}
                value={state[id].manual_factor}
                setValue={(e: string) => handleFactor(e)}
              />
            </FormBox>
          )}
        </div>

        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              state[id].distance_travelled !== '' &&
              state[id].type_of_vehicle !== '' &&
              state[id].unit_of_distance !== '' &&
              (state[id].type_of_vehicle !== 'other.manual_factor' ||
                !!state[id].manual_factor)
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
