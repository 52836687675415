import React, { useEffect, useRef, useState } from 'react';
import AddButtonHolder from '../../components/Questionnaire/AddButtonHolder';
import {
  PageContainer,
  Description,
  HintBox,
  HintTitle,
  PointNumber,
  PointsElement,
  PointsTitle,
  PointsWrapper,
  PointText,
  PreviewBox,
  Reminder,
  Section,
  SectionGroupContainer,
  StyledHeader,
  StyledTh,
  CopyWrapper,
} from './index.styles';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import {
  getAssetsFranchisesAndInvestmentsData,
  getAssetsFranchisesAndInvestmentsEmissionSharesData,
  putAssetsFranchisesAndInvestmentsData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import FranchisesForm from '../../components/Questionnaire/forms/franchises-assets';
import FranchisesPreview from '../../components/Questionnaire/preview/franchisesPreview';
import UpstreamLeasedBuildingsForm from '../../components/Questionnaire/forms/upstream_leased_buildings-assets';
import UlbPreview from '../../components/Questionnaire/preview/ulbPreview';
import LeasedAssetsForm from '../../components/Questionnaire/forms/assets-assets';
import AssetsPreview from '../../components/Questionnaire/preview/assetsPreview';
import InvestmentsForm from '../../components/Questionnaire/forms/investments-assets';
import InvestmentsPreview from '../../components/Questionnaire/preview/investmentsPreview';
import AddProductButton from '../../../components/AddProductButton';
import { hint_data_downstream, hint_data_upstream } from './hint_data';
import {
  downstream_leased_assets_cloud,
  downstream_leased_buildings_cloud,
  franchise_cloud,
  investments_cloud,
  upstream_leased_assets_cloud,
  upstream_leased_buildings_cloud,
} from './clouds_data';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import UploadExcelButton from '../../../components/UploadExcelButton';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import CopyForm from '../../components/CopyForm';

export default function AssetsQuestionnaire() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const reportingCurrency = organization.co2.reporting_currency;
  const { language } = useAppSelector((state) => state.platform);

  const {
    selectedDate,
    assetsData,
    assetsDataError,
    assetsDataLoading,
    assetsDataEmissionShares,
  } = useAppSelector((state) => state.questionnaire);

  const franchisesInit = {
    name: '',
    amount_kg_eq_co2_in_period: '',
    emission_shared: 0,
  };

  const buildingInit = {
    emission_amount_is_known: false,
    name: '',
    leased_building_data_emission_unknown: {
      type_of_builidng: '',
      leased_area_m2: '',
      year_of_construction: '',
    },
    leased_building_data_emission_known: {
      amount_kg_eq_co2_in_period: '',
    },
    emission_shared: 0,
  };

  const assetsInit = {
    emission_amount_is_known: false,
    name: '',
    leased_asset_data_emission_unknown: {
      type_of_fuel: '',
      unit_of_fuel: '',
      amount_of_fuel_in_period: '',
      spending_on_electricity_pln_or_kwh_in_period: '',
      unit_of_energy_usage: '',
    },
    leased_asset_data_emission_known: {
      amount_kg_eq_co2_in_period: '',
    },
    emission_shared: 0,
  };

  const investmentsInit = {
    emission_amount_is_known: false,
    name: '',
    investment_data_factor_unknown: {
      investment_revenues_in_perdiod_pln: '',
      industry: '',
    },
    investment_data_factor_known: {
      amount_kg_eq_co2_in_period: '',
    },
    emission_shared: 0,
  };

  // ULB - upstream_leased_buildings
  // DLB - downstream_leased_buildings

  // ULA - upstream_leased_assets
  // DLA - downstream_leased_assets

  const [activefranchises, setActivefranchises] = useState<undefined | number>(
    undefined
  );
  const [activeULB, setActiveUlb] = useState<undefined | number>(undefined);
  const [activeDLB, setActiveDlb] = useState<undefined | number>(undefined);
  const [activeULA, setActiveULA] = useState<undefined | number>(undefined);
  const [activeDLA, setActiveDLA] = useState<undefined | number>(undefined);
  const [activeInv, setActiveInv] = useState<undefined | number>(undefined);

  const [nffranchises, setNffranchises] = useState(true);
  const [nfULB, setNfULB] = useState(true);
  const [nfDLB, setNfDLB] = useState(true);
  const [nfULA, setNfULA] = useState(true);
  const [nfDLA, setNfDLA] = useState(true);
  const [nfInv, setNfInv] = useState(true);

  const [franchisesState, setFranchisesState] = useState([franchisesInit]);
  const [ulbState, setUlbState] = useState([buildingInit]);
  const [dlbState, setDlbState] = useState([buildingInit]);
  const [ulaState, setUlaState] = useState([assetsInit]);
  const [dlaState, setDlaState] = useState([assetsInit]);
  const [invState, setInvState] = useState([investmentsInit]);

  const franchises_ref = useRef(null);
  const upstream_leased_buildings_ref = useRef(null);
  const downstream_leased_buildings_ref = useRef(null);
  const upstream_leased_assets_ref = useRef(null);
  const downstream_leased_assets_ref = useRef(null);
  const investments_ref = useRef(null);

  useEffect(() => {
    if (assetsData.franchises.length === 0) {
      setNffranchises(true);
    } else {
      setNffranchises(false);
    }

    setFranchisesState(
      assetsData.franchises.length !== 0 &&
        assetsDataEmissionShares.franchises.length !== 0
        ? assetsData.franchises.map((el: any, i: number) => ({
            ...el,
            emission_shared: assetsDataEmissionShares.franchises[i]?.share,
          }))
        : [franchisesInit]
    );

    if (assetsData.upstream_leased_buildings.length === 0) {
      setNfULB(true);
    } else {
      setNfULB(false);
    }

    setUlbState(
      assetsData.upstream_leased_buildings.length !== 0 &&
        assetsDataEmissionShares.upstream_leased_buildings.length !== 0
        ? assetsData.upstream_leased_buildings.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              assetsDataEmissionShares.upstream_leased_buildings[i]?.share,
          }))
        : [buildingInit]
    );

    if (assetsData.downstream_leased_buildings.length === 0) {
      setNfDLB(true);
    } else {
      setNfDLB(false);
    }

    setDlbState(
      assetsData.downstream_leased_buildings.length !== 0 &&
        assetsDataEmissionShares.downstream_leased_buildings.length !== 0
        ? assetsData.downstream_leased_buildings.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              assetsDataEmissionShares.downstream_leased_buildings[i]?.share,
          }))
        : [buildingInit]
    );

    if (assetsData.upstream_leased_assets.length === 0) {
      setNfULA(true);
    } else {
      setNfULA(false);
    }

    setUlaState(
      assetsData.upstream_leased_assets.length !== 0 &&
        assetsDataEmissionShares.upstream_leased_assets.length !== 0
        ? assetsData.upstream_leased_assets.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              assetsDataEmissionShares.upstream_leased_assets[i]?.share,
          }))
        : [assetsInit]
    );

    if (assetsData.downstream_leased_assets.length === 0) {
      setNfDLA(true);
    } else {
      setNfDLA(false);
    }

    setDlaState(
      assetsData.downstream_leased_assets.length !== 0 &&
        assetsDataEmissionShares.downstream_leased_assets.length !== 0
        ? assetsData.downstream_leased_assets.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              assetsDataEmissionShares.downstream_leased_assets[i]?.share,
          }))
        : [assetsInit]
    );

    if (assetsData.investments.length === 0) {
      setNfInv(true);
    } else {
      setNfInv(false);
    }

    setInvState(
      assetsData.investments.length !== 0 &&
        assetsDataEmissionShares.investments.length !== 0
        ? assetsData.investments.map((el: any, i: number) => ({
            ...el,
            emission_shared: assetsDataEmissionShares.investments[i]?.share,
          }))
        : [investmentsInit]
    );
  }, [assetsData, selectedDate, assetsDataEmissionShares]);

  const refreshForm = () => {
    dispatch(
      getAssetsFranchisesAndInvestmentsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getAssetsFranchisesAndInvestmentsEmissionSharesData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };

  const sendData = (data: any) => {
    dispatch(
      putAssetsFranchisesAndInvestmentsData({
        period: timeConverter(selectedDate, reportingPeriod),
        data,
      })
    );
  };

  const combineInvestmentData = (fullData: any, investmentData: any) => {
    return {
      upstream_leased_buildings: fullData.upstream_leased_buildings,
      upstream_leased_assets: fullData.upstream_leased_assets,
      downstream_leased_buildings: fullData.downstream_leased_buildings,
      downstream_leased_assets: fullData.downstream_leased_assets,
      franchises: fullData.franchises,
      investments: [...investmentData, ...fullData.investments],
    };
  };
  const combineFranchiseData = (fullData: any, franchiseData: any) => {
    return {
      ...fullData,
      franchises: [...franchiseData, ...fullData.franchises],
    };
  };
  const combineUpstreamBuildingsData = (
    fullData: any,
    upstreamBuildingsData: any
  ) => {
    return {
      upstream_leased_buildings: [
        ...upstreamBuildingsData,
        ...fullData.upstream_leased_buildings,
      ],
      upstream_leased_assets: fullData.upstream_leased_assets,
      downstream_leased_buildings: fullData.downstream_leased_buildings,
      downstream_leased_assets: fullData.downstream_leased_assets,
      franchises: fullData.franchises,
      investments: fullData.investments,
    };
  };
  const combineUpstreamAssetsData = (
    fullData: any,
    upstreamAssetsData: any
  ) => {
    return {
      upstream_leased_buildings: fullData.upstream_leased_buildings,
      upstream_leased_assets: [
        ...upstreamAssetsData,
        ...fullData.upstream_leased_assets,
      ],
      downstream_leased_buildings: fullData.downstream_leased_buildings,
      downstream_leased_assets: fullData.downstream_leased_assets,
      franchises: fullData.franchises,
      investments: fullData.investments,
    };
  };
  const combineDownstreamAssetsData = (
    fullData: any,
    downstreamAssetsData: any
  ) => {
    return {
      upstream_leased_buildings: fullData.upstream_leased_buildings,
      upstream_leased_assets: fullData.upstream_leased_assets,
      downstream_leased_buildings: fullData.downstream_leased_buildings,
      downstream_leased_assets: [
        ...downstreamAssetsData,
        ...fullData.downstream_leased_assets,
      ],
      franchises: fullData.franchises,
      investments: fullData.investments,
    };
  };
  const combineDownstreamBuildingsData = (
    fullData: any,
    downstreamBuildingsData: any
  ) => {
    return {
      upstream_leased_buildings: fullData.upstream_leased_buildings,
      upstream_leased_assets: fullData.upstream_leased_assets,
      downstream_leased_buildings: [
        ...downstreamBuildingsData,
        ...fullData.downstream_leased_buildings,
      ],
      downstream_leased_assets: fullData.downstream_leased_assets,
      franchises: fullData.franchises,
      investments: fullData.investments,
    };
  };

  useEffect(() => {
    if (!!reportingPeriod) {
      refreshForm();
    }
  }, [selectedDate, organization.id, reportingPeriod]);

  const combinedData = {
    upstream_leased_buildings:
      ulbState.length === 1 &&
      ulbState[0].leased_building_data_emission_unknown?.type_of_builidng ===
        '' &&
      ulbState[0].leased_building_data_emission_known
        ?.amount_kg_eq_co2_in_period === ''
        ? []
        : ulbState,
    upstream_leased_assets:
      ulaState.length === 1 &&
      ulaState[0].leased_asset_data_emission_unknown?.type_of_fuel === '' &&
      ulaState[0].leased_asset_data_emission_known
        ?.amount_kg_eq_co2_in_period === ''
        ? []
        : ulaState,
    downstream_leased_buildings:
      dlbState.length === 1 &&
      dlbState[0].leased_building_data_emission_unknown?.type_of_builidng ===
        '' &&
      dlbState[0].leased_building_data_emission_known
        ?.amount_kg_eq_co2_in_period === ''
        ? []
        : dlbState,
    downstream_leased_assets:
      dlaState.length === 1 &&
      dlaState[0].leased_asset_data_emission_unknown?.type_of_fuel === '' &&
      dlaState[0].leased_asset_data_emission_known
        ?.amount_kg_eq_co2_in_period === ''
        ? []
        : dlaState,
    franchises:
      franchisesState.length === 1 &&
      franchisesState[0].amount_kg_eq_co2_in_period === ''
        ? []
        : franchisesState,
    investments:
      invState.length === 1 &&
      invState[0].investment_data_factor_unknown?.industry === '' &&
      invState[0].investment_data_factor_known?.amount_kg_eq_co2_in_period ===
        ''
        ? []
        : invState,
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('assets-and-other.title')} />
        <Description>{t('assets-and-other.description')}</Description>
        <PointsTitle>{t('assets-and-other.points.title')}</PointsTitle>
        <PointsWrapper>
          <PointsElement>
            <PointNumber>1</PointNumber>
            <PointText
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.points.1'),
              }}
            />
          </PointsElement>
          <PointsElement>
            <PointNumber>2</PointNumber>
            <PointText
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.points.2'),
              }}
            />
          </PointsElement>
          <PointsElement>
            <PointNumber>3</PointNumber>
            <PointText
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.points.3'),
              }}
            />
          </PointsElement>
          <div style={{ paddingLeft: '54px' }}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.point3.1'),
              }}
            />
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t('assets-and-other.point3.1a'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('assets-and-other.point3.1b'),
                }}
              />
            </ul>
            <p
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.point3.2'),
              }}
            />
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t('assets-and-other.point3.2a'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('assets-and-other.point3.2b'),
                }}
              />
            </ul>
          </div>
          <PointsElement>
            <PointNumber>4</PointNumber>
            <PointText
              dangerouslySetInnerHTML={{
                __html: t('assets-and-other.points.4'),
              }}
            />
          </PointsElement>
        </PointsWrapper>
        <Reminder>{t('goods-and-services.reminder')}</Reminder>

        <CopyWrapper>
          <Title title={t('forms.select-period')} description={null} />
          <CopyForm
            form="AssetsFranchisesAndInvestmentsData"
            orgId={organization.id}
            refresh={() => refreshForm()}
          />
        </CopyWrapper>
        <IntuitiveCalendarPeriod
          reportingPeriod={reportingPeriod}
          selectedDate={getFirstDateOfPeriod(
            selectedDate ? new Date(selectedDate) : new Date(),
            reportingPeriod ?? 'M'
          )}
          setSelectedDate={(selDate: Date) =>
            dispatch(setSelectedDate(selDate))
          }
        />

        {assetsDataLoading && <PageLoading />}
        {assetsDataError && <SomethingWentWrong />}

        {!assetsDataLoading && !assetsDataError && (
          <>
            <Section>
              <HintTitle>{t('assets-and-other.upstream.title')}</HintTitle>
              <HintBox>
                <span>{t('hint')}</span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(hint_data_upstream.main),
                  }}
                />
                <ul>
                  {hint_data_upstream.items.map((el: string) => (
                    <li key={el} dangerouslySetInnerHTML={{ __html: t(el) }} />
                  ))}
                </ul>
              </HintBox>
            </Section>

            <Section>
              <div ref={upstream_leased_buildings_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('assets-and-other.upstream_leased_buildings.title')}
                boxContent={upstream_leased_buildings_cloud}
                active={activeULB}
              />
              {nfULB && (
                <>
                  <PreviewBox>
                    {ulbState.map((el, id) => (
                      <UpstreamLeasedBuildingsForm
                        setNewForm={setNfULB}
                        combinedData={combinedData}
                        active={activeULB}
                        setActive={setActiveUlb}
                        variant="u"
                        id={id}
                        key={id}
                        setState={setUlbState}
                        state={ulbState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('assets-and-other.upstream_leased_buildings.title')}
                    slug="LeasedBuildingData"
                    fullData={combinedData}
                    combineData={combineUpstreamBuildingsData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nfULB && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.form.name'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.form.type_of_builidng'
                        )}
                      </StyledTh>
                      <StyledTh width={12}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.table.area'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.table.year'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {ulbState.map((el, id) => {
                        if (activeULB === id) {
                          return (
                            <UpstreamLeasedBuildingsForm
                              setNewForm={setNfULB}
                              combinedData={combinedData}
                              active={activeULB}
                              setActive={setActiveUlb}
                              variant="u"
                              id={id}
                              key={id}
                              setState={setUlbState}
                              state={ulbState}
                            />
                          );
                        } else {
                          return (
                            <UlbPreview
                              language={language}
                              setState={setUlbState}
                              id={id}
                              key={id}
                              state={ulbState}
                              setActive={setActiveUlb}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setUlbState([buildingInit, ...ulbState]);
                          setActiveUlb(0);
                          if (
                            upstream_leased_buildings_ref &&
                            upstream_leased_buildings_ref.current
                          ) {
                            // @ts-ignore
                            upstream_leased_buildings_ref.current.scrollIntoView(
                              {
                                behavior: 'smooth',
                              }
                            );
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'assets-and-other.upstream_leased_buildings.title'
                        )}
                        slug="LeasedBuildingData"
                        fullData={combinedData}
                        combineData={combineUpstreamBuildingsData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={upstream_leased_assets_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('assets-and-other.upstream_leased_assets.title')}
                boxContent={upstream_leased_assets_cloud}
                active={activeULA}
              />
              {nfULA && (
                <>
                  <PreviewBox>
                    {ulaState.map((el, id) => (
                      <LeasedAssetsForm
                        setNewForm={setNfULA}
                        setActive={setActiveULA}
                        active={activeULA}
                        variant="u"
                        combinedData={combinedData}
                        id={id}
                        key={id}
                        setState={setUlaState}
                        state={ulaState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('assets-and-other.upstream_leased_assets.title')}
                    slug="LeasedAssetData"
                    fullData={combinedData}
                    combineData={combineUpstreamAssetsData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nfULA && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.name'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.type-of-fuel'
                        )}
                      </StyledTh>
                      <StyledTh width={12}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.fuel-consumed'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.fuel-unit'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {ulaState.map((el, id) => {
                        if (activeULA === id) {
                          return (
                            <LeasedAssetsForm
                              setNewForm={setNfULA}
                              setActive={setActiveULA}
                              active={activeULA}
                              variant="u"
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setUlaState}
                              state={ulaState}
                            />
                          );
                        } else {
                          return (
                            <AssetsPreview
                              language={language}
                              setState={setUlaState}
                              id={id}
                              key={id}
                              state={ulaState}
                              setActive={setActiveULA}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setUlaState([assetsInit, ...ulaState]);
                          setActiveULA(0);
                          if (
                            upstream_leased_assets_ref &&
                            upstream_leased_assets_ref.current
                          ) {
                            // @ts-ignore
                            upstream_leased_assets_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'assets-and-other.upstream_leased_assets.title'
                        )}
                        slug="LeasedAssetData"
                        fullData={combinedData}
                        combineData={combineUpstreamAssetsData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <HintTitle>{t('assets-and-other.downstream.title')}</HintTitle>
              <HintBox>
                <span>{t('hint')}</span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(hint_data_downstream.main),
                  }}
                />
                <ul>
                  {hint_data_downstream.items.map((el: string) => (
                    <li key={el} dangerouslySetInnerHTML={{ __html: t(el) }} />
                  ))}
                </ul>
              </HintBox>
            </Section>

            <Section>
              <div ref={downstream_leased_buildings_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('assets-and-other.downstream_leased_buildings.title')}
                boxContent={downstream_leased_buildings_cloud}
                active={activeDLB}
              />
              {nfDLB && (
                <>
                  <PreviewBox>
                    {dlbState.map((el, id) => (
                      <UpstreamLeasedBuildingsForm
                        setNewForm={setNfDLB}
                        combinedData={combinedData}
                        active={activeDLB}
                        setActive={setActiveDlb}
                        variant="d"
                        id={id}
                        key={id}
                        setState={setDlbState}
                        state={dlbState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t(
                      'assets-and-other.downstream_leased_buildings.title'
                    )}
                    slug="LeasedBuildingData"
                    fullData={combinedData}
                    combineData={combineDownstreamBuildingsData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nfDLB && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.form.name'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.form.type_of_builidng'
                        )}
                      </StyledTh>
                      <StyledTh width={12}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.table.area'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_buildings.table.year'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {dlbState.map((el, id) => {
                        if (activeDLB === id) {
                          return (
                            <UpstreamLeasedBuildingsForm
                              setNewForm={setNfDLB}
                              combinedData={combinedData}
                              active={activeDLB}
                              setActive={setActiveDlb}
                              variant="d"
                              id={id}
                              key={id}
                              setState={setDlbState}
                              state={dlbState}
                            />
                          );
                        } else {
                          return (
                            <UlbPreview
                              language={language}
                              setState={setDlbState}
                              id={id}
                              key={id}
                              state={dlbState}
                              setActive={setActiveDlb}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setDlbState([buildingInit, ...dlbState]);
                          setActiveDlb(0);
                          if (
                            downstream_leased_buildings_ref &&
                            downstream_leased_buildings_ref.current
                          ) {
                            // @ts-ignore
                            downstream_leased_buildings_ref.current.scrollIntoView(
                              {
                                behavior: 'smooth',
                              }
                            );
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'assets-and-other.downstream_leased_buildings.title'
                        )}
                        slug="LeasedBuildingData"
                        fullData={combinedData}
                        combineData={combineDownstreamBuildingsData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={downstream_leased_assets_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('assets-and-other.downstream_leased_assets.title')}
                boxContent={downstream_leased_assets_cloud}
                active={activeDLA}
              />
              {nfDLA && (
                <>
                  <PreviewBox>
                    {dlaState.map((el, id) => (
                      <LeasedAssetsForm
                        setNewForm={setNfDLA}
                        setActive={setActiveDLA}
                        active={activeDLA}
                        variant="d"
                        combinedData={combinedData}
                        id={id}
                        key={id}
                        setState={setDlaState}
                        state={dlaState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('assets-and-other.downstream_leased_assets.title')}
                    slug="LeasedAssetData"
                    fullData={combinedData}
                    combineData={combineDownstreamAssetsData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nfDLA && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.name'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.type-of-fuel'
                        )}
                      </StyledTh>
                      <StyledTh width={12}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.fuel-consumed'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'assets-and-other.upstream_leased_assets.table.fuel-unit'
                        )}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {dlaState.map((el, id) => {
                        if (activeDLA === id) {
                          return (
                            <LeasedAssetsForm
                              setNewForm={setNfDLA}
                              setActive={setActiveDLA}
                              active={activeDLA}
                              variant="d"
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setDlaState}
                              state={dlaState}
                            />
                          );
                        } else {
                          return (
                            <AssetsPreview
                              language={language}
                              setState={setDlaState}
                              id={id}
                              key={id}
                              state={dlaState}
                              setActive={setActiveDLA}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setDlaState([assetsInit, ...dlaState]);
                          setActiveDLA(0);
                          if (
                            downstream_leased_assets_ref &&
                            downstream_leased_assets_ref.current
                          ) {
                            // @ts-ignore
                            downstream_leased_assets_ref.current.scrollIntoView(
                              {
                                behavior: 'smooth',
                              }
                            );
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'assets-and-other.downstream_leased_assets.title'
                        )}
                        slug="LeasedAssetData"
                        fullData={combinedData}
                        combineData={combineDownstreamAssetsData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={franchises_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('assets-and-other.franchise.title')}
                boxContent={franchise_cloud}
                active={activefranchises}
              />
              {nffranchises && (
                <>
                  <PreviewBox>
                    {franchisesState.map((el, id) => (
                      <FranchisesForm
                        setNewForm={setNffranchises}
                        setActive={setActivefranchises}
                        active={activefranchises}
                        combinedData={combinedData}
                        id={id}
                        key={id}
                        setState={setFranchisesState}
                        state={franchisesState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('assets-and-other.franchise.title')}
                    slug="FranchiseData"
                    fullData={combinedData}
                    combineData={combineFranchiseData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nffranchises && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={80}>
                        {t('assets-and-other.franchise.name')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {franchisesState.map((el, id) => {
                        if (activefranchises === id) {
                          return (
                            <FranchisesForm
                              setNewForm={setNffranchises}
                              setActive={setActivefranchises}
                              active={activefranchises}
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setFranchisesState}
                              state={franchisesState}
                            />
                          );
                        } else {
                          return (
                            <FranchisesPreview
                              setState={setFranchisesState}
                              id={id}
                              key={id}
                              state={franchisesState}
                              setActive={setActivefranchises}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setFranchisesState([
                            franchisesInit,
                            ...franchisesState,
                          ]);
                          setActivefranchises(0);
                          if (franchises_ref && franchises_ref.current) {
                            // @ts-ignore
                            franchises_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('assets-and-other.franchise.title')}
                        slug="FranchiseData"
                        fullData={combinedData}
                        combineData={combineFranchiseData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={investments_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('assets-and-other.investments.title')}
                boxContent={investments_cloud}
                active={activeInv}
              />
              {nfInv && (
                <>
                  <PreviewBox>
                    {invState.map((el, id) => (
                      <InvestmentsForm
                        setNewForm={setNfInv}
                        active={activeInv}
                        combinedData={combinedData}
                        setActive={setActiveInv}
                        id={id}
                        key={id}
                        setState={setInvState}
                        state={invState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('assets-and-other.investments.title')}
                    slug="InvestmentData"
                    fullData={combinedData}
                    combineData={combineInvestmentData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!nfInv && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={43}>
                        {t('assets-and-other.investments.table.name')}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t('assets-and-other.investments.table.revenue', {
                          currency: reportingCurrency,
                        })}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t('assets-and-other.investments.table.industry')}
                      </StyledTh>
                      <StyledTh width={22}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {invState.map((el, id) => {
                        if (activeInv === id) {
                          return (
                            <InvestmentsForm
                              setNewForm={setNfInv}
                              active={activeInv}
                              combinedData={combinedData}
                              setActive={setActiveInv}
                              id={id}
                              key={id}
                              setState={setInvState}
                              state={invState}
                            />
                          );
                        } else {
                          return (
                            <InvestmentsPreview
                              language={language}
                              setState={setInvState}
                              id={id}
                              key={id}
                              state={invState}
                              setActive={setActiveInv}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setInvState([investmentsInit, ...invState]);
                          setActiveInv(0);
                          if (investments_ref && investments_ref.current) {
                            // @ts-ignore
                            investments_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('assets-and-other.investments.title')}
                        slug="InvestmentData"
                        fullData={combinedData}
                        combineData={combineInvestmentData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>
          </>
        )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
