/* eslint-disable no-unused-vars */

/**
 * ProductPlatform
 * An enumeration.
 */
export enum ProductPlatform {
  Co2 = 'co2',
  Esg = 'esg',
  Lca = 'lca',
}

/** UserOrganizationSchema */
export interface UserOrganizationSchema {
  /** Id */
  id: number;
  /**
   * Name
   * Human-readable name of the company
   * @maxLength 150
   */
  name: string;
}

/** UserSuperorganizationSchema */
export interface UserSuperorganizationSchema {
  /** Id */
  id: number;
  /**
   * Name
   * @maxLength 150
   */
  name: string;
}

/** UserSchema */
export interface UserSchema {
  /** Id */
  id: number;
  /**
   * Language
   * Language of the site content used by user.
   * @maxLength 8
   * @default "pl"
   */
  language: string;
  /**
   * Adres E-Mail
   * @maxLength 254
   */
  email: string;
  /**
   * Status Superużytkownika
   * Designates that this user has all permissions without explicitly assigning them.
   * @default false
   */
  is_superuser: boolean;
  available_products: ProductPlatform[];
  organization?: UserOrganizationSchema;
  superorganization?: UserSuperorganizationSchema;
}

/**
 * ReportingFreq
 * An enumeration.
 */
export enum ReportingFreq {
  M = 'M',
  Q = 'Q',
  Y = 'Y',
}

/** Co2OrganizationDataOutListSchema */
export interface Co2OrganizationDataOutListSchema {
  /**
   * Is Onboarding Completed
   * @default false
   */
  is_onboarding_completed: boolean;
  /**
   * Reporting Freq
   * Affects the frequency of the questionnaires and figures in dashboard etc.
   * @maxLength 16
   * @default "M"
   */
  reporting_freq: ReportingFreq;
  /**
   * Reporting Currency
   * @maxLength 3
   * @default "PLN"
   */
  reporting_currency: string;
  /** Has Data */
  has_data: boolean;
}

/** Co2OrganizationDataOutSchema */
export interface Co2OrganizationDataOutSchema
  extends Co2OrganizationDataOutListSchema {
  /**
   * Industry
   * @maxLength 150
   */
  industry?: string;
}

/** OrganizationDataOutDetailedSchema */
export interface OrganizationDataOutDetailedSchema {
  /** Id */
  id: number;
  /**
   * Name
   * Human-readable name of the company
   * @maxLength 150
   */
  name: string;
  /**
   * Vat Identifier
   * @maxLength 100
   */
  vat_identifier?: string;
  /**
   * Country
   * @maxLength 2
   * @default "PL"
   */
  country: string;
  /**
   * Admin Email
   * @maxLength 254
   */
  admin_email?: string;
  co2: Co2OrganizationDataOutSchema | null;
  lca: {} | null;
  esg: {} | null;
  tags?: string[];
  can_read_data: boolean;
  can_write_data: boolean;
}

export interface OrganizationSchema {
  id: number;
  name: string;
  can_read_data: boolean;
  can_write_data: boolean;
  active_products: ProductPlatform[];
  parent_id: number;
}

/** OrganizationDataOutSchema */
export interface OrganizationDataOutSchema extends OrganizationSchema {
  /**
   * Vat Identifier
   * @maxLength 100
   */
  vat_identifier?: string;
  /**
   * Country
   * @maxLength 2
   * @default "PL"
   */
  country: string;
  /**
   * Admin Email
   * @maxLength 254
   */
  admin_email?: string;
  co2: Co2OrganizationDataOutListSchema | null;
  esg: null;
  lca: null;
  tags: string[];
}
/**
 * SupportedCountry
 * An enumeration.
 */
export enum SupportedCountry {
  PL = 'PL',
  LT = 'LT',
  RO = 'RO',
  CZ = 'CZ',
  SK = 'SK',
  LV = 'LV',
}

/**
 * Industry
 * An enumeration.
 */
export enum Industry {
  AAgricultureForestryAndFishing = 'a_agriculture_forestry_and_fishing',
  BMiningAndQuarrying = 'b_mining_and_quarrying',
  CManufacturing = 'c_manufacturing',
  DElectricityGasSteamAndAirConditioningSupply = 'd_electricity_gas_steam_and_air_conditioning_supply',
  EWaterSupplySewerageWasteManagementAndRemediationActivities = 'e_water_supply_sewerage_waste_management_and_remediation_activities',
  FConstruction = 'f_construction',
  GWholesaleAndRetailTradeRepairOfMotorVehiclesAndMotorcycles = 'g_wholesale_and_retail_trade_repair_of_motor_vehicles_and_motorcycles',
  HTransportationAndStorage = 'h_transportation_and_storage',
  IAccommodationAndFoodServiceActivities = 'i_accommodation_and_food_service_activities',
  JInformationAndCommunication = 'j_information_and_communication',
  KFinancialAndInsuranceActivities = 'k_financial_and_insurance_activities',
  LRealEstateActivities = 'l_real_estate_activities',
  MProfessionalScientificAndTechnicalActivities = 'm_professional_scientific_and_technical_activities',
  NAdministrativeAndSupportServiceActivities = 'n_administrative_and_support_service_activities',
  OPublicAdministrationAndDefenceCompulsorySocialSecurity = 'o_public_administration_and_defence_compulsory_social_security',
  PEducation = 'p_education',
  QHumanHealthAndSocialWorkActivities = 'q_human_health_and_social_work_activities',
  RArtsEntertainmentAndRecreation = 'r_arts_entertainment_and_recreation',
  SOtherServiceActivities = 's_other_service_activities',
  TActivitiesOfHouseholdsAsEmployersUndifferentiatedGoodsAndServicesproducingActivitiesOfHouseholdsForOwnUse = 't_activities_of_households_as_employers_undifferentiated_goods_and_servicesproducing_activities_of_households_for_own_use',
  UActivitiesOfExtraterritorialOrganisationsAndBodies = 'u_activities_of_extraterritorial_organisations_and_bodies',
}
/** Co2OrganizationOnboardingSchema */
export interface Co2OrganizationOnboardingSchema {
  /** An enumeration. */
  country: SupportedCountry;
  /**
   * Admin Email
   * @format email
   */
  admin_email?: string;
  /** An enumeration. */
  reporting_freq: ReportingFreq;
  /** An enumeration. */
  industry: Industry;
}

export interface CredentialsInSchema {
  email: string;
  password: string;
}

export interface LoginTokenInSchema {
  token: string;
}

export interface ResetPasswordOutSchema {
  messages: string[];
  success: boolean;
}

export interface SendResetPasswordCodeInSchema {
  email: string;
}

export interface ConfirmResetPasswordInSchema {
  email: string;
  token: string;
  new_password: string;
}
