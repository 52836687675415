import { FieldValue } from '../Field/Fields/types';

/* eslint-disable no-unused-vars */

export enum Qv3SummaryTableType {
  Grid = 'grid',
  IndividualByField = 'individual-by-field',
  Subtopic = 'subtopic',
}

export enum FieldType {
  Boolean = 'boolean',
  Decimal = 'decimal',
  Text = 'text',
}

export interface SummaryFieldValues {
  [key: string]: FieldValue;
}

export interface SummaryFieldSchema {
  id: number;
  title: string;
  type: FieldType;
  value: SummaryFieldValues; // possible allow_many
}

export interface SummaryQuestionSchema {
  id: number;
  title: string;
  fields: SummaryFieldSchema[];
}
export interface SummaryQuestionnaireSchema {
  id: number;
  title: string;
  questions: SummaryQuestionSchema[];
}
export interface Qv3SummaryTableSchema {
  id: number;
  title: string;
  questionnaires: SummaryQuestionnaireSchema[];
}

export enum CellType {
  Value = 'value',
  Question = 'question',
}

export interface Cell {
  value: string[] | string;
  type: CellType;
}

export interface SummaryTable {
  id: number;
  title: string;
  columns: string[];
  rows: Cell[][];
}
