import React, { useState } from 'react';
import LcaDriagramManagement from 'src/Lca/LCADiagram/Management';
import LCADiagram from 'src/Lca/LCADiagram';
import MonteCarloDiagram from 'src/Lca/LCADiagram/MonteCarloDiagram';

import { LcaManagementTab } from 'src/Lca/LCADiagram/Management/index.types';
import ImpactGrid from 'src/Lca/ImpactGrid';
import { Alert, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEcoinventProLicenceValid } from 'src/Lca/hooks';

export default function Diagram({ productId }: { productId: number }) {
  const [tab, setTab] = useState<LcaManagementTab>(LcaManagementTab.FLOWCHART);

  const { isEcoinventLicenceValid } = useEcoinventProLicenceValid();

  const [missingLicenceModalOpen, setMissingLicenceModalOpen] =
    useState<boolean>(!isEcoinventLicenceValid);

  const { t } = useTranslation();
  const missingLicenceAlertText = t('lca.missingLicence');

  return (
    <div style={{ padding: 10, minWidth: '1350px', maxWidth: '1550px' }}>
      <LcaDriagramManagement
        productId={productId}
        tab={tab}
        setTab={setTab}
        setMissingLicenceModalOpen={setMissingLicenceModalOpen}
      />

      {isEcoinventLicenceValid || (
        <>
          <br />
          <Alert severity="warning">{missingLicenceAlertText}</Alert>
          <Dialog
            open={missingLicenceModalOpen}
            onClose={() => setMissingLicenceModalOpen(false)}
          >
            <Alert severity="warning">{missingLicenceAlertText}</Alert>
          </Dialog>
        </>
      )}

      {tab === LcaManagementTab.FLOWCHART && (
        <LCADiagram productId={productId} />
      )}
      {tab === LcaManagementTab.IMPACT && <ImpactGrid />}
      {tab === LcaManagementTab.ANALYTICS && (
        <MonteCarloDiagram productId={productId} />
      )}
    </div>
  );
}
