/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createSoldProductsFinalProductDirectDataAPI,
  createSoldProductsFuelOrFeedstockDataAPI,
  deleteFuelProductAPI,
  editSoldProductsFuelOrFeedstockDataAPI,
  getAssetsFranchisesAndInvestmentsDataAPI,
  getCapitalGoodsDataAPI,
  getDirectEmissionsDataAPI,
  getElecricityEmissionsDataAPI,
  getFuelProductAPI,
  getNewQuestionnairePageAPIAnonymous,
  getPurchasedGoodsAndServicesDataAPI,
  getSoldProductsDataAPI,
  getTransportationAndDistributionDataAPI,
  getWasteAndOtherActivitiesDataAPI,
  postElecricityEmissionsDataAPI,
  postEmployeeSurveyDataAPI,
  putAssetsFranchisesAndInvestmentsDataAPI,
  putCapitalGoodsDataAPI,
  putDirectEmissionsDataAPI,
  putPurchasedGoodsAndServicesDataAPI,
  putTransportationAndDistributionDataAPI,
  putWasteAndOtherActivitiesDataAPI,
  editSoldProductsFinalProductDirectDataAPI,
  getSoldProductsFinalProductDirectAPI,
  deleteSoldProductsFinalProductDirectAPI,
  editSoldProductsFinalProductIndirectDataAPI,
  getSoldProductsFinalProductIndirectAPI,
  deleteSoldProductsFinalProductIndirectAPI,
  createSoldProductsFinalProductIndirectDataAPI,
  getDirectEmissionsDataEmissionSharesAPI,
  getElecricityEmissionsDataEmissionSharesAPI,
  getTransportationAndDistributionDataEmissionSharesAPI,
  getWasteAndOtherActivitiesDataEmissionSharesAPI,
  getAssetsFranchisesAndInvestmentsEmissionSharesDataAPI,
} from '../../utils';
import { RootState } from '../store';
import { DirectEmissionsData, EmployeeSurveyData, Page } from './interfaces';
import { timeConverter } from '../../utils/timeConverter';

export const setSelectedDate = createAsyncThunk(
  'setSelectedDate',
  async (date: Date) => date
);

// TODO: change API to anonymousClient
export const getEmployeeSurvey = createAsyncThunk(
  'getEmployeeSurvey',
  async (apiKey: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { selectedDate } = state.questionnaire;
    const { companyInfo } = state.app;
    const data = (await getNewQuestionnairePageAPIAnonymous(
      'questionnairepage-business-trips-and-work-commutin',
      timeConverter(selectedDate, companyInfo.reporting_freq),
      apiKey
    )) as Page;
    return {
      data,
      slug: 'questionnairepage-business-trips-and-work-commutin',
    };
  }
);

const asNumber = (value: any) =>
  value === null ? null : +value.toString().replace(',', '.');

export const postEmployeeSurveyData = createAsyncThunk(
  'postEmployeeSurveyData',
  async ({ data, token }: { data: EmployeeSurveyData; token: string }) => {
    // TODO: refactor this, add proper typing
    //       currently, `EmployeeSurveyData` might suggest that the types are `number | null`,
    //       but in fact are `string | null`
    const commute = data.commute.map((el) => ({
      type_of_commute_transport: el.type_of_commute_transport,
      times_per_week: asNumber(el.times_per_week),
      average_distance_one_way_km: asNumber(el.average_distance_one_way_km),
    }));

    const business_trips = data.business_trips.map((el) => ({
      type_of_trip_transport: el.type_of_trip_transport,
      times_in_period: asNumber(el.times_in_period),
      average_distance_per_trip_km: asNumber(el.average_distance_per_trip_km),
    }));

    const dataFormated = {
      commute,
      stays: data.stays,
      average_number_of_days_on_home_office_per_week:
        asNumber(data.average_number_of_days_on_home_office_per_week) ?? 0,
      business_trips,
    };

    return await postEmployeeSurveyDataAPI(dataFormated, token);
  }
);

export const getDirectEmissionsData = createAsyncThunk(
  'getDirectEmissionsData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getDirectEmissionsDataAPI(orgId, period)
);

export const getDirectEmissionsDataEmissionShares = createAsyncThunk(
  'getDirectEmissionsDataEmissionShares',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getDirectEmissionsDataEmissionSharesAPI(orgId, period)
);
export const getElecricityEmissionsDataEmissionShares = createAsyncThunk(
  'getElecricityEmissionsDataEmissionShares',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getElecricityEmissionsDataEmissionSharesAPI(orgId, period)
);

export const putDirectEmissionsData = createAsyncThunk(
  'putDirectEmissionsData',
  async ({
    orgId,
    period,
    data,
  }: {
    orgId: number;
    period: string;
    data: DirectEmissionsData;
  }) => {
    const stationary_combustion = data.stationary_combustion.map((el: any) => ({
      unit: el.unit,
      comment: el.comment,
      name_of_equipement: el.name_of_equipement,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
      type_of_fuel: el.type_of_fuel,
    }));

    const mobile_combustion_consumptionwise =
      data.mobile_combustion_consumptionwise.map((el: any) => ({
        unit_of_fuel: el.unit_of_fuel,
        comment: el.comment,
        name_of_equipement: el.name_of_equipement,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
        type_of_fuel: el.type_of_fuel,
      }));

    const mobile_combustion_distancewise =
      data.mobile_combustion_distancewise.map((el: any) => ({
        unit_of_distance: el.unit_of_distance,
        comment: el.comment,
        name_of_equipement: el.name_of_equipement,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
        type_of_fuel: el.type_of_fuel,
        type_of_vehicle: el.type_of_vehicle,
      }));

    const fugutive_emissions = data.fugutive_emissions.map((el: any) => ({
      comment: el.comment,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_kg_per_period: +el.amount_kg_per_period
        .toString()
        .replace(',', '.'),
      emission_type: el.emission_type,
    }));

    const process_emissions = data.process_emissions.map((el: any) => ({
      comment: el.comment,
      kg_eq_co2_per_period: +el.kg_eq_co2_per_period
        .toString()
        .replace(',', '.'),
      name_of_process: el.name_of_process,
    }));

    const newData = {
      stationary_combustion,
      mobile_combustion_consumptionwise,
      fugutive_emissions,
      process_emissions,
      mobile_combustion_distancewise,
    };

    return await putDirectEmissionsDataAPI(
      (orgId || '').toString(),
      period,
      newData
    );
  }
);

export const getElecricityEmissionsData = createAsyncThunk(
  'getElecricityEmissionsData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getElecricityEmissionsDataAPI(orgId, period)
);

export const postElecricityEmissionsData = createAsyncThunk(
  'postElecricityEmissionsData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const electricity_usage = data.electricity_usage.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const steam_usage = data.steam_usage.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const heat_usage = data.heat_usage.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const cooling_usage = data.cooling_usage.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const electric_vehicles_consumptionwise_usage =
      data.electric_vehicles_consumptionwise_usage.map((el: any) => ({
        comment: el.comment,
        emission_factor_is_known: el.emission_factor_is_known,
        electric_vehicle_energy_consumption_data_factor_unknown:
          el.emission_factor_is_known
            ? null
            : {
                name_label:
                  el.electric_vehicle_energy_consumption_data_factor_unknown
                    .name_label,
                unit: el.electric_vehicle_energy_consumption_data_factor_unknown
                  .unit,
                spending_pln_or_kwh_per_period:
                  +el.electric_vehicle_energy_consumption_data_factor_unknown.spending_pln_or_kwh_per_period
                    .toString()
                    .replace(',', '.'),
              },
        electric_vehicle_energy_consumption_data_factor_known:
          el.emission_factor_is_known
            ? {
                unit_label:
                  el.electric_vehicle_energy_consumption_data_factor_known
                    .unit_label,
                emission_factor:
                  +el.electric_vehicle_energy_consumption_data_factor_known.emission_factor
                    .toString()
                    .replace(',', '.'),
                amount:
                  +el.electric_vehicle_energy_consumption_data_factor_known.amount
                    .toString()
                    .replace(',', '.'),
              }
            : null,
      }));

    const electric_vehicles_distancewise_usage =
      data.electric_vehicles_distancewise_usage.map((el: any) => ({
        comment: el.comment,
        type_of_vehicle_label: el.type_of_vehicle_label,
        type_of_vehicle: el.type_of_vehicle,
        unit_of_distance: el.unit_of_distance,
        distance_travelled: +el.distance_travelled.toString().replace(',', '.'),
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
      }));

    const newData = {
      electricity_usage,
      steam_usage,
      heat_usage,
      cooling_usage,
      electric_vehicles_consumptionwise_usage,
      electric_vehicles_distancewise_usage,
    };

    if (organizationId)
      await postElecricityEmissionsDataAPI(organizationId, period, newData);
  }
);

export const getPurchasedGoodsAndServicesData = createAsyncThunk(
  'getPurchasedGoodsAndServicesData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getPurchasedGoodsAndServicesDataAPI(orgId, period)
);

export const getCapitalGoodsData = createAsyncThunk(
  'getCapitalGoodsData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getCapitalGoodsDataAPI(orgId, period)
);

export const putPurchasedGoodsAndServicesData = createAsyncThunk(
  'putPurchasedGoodsAndServicesData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const newData = data.map((el: any) => ({
      name_label: el.name_label,
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      good_data_factor_known: el.emission_factor_is_known
        ? {
            amount: +el.good_data_factor_known.amount
              .toString()
              .replace(',', '.'),
            factor: +el.good_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            unit_label: el.good_data_factor_known.unit_label,
          }
        : null,
      good_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            product_industry: el.good_data_factor_unknown.product_industry,
            spending_pln_per_period:
              +el.good_data_factor_unknown.spending_pln_per_period
                .toString()
                .replace(',', '.'),
          },
    }));

    if (organizationId)
      await putPurchasedGoodsAndServicesDataAPI(
        organizationId,
        period,
        newData
      );
  }
);

export const putCapitalGoodsData = createAsyncThunk(
  'putCapitalGoodsData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const newData = data.map((el: any) => ({
      name_label: el.name_label,
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      good_data_factor_known: el.emission_factor_is_known
        ? {
            amount: +el.good_data_factor_known.amount
              .toString()
              .replace(',', '.'),
            factor: +el.good_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            unit_label: el.good_data_factor_known.unit_label,
          }
        : null,
      good_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            product_industry: el.good_data_factor_unknown.product_industry,
            spending_pln_per_period:
              +el.good_data_factor_unknown.spending_pln_per_period
                .toString()
                .replace(',', '.'),
          },
    }));

    if (organizationId)
      await putCapitalGoodsDataAPI(organizationId, period, newData);
  }
);

export const getTransportationAndDistributionData = createAsyncThunk(
  'getTransportationAndDistributionData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getTransportationAndDistributionDataAPI(orgId, period)
);

export const getTransportationAndDistributionDataEmissionShares =
  createAsyncThunk(
    'getTransportationAndDistributionDataEmissionShares',
    async ({ orgId, period }: { orgId: number; period: string }) =>
      await getTransportationAndDistributionDataEmissionSharesAPI(orgId, period)
  );

export const getWasteAndOtherActivitiesDataEmissionShares = createAsyncThunk(
  'getWasteAndOtherActivitiesDataEmissionShares',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getWasteAndOtherActivitiesDataEmissionSharesAPI(orgId, period)
);

export const getAssetsFranchisesAndInvestmentsEmissionSharesData =
  createAsyncThunk(
    'getAssetsFranchisesAndInvestmentsEmissionSharesData',
    async ({ orgId, period }: { orgId: number; period: string }) =>
      await getAssetsFranchisesAndInvestmentsEmissionSharesDataAPI(
        orgId,
        period
      )
  );

export const putTransportationAndDistributionData = createAsyncThunk(
  'putTransportationAndDistributionData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    const { transportation, distribution } = data;
    const transUpstream = transportation.upstream.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      transportation_data_factor_known: el.emission_factor_is_known
        ? {
            factor: asNumber(el.transportation_data_factor_known.factor),
            amount: asNumber(el.transportation_data_factor_known.amount),
            unit_label: el.transportation_data_factor_known.unit_label,
          }
        : null,
      transportation_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            type_of_transportation:
              el.transportation_data_factor_unknown.type_of_transportation,
            distance_km: asNumber(el.transportation_data_factor_unknown.distance_km),
            weight: asNumber(el.transportation_data_factor_unknown.weight),
            weight_unit: el.transportation_data_factor_unknown.weight_unit,
          },
    }));

    const transDownstream = transportation.downstream.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      transportation_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            type_of_transportation:
              el.transportation_data_factor_unknown.type_of_transportation,
            distance_km: asNumber(el.transportation_data_factor_unknown.distance_km),
            weight: asNumber(el.transportation_data_factor_unknown.weight),
            weight_unit: el.transportation_data_factor_unknown.weight_unit,
          },
      transportation_data_factor_known: el.emission_factor_is_known
        ? {
            factor: asNumber(el.transportation_data_factor_known.factor),
            amount: asNumber(el.transportation_data_factor_known.amount),
            unit_label: el.transportation_data_factor_known.unit_label,
          }
        : null,
    }));

    const distUpstream = distribution.upstream.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      distribution_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            amount_stored_kg_in_period:
              +el.distribution_data_factor_unknown.amount_stored_kg_in_period
                .toString()
                .replace(',', '.'),
            storage_duration_days_in_period:
              +el.distribution_data_factor_unknown.storage_duration_days_in_period
                .toString()
                .replace(',', '.'),
          },
      distribution_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.distribution_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount: +el.distribution_data_factor_known.amount
              .toString()
              .replace(',', '.'),
            unit_label: el.distribution_data_factor_known.unit_label,
          }
        : null,
    }));

    const distDownstream = distribution.downstream.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      distribution_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            amount_stored_kg_in_period:
              +el.distribution_data_factor_unknown.amount_stored_kg_in_period
                .toString()
                .replace(',', '.'),
            storage_duration_days_in_period:
              +el.distribution_data_factor_unknown.storage_duration_days_in_period
                .toString()
                .replace(',', '.'),
          },
      distribution_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.distribution_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount: +el.distribution_data_factor_known.amount
              .toString()
              .replace(',', '.'),
            unit_label: el.distribution_data_factor_known.unit_label,
          }
        : null,
    }));

    const newData = {
      transportation: {
        upstream: transUpstream,
        downstream: transDownstream,
      },
      distribution: {
        upstream: distUpstream,
        downstream: distDownstream,
      },
    };

    if (organizationId)
      await putTransportationAndDistributionDataAPI(
        organizationId,
        period,
        newData
      );
  }
);

export const getWasteAndOtherActivitiesData = createAsyncThunk(
  'getWasteAndOtherActivitiesData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getWasteAndOtherActivitiesDataAPI(orgId, period)
);

export const putWasteAndOtherActivitiesData = createAsyncThunk(
  'putWasteAndOtherActivitiesData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const waste_data = data.waste_data.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const other_fuel_related_activities_data =
      data.other_fuel_related_activities_data.map((el: any) => ({
        comment: el.comment,
        type_of_fuel: el.type_of_fuel,
        amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
        unit: el.unit,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
      }));

    const other_energy_related_activities =
      data.other_energy_related_activities.map((el: any) => ({
        comment: el.comment,
        emission_factor_is_known: el.emission_factor_is_known,
        energy_usage_data_factor_unknown: el.emission_factor_is_known
          ? null
          : {
              spending_pln_or_kwh_per_period:
                +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                  .toString()
                  .replace(',', '.'),
              unit: el.energy_usage_data_factor_unknown.unit,
            },
        energy_usage_data_factor_known: el.emission_factor_is_known
          ? {
              factor: +el.energy_usage_data_factor_known.factor
                .toString()
                .replace(',', '.'),
              amount_per_period:
                +el.energy_usage_data_factor_known.amount_per_period
                  .toString()
                  .replace(',', '.'),
              unit_label: el.energy_usage_data_factor_known.unit_label,
            }
          : null,
        certified_sources_are_utilized: el.certified_sources_are_utilized,
        certified_energy_usage_data: el.certified_sources_are_utilized
          ? {
              certified_energy_type_label:
                el.certified_energy_usage_data.certified_energy_type_label,
              certified_percentge:
                +el.certified_energy_usage_data.certified_percentge
                  .toString()
                  .replace(',', '.'),
              emission_factor: +el.certified_energy_usage_data.emission_factor
                .toString()
                .replace(',', '.'),
            }
          : null,
      }));

    const other_emissions = data.other_emissions.map(
      (el: {
        comment?: string;
        name?: string;
        amount_kg_eq_co2_in_period: string;
      }) => ({
        comment: el.comment,
        name: el.name,
        amount_kg_eq_co2_in_period: +el.amount_kg_eq_co2_in_period
          .toString()
          .replace(',', '.'),
      })
    );

    const newData = {
      waste_data,
      other_fuel_related_activities_data,
      other_energy_related_activities,
      other_emissions,
    };

    if (organizationId)
      await putWasteAndOtherActivitiesDataAPI(organizationId, period, newData);
  }
);

export const getAssetsFranchisesAndInvestmentsData = createAsyncThunk(
  'getAssetsFranchisesAndInvestmentsData',
  async ({ orgId, period }: { orgId: number; period: string }) =>
    await getAssetsFranchisesAndInvestmentsDataAPI(orgId, period)
);

export const putAssetsFranchisesAndInvestmentsData = createAsyncThunk(
  'putAssetsFranchisesAndInvestmentsData',
  async (
    {
      period,
      data,
    }: {
      period: string;
      data: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const franchises = data.franchises.map(
      (el: {
        comment?: string | null;
        name: string;
        amount_kg_eq_co2_in_period: string;
      }) => ({
        comment: el.comment,
        name: el.name,
        amount_kg_eq_co2_in_period: +el.amount_kg_eq_co2_in_period
          .toString()
          .replace(',', '.'),
      })
    );

    const upstream_leased_buildings = data.upstream_leased_buildings.map(
      (el: {
        comment?: string | null;
        emission_amount_is_known: boolean;
        name: string;
        leased_building_data_emission_unknown: {
          type_of_builidng: string;
          leased_area_m2: string;
          year_of_construction: string;
        };
        leased_building_data_emission_known: {
          amount_kg_eq_co2_in_period: string;
        };
      }) => ({
        comment: el.comment,
        emission_amount_is_known: el.emission_amount_is_known,
        name: el.name,
        leased_building_data_emission_unknown: el.emission_amount_is_known
          ? null
          : {
              type_of_builidng:
                el.leased_building_data_emission_unknown.type_of_builidng,
              leased_area_m2:
                +el.leased_building_data_emission_unknown.leased_area_m2
                  .toString()
                  .replace(',', '.'),
              year_of_construction:
                el.leased_building_data_emission_unknown.year_of_construction,
            },
        leased_building_data_emission_known: el.emission_amount_is_known
          ? {
              amount_kg_eq_co2_in_period:
                +el.leased_building_data_emission_known.amount_kg_eq_co2_in_period
                  .toString()
                  .replace(',', '.'),
            }
          : null,
      })
    );

    const downstream_leased_buildings = data.downstream_leased_buildings.map(
      (el: {
        comment?: string | null;
        name: string;
        emission_amount_is_known: boolean;
        leased_building_data_emission_unknown: {
          type_of_builidng: string;
          leased_area_m2: string;
          year_of_construction: string;
        };
        leased_building_data_emission_known: {
          amount_kg_eq_co2_in_period: string;
        };
      }) => ({
        comment: el.comment,
        name: el.name,
        emission_amount_is_known: el.emission_amount_is_known,
        leased_building_data_emission_unknown: el.emission_amount_is_known
          ? null
          : {
              type_of_builidng:
                el.leased_building_data_emission_unknown.type_of_builidng,
              leased_area_m2:
                +el.leased_building_data_emission_unknown.leased_area_m2
                  .toString()
                  .replace(',', '.'),
              year_of_construction:
                el.leased_building_data_emission_unknown.year_of_construction,
            },
        leased_building_data_emission_known: el.emission_amount_is_known
          ? {
              amount_kg_eq_co2_in_period:
                +el.leased_building_data_emission_known.amount_kg_eq_co2_in_period
                  .toString()
                  .replace(',', '.'),
            }
          : null,
      })
    );

    const upstream_leased_assets = data.upstream_leased_assets.map(
      (el: any) => ({
        comment: el.comment,
        name: el.name,
        emission_amount_is_known: el.emission_amount_is_known,
        leased_asset_data_emission_unknown: el.emission_amount_is_known
          ? null
          : {
              type_of_fuel: el.leased_asset_data_emission_unknown.type_of_fuel,
              unit_of_fuel: el.leased_asset_data_emission_unknown.unit_of_fuel,
              amount_of_fuel_in_period:
                +el.leased_asset_data_emission_unknown.amount_of_fuel_in_period
                  .toString()
                  .replace(',', '.'),
              spending_on_electricity_pln_or_kwh_in_period:
                +el.leased_asset_data_emission_unknown.spending_on_electricity_pln_or_kwh_in_period
                  .toString()
                  .replace(',', '.'),
              unit_of_energy_usage:
                el.leased_asset_data_emission_unknown.unit_of_energy_usage,
            },
        leased_asset_data_emission_known: el.emission_amount_is_known
          ? {
              amount_kg_eq_co2_in_period:
                +el.leased_asset_data_emission_known.amount_kg_eq_co2_in_period
                  .toString()
                  .replace(',', '.'),
            }
          : null,
      })
    );

    const downstream_leased_assets = data.downstream_leased_assets.map(
      (el: any) => ({
        comment: el.comment,
        name: el.name,
        emission_amount_is_known: el.emission_amount_is_known,
        leased_asset_data_emission_unknown: el.emission_amount_is_known
          ? null
          : {
              type_of_fuel: el.leased_asset_data_emission_unknown.type_of_fuel,
              unit_of_fuel: el.leased_asset_data_emission_unknown.unit_of_fuel,
              amount_of_fuel_in_period:
                +el.leased_asset_data_emission_unknown.amount_of_fuel_in_period
                  .toString()
                  .replace(',', '.'),
              spending_on_electricity_pln_or_kwh_in_period:
                +el.leased_asset_data_emission_unknown.spending_on_electricity_pln_or_kwh_in_period
                  .toString()
                  .replace(',', '.'),
              unit_of_energy_usage:
                el.leased_asset_data_emission_unknown.unit_of_energy_usage,
            },
        leased_asset_data_emission_known: el.emission_amount_is_known
          ? {
              amount_kg_eq_co2_in_period:
                +el.leased_asset_data_emission_known.amount_kg_eq_co2_in_period
                  .toString()
                  .replace(',', '.'),
            }
          : null,
      })
    );
    const investments = data.investments.map((el: any) => ({
      comment: el.comment,
      name: el.name,
      emission_amount_is_known: el.emission_amount_is_known,
      investment_data_factor_unknown: el.emission_amount_is_known
        ? null
        : {
            industry: el.investment_data_factor_unknown.industry,
            investment_revenues_in_perdiod_pln:
              +el.investment_data_factor_unknown.investment_revenues_in_perdiod_pln
                .toString()
                .replace(',', '.'),
          },
      investment_data_factor_known: el.emission_amount_is_known
        ? {
            amount_kg_eq_co2_in_period:
              +el.investment_data_factor_known.amount_kg_eq_co2_in_period
                .toString()
                .replace(',', '.'),
          }
        : null,
    }));

    const newData = {
      upstream_leased_buildings,
      upstream_leased_assets,
      downstream_leased_buildings,
      downstream_leased_assets,
      franchises,
      investments,
    };

    if (organizationId)
      await putAssetsFranchisesAndInvestmentsDataAPI(
        organizationId,
        period,
        newData
      );
  }
);

export const getSoldProductsData = createAsyncThunk(
  'getSoldProductsFuelOrFeedstockData',
  async ({ period }: { period: string }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await getSoldProductsDataAPI(organizationId as number, period);
  }
);

export const createSoldProductsFuelOrFeedstockData = createAsyncThunk(
  'createSoldProductsFuelOrFeedstockData',
  async ({ period, data }: { period: string; data: any }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const fuel_or_feedstock = {
      name: data.fuel_or_feedstock.name,
      type_of_fuel: data.fuel_or_feedstock.type_of_fuel,
      unit: data.fuel_or_feedstock.unit,
      amount: +data.fuel_or_feedstock.amount.toString().replace(',', '.'),
      manual_factor:
        data.fuel_or_feedstock.manual_factor === null ||
        data.fuel_or_feedstock.manual_factor === ''
          ? null
          : +data.fuel_or_feedstock.manual_factor.toString().replace(',', '.'),
      comment: data.fuel_or_feedstock.comment,
    };

    const waste = data.waste.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const combinedData = {
      fuel_or_feedstock,
      waste,
    };
    if (organizationId)
      await createSoldProductsFuelOrFeedstockDataAPI(
        organizationId,
        period,
        combinedData
      );
  }
);

export const editSoldProductsFuelOrFeedstockData = createAsyncThunk(
  'editSoldProductsFuelOrFeedstockData',
  async (
    {
      period,
      data,
      participant_id,
    }: { period: string; data: any; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const fuel_or_feedstock = {
      name: data.fuel_or_feedstock.name,
      type_of_fuel: data.fuel_or_feedstock.type_of_fuel,
      unit: data.fuel_or_feedstock.unit,
      amount: +data.fuel_or_feedstock.amount.toString().replace(',', '.'),
      manual_factor:
        data.fuel_or_feedstock.manual_factor === null ||
        data.fuel_or_feedstock.manual_factor === ''
          ? null
          : +data.fuel_or_feedstock.manual_factor.toString().replace(',', '.'),
      comment: data.fuel_or_feedstock.comment,
    };

    const waste = data.waste.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const combinedData = {
      fuel_or_feedstock,
      waste,
    };
    if (organizationId)
      await editSoldProductsFuelOrFeedstockDataAPI(
        organizationId,
        participant_id,
        period,
        combinedData
      );
  }
);

export const getFuelProduct = createAsyncThunk(
  'getFuelProduct',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await getFuelProductAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);

export const deleteFuelProduct = createAsyncThunk(
  'deleteFuelProduct',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await deleteFuelProductAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);

export const createSoldProductsFinalProductDirectData = createAsyncThunk(
  'createSoldProductsFinalProductDirectData',
  async ({ period, data }: { period: string; data: any }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const waste_data = data.waste_data.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const fuel_data = data.fuel_data.map((el: any) => ({
      unit: el.unit,
      comment: el.comment,
      name_of_equipement: el.name_of_equipement,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
      type_of_fuel: el.type_of_fuel,
    }));

    const fugutive_emissions_data = data.fugutive_emissions_data.map(
      (el: any) => ({
        comment: el.comment,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_kg_per_period: +el.amount_kg_per_period
          .toString()
          .replace(',', '.'),
        emission_type: el.emission_type,
      })
    );

    const energy_data = data.energy_data.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const combinedData = {
      name: data.name,
      number_of_uses_or_yesrs_of_use: +data.number_of_uses_or_yesrs_of_use
        .toString()
        .replace(',', '.'),
      unit: data.unit,
      number_of_products_sold: +data.number_of_products_sold
        .toString()
        .replace(',', '.'),
      assumptions: data.assumptions,
      sales_region: data.sales_region.filter((el: string) => !!el),
      fuel_data,
      energy_data,
      fugutive_emissions_data,
      waste_data,
      other_emissions_kg_eq_co2: data.other_emissions_kg_eq_co2,
    };
    if (organizationId)
      await createSoldProductsFinalProductDirectDataAPI(
        organizationId,
        period,
        combinedData
      );
  }
);

export const editSoldProductsFinalProductDirectData = createAsyncThunk(
  'editSoldProductsFinalProductDirectData',
  async (
    {
      period,
      data,
      participant_id,
    }: { period: string; data: any; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const waste_data = data.waste_data.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const fuel_data = data.fuel_data.map((el: any) => ({
      unit: el.unit,
      comment: el.comment,
      name_of_equipement: el.name_of_equipement,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
      type_of_fuel: el.type_of_fuel,
    }));

    const fugutive_emissions_data = data.fugutive_emissions_data.map(
      (el: any) => ({
        comment: el.comment,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_kg_per_period: +el.amount_kg_per_period
          .toString()
          .replace(',', '.'),
        emission_type: el.emission_type,
      })
    );

    const energy_data = data.energy_data.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const combinedData = {
      name: data.name,
      number_of_uses_or_yesrs_of_use: +data.number_of_uses_or_yesrs_of_use
        .toString()
        .replace(',', '.'),
      unit: data.unit,
      number_of_products_sold: +data.number_of_products_sold
        .toString()
        .replace(',', '.'),
      assumptions: data.assumptions,
      sales_region: data.sales_region.filter((el: string) => !!el),
      fuel_data,
      energy_data,
      fugutive_emissions_data,
      waste_data,
      other_emissions_kg_eq_co2: data.other_emissions_kg_eq_co2,
    };
    if (organizationId)
      await editSoldProductsFinalProductDirectDataAPI(
        organizationId,
        participant_id,
        period,
        combinedData
      );
  }
);

export const getSoldProductsFinalProductDirect = createAsyncThunk(
  'getSoldProductsFinalProductDirect',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await getSoldProductsFinalProductDirectAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);

export const deleteSoldProductsFinalProductDirect = createAsyncThunk(
  'deleteSoldProductsFinalProductDirect',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await deleteSoldProductsFinalProductDirectAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);

export const createSoldProductsFinalProductIndirectData = createAsyncThunk(
  'createSoldProductsFinalProductIndirectData',
  async ({ period, data }: { period: string; data: any }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const waste_data = data.waste_data.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const fuel_data = data.fuel_data.map((el: any) => ({
      unit: el.unit,
      comment: el.comment,
      name_of_equipement: el.name_of_equipement,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
      type_of_fuel: el.type_of_fuel,
    }));

    const fugutive_emissions_data = data.fugutive_emissions_data.map(
      (el: any) => ({
        comment: el.comment,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_kg_per_period: +el.amount_kg_per_period
          .toString()
          .replace(',', '.'),
        emission_type: el.emission_type,
      })
    );

    const energy_data = data.energy_data.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const combinedData = {
      name: data.name,
      number_of_uses_or_yesrs_of_use: +data.number_of_uses_or_yesrs_of_use
        .toString()
        .replace(',', '.'),
      unit: data.unit,
      number_of_products_sold: +data.number_of_products_sold
        .toString()
        .replace(',', '.'),
      assumptions: data.assumptions,
      sales_region: data.sales_region.filter((el: string) => !!el),
      fuel_data,
      energy_data,
      fugutive_emissions_data,
      waste_data,
      other_emissions_kg_eq_co2: data.other_emissions_kg_eq_co2,
    };
    if (organizationId)
      await createSoldProductsFinalProductIndirectDataAPI(
        organizationId,
        period,
        combinedData
      );
  }
);

export const editSoldProductsFinalProductIndirectData = createAsyncThunk(
  'editSoldProductsFinalProductIndirectData',
  async (
    {
      period,
      data,
      participant_id,
    }: { period: string; data: any; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;

    const waste_data = data.waste_data.map((el: any) => ({
      comment: el.comment,
      waste_type: el.waste_type,
      amount_kg: +el.amount_kg.toString().replace(',', '.'),
      recycled_or_composed_flag: el.recycled_or_composed_flag,
      manual_factor:
        el.manual_factor === null || el.manual_factor === ''
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
    }));

    const fuel_data = data.fuel_data.map((el: any) => ({
      unit: el.unit,
      comment: el.comment,
      name_of_equipement: el.name_of_equipement,
      manual_factor:
        el.manual_factor === null
          ? null
          : +el.manual_factor.toString().replace(',', '.'),
      amount_per_period: +el.amount_per_period.toString().replace(',', '.'),
      type_of_fuel: el.type_of_fuel,
    }));

    const fugutive_emissions_data = data.fugutive_emissions_data.map(
      (el: any) => ({
        comment: el.comment,
        manual_factor:
          el.manual_factor === null
            ? null
            : +el.manual_factor.toString().replace(',', '.'),
        amount_kg_per_period: +el.amount_kg_per_period
          .toString()
          .replace(',', '.'),
        emission_type: el.emission_type,
      })
    );

    const energy_data = data.energy_data.map((el: any) => ({
      comment: el.comment,
      emission_factor_is_known: el.emission_factor_is_known,
      energy_usage_data_factor_unknown: el.emission_factor_is_known
        ? null
        : {
            spending_pln_or_kwh_per_period:
              +el.energy_usage_data_factor_unknown.spending_pln_or_kwh_per_period
                .toString()
                .replace(',', '.'),
            unit: el.energy_usage_data_factor_unknown.unit,
          },
      energy_usage_data_factor_known: el.emission_factor_is_known
        ? {
            factor: +el.energy_usage_data_factor_known.factor
              .toString()
              .replace(',', '.'),
            amount_per_period:
              +el.energy_usage_data_factor_known.amount_per_period
                .toString()
                .replace(',', '.'),
            unit_label: el.energy_usage_data_factor_known.unit_label,
          }
        : null,
      certified_sources_are_utilized: el.certified_sources_are_utilized,
      certified_energy_usage_data: el.certified_sources_are_utilized
        ? {
            certified_energy_type_label:
              el.certified_energy_usage_data.certified_energy_type_label,
            certified_percentge:
              +el.certified_energy_usage_data.certified_percentge
                .toString()
                .replace(',', '.'),
            emission_factor: +el.certified_energy_usage_data.emission_factor
              .toString()
              .replace(',', '.'),
          }
        : null,
    }));

    const combinedData = {
      name: data.name,
      number_of_uses_or_yesrs_of_use: +data.number_of_uses_or_yesrs_of_use
        .toString()
        .replace(',', '.'),
      unit: data.unit,
      number_of_products_sold: +data.number_of_products_sold
        .toString()
        .replace(',', '.'),
      assumptions: data.assumptions,
      sales_region: data.sales_region.filter((el: string) => !!el),
      fuel_data,
      energy_data,
      fugutive_emissions_data,
      waste_data,
      other_emissions_kg_eq_co2: data.other_emissions_kg_eq_co2,
    };
    if (organizationId)
      await editSoldProductsFinalProductIndirectDataAPI(
        organizationId,
        participant_id,
        period,
        combinedData
      );
  }
);

export const getSoldProductsFinalProductIndirect = createAsyncThunk(
  'getSoldProductsFinalProductIndirect',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await getSoldProductsFinalProductIndirectAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);

export const deleteSoldProductsFinalProductIndirect = createAsyncThunk(
  'deleteSoldProductsFinalProductIndirect',
  async (
    { period, participant_id }: { period: string; participant_id: string },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const { activeOrganizationId: organizationId } = state.platform;
    return await deleteSoldProductsFinalProductIndirectAPI(
      organizationId as number,
      period,
      participant_id
    );
  }
);
