import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { useEcoinventProLicenceValid } from 'src/Lca/hooks';
import { formatNumberWithPrecision } from 'src/utils/helpers';

interface Props {
  value?: number | null;
  sx?: SxProps;
}

export default function EcoinventEmission({ value, sx }: Props) {
  const { isEcoinventLicenceValid } = useEcoinventProLicenceValid();
  if (!isEcoinventLicenceValid || value === undefined || value === null)
    return <span>--</span>;
  return <Box sx={sx}>{formatNumberWithPrecision(value, 5, 2)}</Box>;
}
