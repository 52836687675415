import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import smartlookClient from 'smartlook-client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material';
import './index.css';
import './i18n';
import theme from './theme';

import ToastProvider from './Providers/ToastProvider';
import InitialRequest from './Providers/InitialRequest';
import UnauthorizedAccessProvider from './Providers/UnauthorizedAccessProvider';
import ProductRouter from './Providers/ProductRouter';
import ScrollToLocation from './Providers/ScrollToLocation';
import ConsentProvider from './Providers/ConsentProvider';

import { store } from './redux-file/store';
import registerFonts from './utils/fonts';
import registerSentry from './utils/sentry';

import { appConfig } from './config';
import { AxiosError } from 'axios';
import AuthorizedAccessProvider from './Providers/AuthorizedAccessProvider';
import AuthenticationProvider from './Providers/AuthenticationProvider';
import HelmetProvider from './Providers/HelmetProvider';
import PasswordActionProvider from './Providers/PasswordActionProvider';
import LanguageProvider from './Providers/LanguageProvider';

registerFonts(); /* registers fonts */
registerSentry(appConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        // retry up to 3 times
        if (failureCount > 3) return false;
        // don't retry client errors (< 500), it means that the request is invalid anyway
        if (
          error instanceof AxiosError &&
          typeof error.response?.status === 'number' &&
          error.response.status < 500
        )
          return false;
        return true;
      },
    },
  },
});
if (appConfig.environment === 'production') {
  smartlookClient.init(appConfig.smartlookApiKey, { region: 'eu' });
}

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HelmetProvider />
        <AuthenticationProvider />
        <ToastProvider />
        <LanguageProvider />
        <QueryClientProvider client={queryClient}>
          <Router>
            <UnauthorizedAccessProvider>
              <AuthorizedAccessProvider>
                <InitialRequest>
                  <PasswordActionProvider>
                    <ConsentProvider>
                      <ScrollToLocation>
                        <ProductRouter />
                      </ScrollToLocation>
                    </ConsentProvider>
                  </PasswordActionProvider>
                </InitialRequest>
              </AuthorizedAccessProvider>
            </UnauthorizedAccessProvider>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}
