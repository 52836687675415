import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ghgTheme } from './theme';
import AddReductionTarget from 'src/views/Pages/Targets/AddReductionTarget';
import TargetPage from 'src/views/Pages/Targets/Target';
import TargetsOverview from 'src/views/Pages/Targets/TargetsOverview';
import AboutCompany from 'src/views/Pages/AboutCompany';
import EmployeesEmissions from 'src/views/Pages/EmployeeSurvey';
import DirectEmissionQuestionnaire from 'src/views/Pages/Questionnaire/directEmission';
import ElectricityEmission from 'src/views/Pages/Questionnaire/electricityEmission';
import WasteQuestionnaire from 'src/views/Pages/Questionnaire/waste';
import IndexSoldProducts from 'src/views/Pages/Questionnaire/index-sold-products';
import FuelsAndRawMaterials from 'src/views/Pages/Questionnaire/fuels-and-raw-materials';
import FuelsAndRawMaterialsEdit from 'src/views/Pages/Questionnaire/fuels-and-raw-materials-edit';
import FinalProductDirect from 'src/views/Pages/Questionnaire/final-product-direct';
import FinalProductDirectEdit from 'src/views/Pages/Questionnaire/final-product-direct-edit';
import FinalProductIndirect from 'src/views/Pages/Questionnaire/final-product-indirect';
import FinalProductIndirectEdit from 'src/views/Pages/Questionnaire/final-product-indirect-edit';
import TransportationAndDistribution from 'src/views/Pages/Questionnaire/transportationAndDistribution';
import AssetsQuestionnaire from 'src/views/Pages/Questionnaire/assets';
import Recommendations from 'src/views/Pages/Recommendations';
import Dashboard from 'src/views/Pages/Dashboard';
import Layout, { RouteLayout } from 'src/views/components/Layout';
import GhgOrganizationSidebar from './components/OrganizationSidebar';
import TopBar from '../components/TopBar';
import {
  PermissionRoute,
  ProductHomeRoute,
  ProductOrganizationRoute,
} from '../common/routes';
import { ProductPlatform } from '../utils/api.interfaces';
import SuperDashboard from '../views/Pages/SuperDashboard';
import SuperdashboardSidebar from './components/SuperdashboardSidebar';
import Reported from './Scope3GoodsAndServices/Reported';
import ProductsFromSuppliers from './Scope3GoodsAndServices/ProductsFromSuppliers';
import Scope3GoodsAndServices from './Scope3GoodsAndServices';
import { useGhgOrganization } from '../common/hooks';
import { OrganizationTag, hasTag } from '../utils/tags';
import GhgTopBar from './components/TopBar';
import CodeOfConduct from './Scope3GoodsAndServices/CodeOfConduct';
import TransportationSummary from './Scope3TransportationSummary';

const GhgHomeRoute = () => {
  return (
    <ProductHomeRoute>
      <Layout sidebar={<SuperdashboardSidebar />} topbar={<GhgTopBar />}>
        <SuperDashboard />
      </Layout>
    </ProductHomeRoute>
  );
};

const GhgOrganizationRoute = () => {
  return <ProductOrganizationRoute product={ProductPlatform.Co2} />;
};

const GhgLayoutRoute = () => {
  return (
    <RouteLayout sidebar={<GhgOrganizationSidebar />} topbar={<GhgTopBar />} />
  );
};

const GhgScope3Routes = () => {
  const organization = useGhgOrganization();
  const hideScope3 = hasTag(organization, OrganizationTag.HideScope3);
  const showTransportationSummary = hasTag(
    organization,
    OrganizationTag.TransportationSummary
  );

  if (hideScope3) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/ghg" />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route
        path="questionnairepage-goods-and-services"
        element={<Scope3GoodsAndServices />}
      >
        <Route index element={<Reported />} />
        <Route
          path="products-from-suppliers"
          element={<ProductsFromSuppliers />}
        />
        <Route path="code-of-conduct" element={<CodeOfConduct />} />
      </Route>
      <Route
        path="questionnairepage-waste-and-other"
        element={<WasteQuestionnaire />}
      />
      <Route
        path="questionnairepage-sold-products"
        element={<IndexSoldProducts />}
      />
      <Route
        path="questionnairepage-sold-products/fuels-and-raw-materials"
        element={<FuelsAndRawMaterials />}
      />
      <Route
        path="questionnairepage-sold-products/fuels-and-raw-materials/:id"
        element={<FuelsAndRawMaterialsEdit />}
      />
      <Route
        path="questionnairepage-sold-products/final-products-direct"
        element={<FinalProductDirect />}
      />
      <Route
        path="questionnairepage-sold-products/final-products-direct/:id"
        element={<FinalProductDirectEdit />}
      />
      <Route
        path="questionnairepage-sold-products/final-products-indirect"
        element={<FinalProductIndirect />}
      />
      <Route
        path="questionnairepage-sold-products/final-products-indirect/:id"
        element={<FinalProductIndirectEdit />}
      />
      <Route
        path="questionnairepage-indirect-transportations"
        element={<TransportationAndDistribution />}
      />
      <Route
        path="questionnairepage-assets"
        element={<AssetsQuestionnaire />}
      />

      {showTransportationSummary && (
        <Route
          path="transportation-summary"
          element={<TransportationSummary />}
        />
      )}

      <Route path="employees" element={<EmployeesEmissions />} />
    </Routes>
  );
};

const Ghg = () => {
  return (
    <ThemeProvider theme={ghgTheme}>
      <Routes>
        <Route index element={<GhgHomeRoute />} />
        <Route element={<GhgOrganizationRoute />}>
          <Route element={<GhgLayoutRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            {/* Routes with required write access */}
            <Route element={<PermissionRoute writeData />}>
              <Route path="reduction-advice" element={<Recommendations />} />
              <Route path="target" element={<TargetsOverview />} />
              <Route path="target/add" element={<AddReductionTarget />} />
              <Route path="target/edit/:id" element={<AddReductionTarget />} />
              <Route path="target/:id" element={<TargetPage />} />
              <Route path="about-company" element={<AboutCompany />} />

              <Route
                path="emissions/questionnairepage-direct-emissions"
                element={<DirectEmissionQuestionnaire />}
              />
              <Route
                path="emissions/questionnairepage-electricity-emissions"
                element={<ElectricityEmission />}
              />

              <Route path="emissions/*" element={<GhgScope3Routes />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default Ghg;
