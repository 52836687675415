import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTitle } from '../../../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../theme';
import AvailableProduct from './AvailableProduct';
import { ProductAvailabilitySchema } from '../../../index.types';
import { ProductPlatform } from 'src/utils/api.interfaces';

export type AvailableProductsState = {
  availableProducts: ProductAvailabilitySchema[];
  setAvailableProducts: React.Dispatch<
    React.SetStateAction<ProductAvailabilitySchema[]>
  >;
};

const defaultAvailableProducts: ProductAvailabilitySchema[] = Object.values(
  ProductPlatform
).map((p) => ({
  product: p,
  is_active: false,
  is_available: false,
}));

export const useAvailableProducts = (): AvailableProductsState => {
  const [availableProducts, setAvailableProducts] = React.useState<
    ProductAvailabilitySchema[]
  >(defaultAvailableProducts);
  return { availableProducts, setAvailableProducts };
};

type Props = AvailableProductsState & {};

export default function AvailableProducts(props: Props) {
  const { t } = useTranslation();
  const { availableProducts, setAvailableProducts } = props;

  const updateProduct = (product: ProductAvailabilitySchema) => {
    const idx = availableProducts.findIndex(
      (p) => p.product === product.product
    );
    if (idx === -1) return;
    const newState = [...availableProducts];
    newState[idx] = { ...availableProducts[idx], ...product };
    setAvailableProducts(newState);
  };

  const isWideScreen = useMediaQuery(theme.breakpoints.up(1550));
  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin.components.panels.unit.availableProducts.title')}
      </StyledTitle>

      <StyledFlex sx={{ flexDirection: isWideScreen ? 'row' : 'column' }}>
        {availableProducts.map((p) => (
          <AvailableProduct
            key={p.product}
            product={p}
            updateProduct={updateProduct}
            isWideScreen={isWideScreen}
          />
        ))}
      </StyledFlex>
    </div>
  );
}
