import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddProductButton from 'src/components/AddProductButton';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import UploadExcelButton from 'src/components/UploadExcelButton';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import {
  getCapitalGoodsData,
  getPurchasedGoodsAndServicesData,
  putCapitalGoodsData,
  putPurchasedGoodsAndServicesData,
} from 'src/redux-file/questionnaires/thunks';
import { timeConverter } from 'src/utils/timeConverter';
import {
  capital_goods_cloud,
  purchased_goods_cloud,
} from 'src/views/Pages/Questionnaire/clouds_data';
import {
  PreviewBox,
  Section,
  StyledHeader,
  StyledTh,
} from 'src/views/Pages/Questionnaire/index.styles';
import MethodBox from 'src/views/components/MethodBox';
import AddButtonHolder from 'src/views/components/Questionnaire/AddButtonHolder';
import GoodsAndServicesForm from 'src/views/components/Questionnaire/forms/goodsAndServicesForm';
import GoodsPreview from 'src/views/components/Questionnaire/preview/goodsPreview';
import QuestionnaireSectionTitle from 'src/views/components/QuestionnaireSectionTitle';
import MethodBox2 from './MethodBox2';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../interfaces';
import { useGhgOrganization } from '../../../common/hooks';

export default function Reported() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    purchasedGoodsAndServicesError,
    purchasedGoodsAndServicesLoading,
    purchasedGoodsAndServices,
    capitalGoods,
    selectedDate,
    capitalGoodsError,
    capitalGoodsLoading,
  } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [newFormPG, setNewFormPG] = useState(true);
  const [newFormCG, setNewFormCG] = useState(true);
  const [showFormPG, setShowFormPG] = useState(false);
  const [showFormCG, setShowFormCG] = useState(false);

  const [active1, setActive1] = useState<undefined | number>(undefined);
  const [active2, setActive2] = useState<undefined | number>(undefined);

  const pgInit = {
    emission_factor_is_known: false,
    good_data_factor_unknown: {
      spending_pln_per_period: '',
      product_industry: '',
    },
    good_data_factor_known: {
      factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
    added_by: '',
    emission_kg_eq_co2: '',
    fraction: '',
    name_label: '',
  };

  const [purchasedGoodsState, setPurchasedGoods] = useState([pgInit]);
  const [capitalGoodsState, setCapitalGoods] = useState([pgInit]);

  const purchased_ref = useRef(null);
  const capital_ref = useRef(null);

  useEffect(() => {
    if (purchasedGoodsAndServices.length === 0) {
      setNewFormPG(true);
    } else {
      setNewFormPG(false);
    }

    if (capitalGoods.length === 0) {
      setNewFormCG(true);
    } else {
      setNewFormCG(false);
    }

    setShowFormPG(false);
    setShowFormCG(false);

    setPurchasedGoods(
      purchasedGoodsAndServices.length !== 0
        ? purchasedGoodsAndServices
        : [pgInit]
    );
  }, [purchasedGoodsAndServices, selectedDate]);

  useEffect(() => {
    if (capitalGoods.length === 0) {
      setNewFormCG(true);
    } else {
      setNewFormCG(false);
    }

    setShowFormCG(false);

    setCapitalGoods(capitalGoods.length !== 0 ? capitalGoods : [pgInit]);
  }, [capitalGoods, selectedDate]);

  const refreshPurchased = () => {
    dispatch(
      getPurchasedGoodsAndServicesData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  const refreshCapitalGoods = () => {
    dispatch(
      getCapitalGoodsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  useEffect(() => {
    refreshPurchased();
    refreshCapitalGoods();
  }, [selectedDate, organization, reportingPeriod]);

  const sendPurchased = (data: any) => {
    dispatch(
      putPurchasedGoodsAndServicesData({
        data,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  const sendCapitalGoods = (data: any) => {
    dispatch(
      putCapitalGoodsData({
        data,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  const combineGoodsData = (goodsData: any, newData: any) => {
    const validGoods =
      goodsData?.length === 1 && goodsData[0].emission_kg_eq_co2 === ''
        ? []
        : goodsData;
    return [...newData, ...validGoods];
  };

  return (
    <>
      {(purchasedGoodsAndServicesLoading || capitalGoodsLoading) && (
        <PageLoading />
      )}
      {(purchasedGoodsAndServicesError || capitalGoodsError) && (
        <SomethingWentWrong />
      )}
      {(!purchasedGoodsAndServicesLoading || !capitalGoodsLoading) &&
        (!purchasedGoodsAndServicesError || !capitalGoodsError) && (
          <>
            <Section>
              <div ref={purchased_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('goods-and-services.question1.title')}
                boxContent={purchased_goods_cloud}
                active={active1}
              />
              <UploadExcelButton
                name={t('goods-and-services.question1.title')}
                slug="GoodData"
                fullData={purchasedGoodsState}
                combineData={combineGoodsData}
                sendData={sendPurchased}
                refresh={refreshPurchased}
              />
              <br />

              {newFormPG && (
                <div>
                  {!showFormPG ? (
                    <MethodBox
                      active
                      title={t('goods-and-services.question1.method1.title')}
                      point1={t('goods-and-services.question1.method1.point1')}
                      buttonAction={() => setShowFormPG(true)}
                      buttonText={t(
                        'goods-and-services.question1.method1.button'
                      )}
                      based={t('goods-and-services.question1.method1.based')}
                      desc={t('goods-and-services.question1.method1.desc')}
                      point2={t('goods-and-services.question1.method1.point2')}
                    />
                  ) : (
                    <PreviewBox>
                      {purchasedGoodsState.map((_, id) => (
                        <GoodsAndServicesForm
                          setNewForm={setNewFormPG}
                          variant="purchased"
                          active={active1}
                          setActive={setActive1}
                          key={id}
                          id={id}
                          state={purchasedGoodsState}
                          setState={setPurchasedGoods}
                        />
                      ))}
                    </PreviewBox>
                  )}
                </div>
              )}
              {!newFormPG && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={33}>
                        {t('goods-and-services.question1.table.column1')}
                      </StyledTh>
                      <StyledTh width={33}>
                        {t('goods-and-services.question1.table.column4')}
                      </StyledTh>
                      <StyledTh width={33}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {purchasedGoodsState.map((_, id) => {
                        if (active1 === id) {
                          return (
                            <GoodsAndServicesForm
                              setNewForm={setNewFormPG}
                              variant="purchased"
                              active={active1}
                              key={id}
                              id={id}
                              state={purchasedGoodsState}
                              setState={setPurchasedGoods}
                              setActive={setActive1}
                            />
                          );
                        } else {
                          return (
                            <GoodsPreview
                              setState={setPurchasedGoods}
                              key={id}
                              state={purchasedGoodsState}
                              id={id}
                              setActive={setActive1}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddProductButton
                    onClick={() => {
                      setPurchasedGoods([pgInit, ...purchasedGoodsState]);
                      setActive1(0);
                      if (purchased_ref && purchased_ref.current) {
                        // @ts-ignore
                        purchased_ref.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                </>
              )}
              <br />
              <MethodBox2
                type={GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES}
              />
              <br />
              <MethodBox
                active
                title={t('goods-and-services.question1.method3.title')}
                point1={t('goods-and-services.question1.method3.point1')}
                based={t('goods-and-services.question1.method3.based')}
                desc={t('goods-and-services.question1.method3.desc')}
              />
            </Section>
            <Section>
              <div ref={capital_ref} />
              <QuestionnaireSectionTitle
                active={active2}
                level="primary"
                text={t('goods-and-services.question2.title')}
                boxContent={capital_goods_cloud}
              />

              <UploadExcelButton
                name={t('goods-and-services.question1.title')}
                slug="GoodData"
                fullData={capitalGoodsState}
                combineData={combineGoodsData}
                sendData={sendCapitalGoods}
                refresh={refreshCapitalGoods}
              />
              <br />

              {newFormCG && (
                <div>
                  {!showFormCG ? (
                    <MethodBox
                      active
                      title={t('goods-and-services.question2.method1.title')}
                      point1={t('goods-and-services.question2.method1.point1')}
                      buttonAction={() => setShowFormCG(true)}
                      buttonText={t(
                        'goods-and-services.question2.method1.button'
                      )}
                      based={t('goods-and-services.question2.method1.based')}
                      desc={t('goods-and-services.question2.method1.desc')}
                      point2={t('goods-and-services.question2.method1.point2')}
                    />
                  ) : (
                    <PreviewBox>
                      {capitalGoodsState.map((_, id) => (
                        <GoodsAndServicesForm
                          setNewForm={setNewFormCG}
                          variant="capital"
                          active={active2}
                          setActive={setActive2}
                          key={id}
                          id={id}
                          state={capitalGoodsState}
                          setState={setCapitalGoods}
                        />
                      ))}
                    </PreviewBox>
                  )}
                </div>
              )}
              {!newFormCG && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={33}>
                        {t('goods-and-services.question2.table.column1')}
                      </StyledTh>
                      <StyledTh width={33}>
                        {t('goods-and-services.question2.table.column4')}
                      </StyledTh>
                      <StyledTh width={33}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {capitalGoodsState.map((_, id) => {
                        if (active2 === id) {
                          return (
                            <GoodsAndServicesForm
                              setNewForm={setNewFormCG}
                              variant="capital"
                              active={active2}
                              setActive={setActive2}
                              key={id}
                              id={id}
                              state={capitalGoodsState}
                              setState={setCapitalGoods}
                            />
                          );
                        } else {
                          return (
                            <GoodsPreview
                              setState={setCapitalGoods}
                              key={id}
                              state={capitalGoodsState}
                              id={id}
                              setActive={setActive2}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setCapitalGoods([pgInit, ...capitalGoodsState]);
                          setActive2(0);
                          if (capital_ref && capital_ref.current) {
                            // @ts-ignore
                            capital_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('goods-and-services.question2.title')}
                        slug="GoodData"
                        fullData={capitalGoodsState}
                        combineData={combineGoodsData}
                        sendData={sendCapitalGoods}
                        refresh={refreshCapitalGoods}
                      />
                    }
                  />
                </>
              )}
              <br />
              <MethodBox2
                type={GOODS_AND_SERVICES_OR_CAPITAL_GOODS.CAPITAL_GOODS}
              />
              <br />
              <MethodBox
                active
                title={t('goods-and-services.question2.method3.title')}
                point1={t('goods-and-services.question2.method3.point1')}
                based={t('goods-and-services.question2.method3.based')}
                desc={t('goods-and-services.question2.method3.desc')}
              />
            </Section>
          </>
        )}
    </>
  );
}
