import React from 'react';
import Container from '../components/Container';
import Title from '../components/Title';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBasicSurveyMeta } from '../hooks';
import assets from '../assets';
import SomethingWentWrong from '../../../../../components/SomethingWentWrong';
import PageLoading from '../../../../../components/PageLoading';

export default function StakeholderSurveyEnd() {
  const { t } = useTranslation();
  const { token } = useParams();
  const { data: meta, isError } = useBasicSurveyMeta(token);

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;

  return (
    <Container header={<img src={assets.outro.image} />}>
      <div>
        <Title
          title={t('esg.stakeholderSurvey.title')}
          period={meta.report_period}
        />
        <Trans i18nKey="esg.stakeholderSurvey.outro.message" />
      </div>
    </Container>
  );
}
