/* eslint-disable no-unused-vars */

import React from 'react';
import { createTheme } from '@mui/material';
import { body1, heading6, captionText } from 'src/typography';
import '@mui/material/styles/createTypography';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    heading6?: React.CSSProperties;
    captionText?: React.CSSProperties;
  }
  interface Typography {
    heading6: React.CSSProperties;
    captionText: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading6: true;
    captionText: true;
  }
}
const superadminTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    body1,
    heading6,
    captionText,
  },
  palette: {
    primary: {
      main: '#38414F',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#38414F',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#38414F',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '0.36px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
          '&.OrganizationUnitSearchTextField': {
            '& .MuiInputBase-root': {
              borderRadius: '32px',
            },
            '& input::placeholder': {
              color: '#38414F',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontStyle: 'italic',
              fontWeight: 400,
              lineHeight: '18px',
              letterSpacing: '0.32px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '28px',
          minWidth: '192px',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '15px',
          minHeight: '48px',
          textTransform: 'none',
          padding: '10px 30px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-sizeSmall': {
            minHeight: '32px',
            minWidth: '100px',
            padding: '5px 20px',
            fontSize: '13px',
            lineHeight: '18px',
          },
        },
        textSecondary: {
          color: '#38414F',
          border: '1px solid #D9D9D9',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          border: '1px solid #9E9E9E',
          minWidth: '192px',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          textTransform: 'none',
          letterSpacing: '0.32px',
          color: '#38414F',
          '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: '#38414F',
            cursor: 'default',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-outlined': {
            backgroundColor: '#F2F2F2',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderColor: '#F2F2F2',
          },
        },
      },
    },
  },
});

export default superadminTheme;
