import React from 'react';
import { useTranslation } from 'react-i18next';
import MethodBox from 'src/views/components/MethodBox';
import InviteSupplierModal from '../components/InviteSupplierModal';
import { useAppSelector } from 'src/redux-file/hooks';
import ProductTable from './ProductTable';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { timeConverter } from 'src/utils/timeConverter';
import { useSupplierGoods } from '../hooks';
import { useGhgOrganization } from '../../../common/hooks';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

export default function MethodBox2({ type }: Props) {
  const { t } = useTranslation();
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const { selectedDate } = useAppSelector((state) => state.app);

  const _reportingPeriod = timeConverter(
    selectedDate || new Date(),
    reportingPeriod
  );

  const question =
    type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? 'question1'
      : 'question2';

  const [inviteSupplierModalOpen, setInviteSupplierModalOpen] =
    React.useState<boolean>(false);

  const { query } = useSupplierGoods(
    organization.id,
    `?type=${type}&reporting_period=${_reportingPeriod}`
  );

  if (query.data === undefined) return null;

  return (
    <>
      {query.data.length > 0 ? (
        <ProductTable
          data={query.data}
          onAddNewRow={() => setInviteSupplierModalOpen(true)}
          type={type}
        />
      ) : (
        <MethodBox
          active
          title={t(`goods-and-services.${question}.method2.title`)}
          point1={t(`goods-and-services.${question}.method2.point1`)}
          buttonAction={() => setInviteSupplierModalOpen(true)}
          buttonText={t(`goods-and-services.${question}.method2.button`)}
          based={t(`goods-and-services.${question}.method2.based`)}
          desc={t(`goods-and-services.${question}.method2.desc`)}
          point2={t(`goods-and-services.${question}.method2.point2`)}
        />
      )}
      <InviteSupplierModal
        open={inviteSupplierModalOpen}
        onClose={() => setInviteSupplierModalOpen(false)}
      />
    </>
  );
}
