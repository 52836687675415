import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  deleteTaskList,
  getRecommendations,
  getTaskList,
  postTaskList,
  patchTaskList,
} from '../../../redux-file/thunk';
import Box from '../../components/Box';
import { FirstColumn, Grid, SecondColumn, Description } from './index.styles';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import TargetsAchieved from '../../components/TargetsAchieved';
import TaskList from '../../components/TaskList';
import { getOrgStats } from '../../../redux-file/charts/thunk';
import { getUserData } from '../../../redux-file/session/thunks';
import RecommendationItem from '../../components/RecomendationItem';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import {
  PageContainer,
  SectionGroupContainer,
} from '../Questionnaire/index.styles';

export default function Recommendations() {
  const {
    recommendations,
    recommendationsLoading,
    recommendationsError,
    targetStatsError,
    taskList,
    taskListLoading,
    taskListError,
  } = useAppSelector((state) => state.app);
  const { language } = useAppSelector((state) => state.platform);

  const organization = useGhgOrganization();

  const { orgStats, orgStatsError, orgStatsLoading } = useAppSelector(
    (state) => state.charts
  );
  const loading = recommendationsLoading || taskListLoading || orgStatsLoading;
  const error =
    targetStatsError || recommendationsError || taskListError || orgStatsError;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    dispatch(getRecommendations(organization.id));
    dispatch(getTaskList(organization.id));
    dispatch(
      getOrgStats({
        organizationId: organization.id,
      })
    );
  }, [organization, language]);

  if (loading) return <PageLoading />;
  if (error) return <SomethingWentWrong />;

  const { targets_count, targets_achieved_count } = orgStats.reduce(
    (acc, rec) => ({
      ...acc,
      [rec.name.replaceAll('-', '_')]: rec.value,
    }),
    {}
  ) as { targets_count: number | null; targets_achieved_count: number | null };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle text={t('recommendations.page-title')} />
        <Description>{t('recommendations.description')}</Description>
        <Grid>
          <FirstColumn>
            {recommendations.length > 0 ? (
              recommendations.map((el, i) => (
                <RecommendationItem key={el.id} id={i} item={el} />
              ))
            ) : (
              <p>{t('recommendations.no-data')}</p>
            )}
          </FirstColumn>
          <SecondColumn>
            <Box text="recommendations.achieved-box" recommendations>
              <TargetsAchieved
                numberOfAchievedTargets={targets_achieved_count || 0}
                numberOfTargets={targets_count || 0}
              />
            </Box>
            <Box text="recommendations.task-list" recommendations>
              <TaskList
                tasks={taskList}
                deleteTask={(id: number) => dispatch(deleteTaskList(id))}
                addTask={(label: string) =>
                  dispatch(
                    postTaskList({
                      organizationId: organization.id,
                      title: label,
                    })
                  )
                }
                changeTask={(id: number, checked: boolean) =>
                  dispatch(
                    patchTaskList({
                      organizationId: organization.id,
                      id,
                      checked,
                    })
                  )
                }
              />
            </Box>
          </SecondColumn>
        </Grid>
      </SectionGroupContainer>
    </PageContainer>
  );
}
