import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS,
  GhgSupplierSurvey,
  GhgSupplierGoods,
} from '../interfaces';

export const useSupplierSurvey = (organizationId: number) => {
  const endpointUrl = `/web/ghg-suppliers/${organizationId}/survey`;
  const query = useQuery(['ghg-supplier', 'surveys', { organizationId }], () =>
    client
      .get<GhgSupplierSurvey[]>(`${endpointUrl}/all`)
      .then((response) => response.data)
  );

  const create = useMutation({
    mutationFn: (data: {
      product_name: string;
      supplier_name: string;
      organization_id: number;
    }) => client.post(endpointUrl, data),
    onSuccess: () => query.refetch(),
  });

  const remove = useMutation({
    mutationFn: (surveyId: number) =>
      client.delete(`${endpointUrl}/${surveyId}`),
    onSuccess: () => query.refetch(),
  });

  return {
    ...query,
    create,
    remove,
  };
};

export const useSupplierGoods = (
  organizationId: number,
  filter: string = ''
) => {
  const queryClient = useQueryClient();
  const endpointUrl = `/web/ghg-suppliers/${organizationId}/goods`;
  const query = useQuery(
    ['ghg-supplier', 'goods', { organizationId, filter }],
    () =>
      client
        .get<GhgSupplierGoods[]>(`${endpointUrl}/all${filter}`)
        .then((response) => response.data)
  );
  const create = useMutation({
    mutationFn: (data: {
      survey_answer_id: number;
      amount: number;
      type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
      reporting_period: string;
    }) => client.post(endpointUrl, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  const update = useMutation({
    mutationFn: (data: {
      id: number;
      amount: number;
      type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
      reporting_period: string;
    }) => client.put(`${endpointUrl}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  const remove = useMutation({
    mutationFn: (reportingPeriodId: number) =>
      client.delete(`${endpointUrl}/${reportingPeriodId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  return { query, create, update, remove };
};
