import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { client } from 'src/utils/api-client';

export const useLcaObjects = () => {
  const queryClient = useQueryClient();

  const requestCopy = useMutation({
    mutationFn: (objectId: number) =>
      client.post(`/web/lca/objects/${objectId}/copy`),
    onSuccess: () => {
      toast.success('Successfully copied');
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
    },
    onError: () => {
      toast.error('error');
    },
  });

  return { requestCopy };
};

export const useLcaProcesses = () => {
  const queryClient = useQueryClient();

  const requestCopy = useMutation({
    mutationFn: (objectId: number) =>
      client.post(`/web/lca/processes/${objectId}/copy`),
    onSuccess: () => {
      toast.success('Successfully copied');
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
    },
    onError: () => {
      toast.error('error');
    },
  });

  return { requestCopy };
};
