export const units = [
  ['liters', 'liters'],
  ['tons', 'tons'],
  ['cubic_meters', 'cubic_meters'],
  ['kwh_net_cv', 'kwh_net_cv'],
  ['kwh_gross_cv', 'kwh_gross_cv'],
  ['mwh_net_cv', 'mwh_net_cv'],
  ['mwh_gross_cv', 'mwh_gross_cv'],
];

export const other_units = [
  ['kwh', 'kwh'],
  ['mwh', 'mwh'],
  ['gj', 'gj'],
];

export const type_of_builidng = [
  ['office', 'office'],
  ['commerce', 'commerce'],
  ['housing', 'housing'],
];

export const unit_uses_years = [
  ['uses', 'uses'],
  ['years', 'years'],
];

export const year_of_construction = [
  ['before_1994', 'before_1994'],
  ['years_1994_1998', 'years_1994_1998'],
  ['years_1999_2008', 'years_1999_2008'],
  ['years_2009_2013', 'years_2009_2013'],
  ['years_2014_2016', 'years_2014_2016'],
  ['years_2017_2018', 'years_2017_2018'],
  ['years_2019_2020', 'years_2019_2020'],
  ['after_2020', 'after_2020'],
];

export const choices_without_others = [
  [
    'gaseous_fuels_title',
    [
      ['gaseous_fuel.butane', 'gaseous_fuel.butane'],
      ['gaseous_fuel.cng', 'gaseous_fuel.cng'],
      ['gaseous_fuel.lng', 'gaseous_fuel.lng'],
      ['gaseous_fuel.lpg', 'gaseous_fuel.lpg'],
      ['gaseous_fuel.natural_gas', 'gaseous_fuel.natural_gas'],
      [
        'gaseous_fuel.natural_gas_100_pct_mineral_blend',
        'gaseous_fuel.natural_gas_100_pct_mineral_blend',
      ],
      ['gaseous_fuel.other_petroleum_gas', 'gaseous_fuel.other_petroleum_gas'],
      ['gaseous_fuel.propane', 'gaseous_fuel.propane'],
    ],
  ],
  [
    'liquid_fuels_title',
    [
      ['liquid_fules.aviation_spirit', 'liquid_fules.aviation_spirit'],
      [
        'liquid_fules.aviation_turbine_fuel',
        'liquid_fules.aviation_turbine_fuel',
      ],
      ['liquid_fules.burning_oil', 'liquid_fules.burning_oil'],
      [
        'liquid_fules.diesel_average_biofuel_blend',
        'liquid_fules.diesel_average_biofuel_blend',
      ],
      [
        'liquid_fules.diesel_100_pct_mineral',
        'liquid_fules.diesel_100_pct_mineral',
      ],
      ['liquid_fules.fuel_oil', 'liquid_fules.fuel_oil'],
      ['liquid_fules.gas_oil', 'liquid_fules.gas_oil'],
      ['liquid_fules.lubricants', 'liquid_fules.lubricants'],
      ['liquid_fules.naphtha', 'liquid_fules.naphtha'],
      [
        'liquid_fules.petrol_average_biofuel_blend',
        'liquid_fules.petrol_average_biofuel_blend',
      ],
      [
        'liquid_fules.petrol_100_pct_mineral',
        'liquid_fules.petrol_100_pct_mineral',
      ],
      [
        'liquid_fules.processed_fuel_oils_residual_oil',
        'liquid_fules.processed_fuel_oils_residual_oil',
      ],
      [
        'liquid_fules.processed_fuel_oils_distillate_oil',
        'liquid_fules.processed_fuel_oils_distillate_oil',
      ],
      ['liquid_fules.waste_oils', 'liquid_fules.waste_oils'],
      ['liquid_fules.marine_gas_oil', 'liquid_fules.marine_gas_oil'],
      ['liquid_fules.marine_fuel_oil', 'liquid_fules.marine_fuel_oil'],
    ],
  ],
  [
    'solid_fuels_title',
    [
      ['solid_fuels.coal_industrial', 'solid_fuels.coal_industrial'],
      [
        'solid_fuels.coal_electricity_generation',
        'solid_fuels.coal_electricity_generation',
      ],
      ['solid_fuels.coal_domestic', 'solid_fuels.coal_domestic'],
      ['solid_fuels.coking_coal', 'solid_fuels.coking_coal'],
      ['solid_fuels.petroleum_coke', 'solid_fuels.petroleum_coke'],
    ],
  ],
];

export const choices = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  ...choices_without_others,
];

export const mobileChoices = [
  ['other_manual_factor', 'other_manual_factor'],
  ['cng', 'cng'],
  ['lng', 'lng'],
  ['lpg', 'lpg'],
  ['natural_gas', 'natural_gas'],
  ['natural_gas_100_pct_mineral_blend', 'natural_gas_100_pct_mineral_blend'],
  ['aviation_spirit', 'aviation_spirit'],
  ['aviation_turbine_fuel', 'aviation_turbine_fuel'],
  ['burning_oil', 'burning_oil'],
  ['diesel_average_biofuel_blend', 'diesel_average_biofuel_blend'],
  ['diesel_100_pct_mineral_diesel', 'diesel_100_pct_mineral_diesel'],
  ['fuel_oil', 'fuel_oil'],
  ['gas_oil', 'gas_oil'],
  ['lubricants', 'lubricants'],
  ['naphtha', 'naphtha'],
  ['petrol_average_biofuel_blend', 'petrol_average_biofuel_blend'],
  ['petrol_100_pct_mineral_petrol', 'petrol_100_pct_mineral_petrol'],
  ['processed_fuel_oils_residual_oil', 'processed_fuel_oils_residual_oil'],
  ['processed_fuel_oils_distillate_oil', 'processed_fuel_oils_distillate_oil'],
  ['waste_oils', 'waste_oils'],
  ['marine_gas_oil', 'marine_gas_oil'],
  ['marine_fuel_oil', 'marine_fuel_oil'],
];

export const fugutive_emissions_choices = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  [
    'kyoto_standard',
    [
      ['kyoto.carbon_dioxide', 'kyoto.carbon_dioxide'],
      ['kyoto.methane', 'kyoto.methane'],
      ['kyoto.nitrous_oxide', 'kyoto.nitrous_oxide'],
      ['kyoto.hfc_23', 'kyoto.hfc_23'],
      ['kyoto.hfc_32', 'kyoto.hfc_32'],
      ['kyoto.hfc_41', 'kyoto.hfc_41'],
      ['kyoto.hfc_125', 'kyoto.hfc_125'],
      ['kyoto.hfc_134', 'kyoto.hfc_134'],
      ['kyoto.hfc_134a', 'kyoto.hfc_134a'],
      ['kyoto.hfc_143', 'kyoto.hfc_143'],
      ['kyoto.hfc_143a', 'kyoto.hfc_143a'],
      ['kyoto.hfc_152a', 'kyoto.hfc_152a'],
      ['kyoto.hfc_227ea', 'kyoto.hfc_227ea'],
      ['kyoto.hfc_236fa', 'kyoto.hfc_236fa'],
      ['kyoto.hfc_245fa', 'kyoto.hfc_245fa'],
      ['kyoto.hfc_43_i0mee', 'kyoto.hfc_43_i0mee'],
      ['kyoto.perfluoromethane_pfc_14', 'kyoto.perfluoromethane_pfc_14'],
      ['kyoto.perfluoroethane_pfc_116', 'kyoto.perfluoroethane_pfc_116'],
      ['kyoto.perfluoropropane_pfc_218', 'kyoto.perfluoropropane_pfc_218'],
      [
        'kyoto.perfluorocyclobutane_pfc_318',
        'kyoto.perfluorocyclobutane_pfc_318',
      ],
      ['kyoto.perfluorobutane_pfc_3_1_10', 'kyoto.perfluorobutane_pfc_3_1_10'],
      [
        'kyoto.perfluoropentane_pfc_4_1_12',
        'kyoto.perfluoropentane_pfc_4_1_12',
      ],
      ['kyoto.perfluorohexane_pfc_5_1_14', 'kyoto.perfluorohexane_pfc_5_1_14'],
      ['kyoto.pfc_9_1_18', 'kyoto.pfc_9_1_18'],
      ['kyoto.perfluorocyclopropane', 'kyoto.perfluorocyclopropane'],
      ['kyoto.sulphur_hexafluoride_sf6', 'kyoto.sulphur_hexafluoride_sf6'],
      ['kyoto.hfc_152', 'kyoto.hfc_152'],
      ['kyoto.hfc_161', 'kyoto.hfc_161'],
      ['kyoto.hfc_236cb', 'kyoto.hfc_236cb'],
      ['kyoto.hfc_236ea', 'kyoto.hfc_236ea'],
      ['kyoto.hfc_245ca', 'kyoto.hfc_245ca'],
      ['kyoto.hfc_365mfc', 'kyoto.hfc_365mfc'],
      ['kyoto.nitrogen_trifluoride', 'kyoto.nitrogen_trifluoride'],
    ],
  ],
  [
    'blends',
    [
      ['blends.R401A', 'blends.R401A'],
      ['blends.R401B', 'blends.R401B'],
      ['blends.R401C', 'blends.R401C'],
      ['blends.R402A', 'blends.R402A'],
      ['blends.R402B', 'blends.R402B'],
      ['blends.R403A', 'blends.R403A'],
      ['blends.R403B', 'blends.R403B'],
      ['blends.R404A', 'blends.R404A'],
      ['blends.R405A', 'blends.R405A'],
      ['blends.R407A', 'blends.R407A'],
      ['blends.R407B', 'blends.R407B'],
      ['blends.R407C', 'blends.R407C'],
      ['blends.R407D', 'blends.R407D'],
      ['blends.R407E', 'blends.R407E'],
      ['blends.R407F', 'blends.R407F'],
      ['blends.R408A', 'blends.R408A'],
      ['blends.R410A', 'blends.R410A'],
      ['blends.R410B', 'blends.R410B'],
      ['blends.R411A', 'blends.R411A'],
      ['blends.R411B', 'blends.R411B'],
      ['blends.R412A', 'blends.R412A'],
      ['blends.R413A', 'blends.R413A'],
      ['blends.R415A', 'blends.R415A'],
      ['blends.R415B', 'blends.R415B'],
      ['blends.R416A', 'blends.R416A'],
      ['blends.R417A', 'blends.R417A'],
      ['blends.R417B', 'blends.R417B'],
      ['blends.R417C', 'blends.R417C'],
      ['blends.R418A', 'blends.R418A'],
      ['blends.R419A', 'blends.R419A'],
      ['blends.R419B', 'blends.R419B'],
      ['blends.R420A', 'blends.R420A'],
      ['blends.R421A', 'blends.R421A'],
      ['blends.R421B', 'blends.R421B'],
      ['blends.R422A', 'blends.R422A'],
      ['blends.R422B', 'blends.R422B'],
      ['blends.R422C', 'blends.R422C'],
      ['blends.R422D', 'blends.R422D'],
      ['blends.R422E', 'blends.R422E'],
      ['blends.R423A', 'blends.R423A'],
      ['blends.R424A', 'blends.R424A'],
      ['blends.R425A', 'blends.R425A'],
      ['blends.R426A', 'blends.R426A'],
      ['blends.R427A', 'blends.R427A'],
      ['blends.R428A', 'blends.R428A'],
      ['blends.R429A', 'blends.R429A'],
      ['blends.R430A', 'blends.R430A'],
      ['blends.R431A', 'blends.R431A'],
      ['blends.R434A', 'blends.R434A'],
      ['blends.R435A', 'blends.R435A'],
      ['blends.R437A', 'blends.R437A'],
      ['blends.R438A', 'blends.R438A'],
      ['blends.R439A', 'blends.R439A'],
      ['blends.R440A', 'blends.R440A'],
      ['blends.R442A', 'blends.R442A'],
      ['blends.R444A', 'blends.R444A'],
      ['blends.R445A', 'blends.R445A'],
      ['blends.R500', 'blends.R500'],
      ['blends.R503', 'blends.R503'],
      ['blends.R504', 'blends.R504'],
      ['blends.R507A', 'blends.R507A'],
      ['blends.R508A', 'blends.R508A'],
      ['blends.R508B', 'blends.R508B'],
      ['blends.R509A', 'blends.R509A'],
      ['blends.R511A', 'blends.R511A'],
      ['blends.R512A', 'blends.R512A'],
    ],
  ],
];

export const waste_types = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  [
    'construction_title',
    [
      ['construction.aggregates', 'construction.aggregates'],
      [
        'construction.average_construction',
        'construction.average_construction',
      ],
      ['construction.asbestos', 'construction.asbestos'],
      ['construction.asphalt', 'construction.asphalt'],
      ['construction.bricks', 'construction.bricks'],
      ['construction.concrete', 'construction.concrete'],
      ['construction.insulation', 'construction.insulation'],
      ['construction.metals', 'construction.metals'],
      ['construction.soils', 'construction.soils'],
      ['construction.mineral_oil', 'construction.mineral_oil'],
      ['construction.plasterboard', 'construction.plasterboard'],
      ['construction.tyres', 'construction.tyres'],
      ['construction.wood', 'construction.wood'],
    ],
  ],
  [
    'refuse_title',
    [
      ['refuse.household_residual_waste', 'refuse.household_residual_waste'],
      [
        'refuse.organic_food_and_drink_waste',
        'refuse.organic_food_and_drink_waste',
      ],
      ['refuse.organic_garden_waste', 'refuse.organic_garden_waste'],
      [
        'refuse.organic_mixed_food_and_garden_waste',
        'refuse.organic_mixed_food_and_garden_waste',
      ],
      [
        'refuse.commercial_and_industrial_waste',
        'refuse.commercial_and_industrial_waste',
      ],
    ],
  ],
  [
    'others',
    [
      ['other.books', 'other.books'],
      ['other.glass', 'other.glass'],
      ['other.clothing', 'other.clothing'],
    ],
  ],
  [
    'electrical_items_title',
    [
      [
        'electrical_items.weee_fridges_and_freezers',
        'electrical_items.weee_fridges_and_freezers',
      ],
      ['electrical_items.weee_large', 'electrical_items.weee_large'],
      ['electrical_items.weee_mixed', 'electrical_items.weee_mixed'],
      ['electrical_items.weee_small', 'electrical_items.weee_small'],
      ['electrical_items.batteries', 'electrical_items.batteries'],
    ],
  ],
  [
    'metal_title',
    [
      [
        'metal.aluminium_cans_and_foil_excl_forming',
        'metal.aluminium_cans_and_foil_excl_forming',
      ],
      ['metal.mixed_cans', 'metal.mixed_cans'],
      ['metal.scrap_metal', 'metal.scrap_metal'],
      ['metal.steel_cans', 'metal.steel_cans'],
    ],
  ],
  [
    'plastic_title',
    [
      ['plastic.average_plastics', 'plastic.average_plastics'],
      ['plastic.average_plastic_film', 'plastic.average_plastic_film'],
      ['plastic.average_plastic_rigid', 'plastic.average_plastic_rigid'],
      ['plastic.hdpe_incl_forming', 'plastic.hdpe_incl_forming'],
      [
        'plastic.ldpe_and_lldpe_incl_forming',
        'plastic.ldpe_and_lldpe_incl_forming',
      ],
      ['plastic.pet_incl_forming', 'plastic.pet_incl_forming'],
      ['plastic.pp_incl_forming', 'plastic.pp_incl_forming'],
      ['plastic.ps_incl_forming', 'plastic.ps_incl_forming'],
      ['plastic.pvc_incl_forming', 'plastic.pvc_incl_forming'],
    ],
  ],
  [
    'paper_title',
    [
      ['paper.paper_and_board_board', 'paper.paper_and_board_board'],
      ['paper.paper_and_board_mixed', 'paper.paper_and_board_mixed'],
      ['paper.paper_and_board_paper', 'paper.paper_and_board_paper'],
    ],
  ],
  [
    'sewage_treatment',
    [
      ['sewage_treatment.sewers_etc', 'sewage_treatment.sewers_etc'],
      ['sewage_treatment.gravity_etc', 'sewage_treatment.gravity_etc'],
      ['sewage_treatment.composting_etc', 'sewage_treatment.composting_etc'],
    ],
  ],
  [
    'sewage_disposal',
    [
      ['sewage_disposal.raw_limed_cake', 'sewage_disposal.raw_limed_cake'],
      ['sewage_disposal.raw_sludge', 'sewage_disposal.raw_sludge'],
      [
        'sewage_disposal.anaerobically_digested_sludge',
        'sewage_disposal.anaerobically_digested_sludge',
      ],
      ['sewage_disposal.incineration', 'sewage_disposal.incineration'],
    ],
  ],
  [
    'hazardous_waste',
    [
      ['hazardous.industrial_landfill', 'hazardous.industrial_landfill'],
      [
        'hazardous.industrial_incineration',
        'hazardous.industrial_incineration',
      ],
    ],
  ],
  ['medical_waste', [['medical.medical_waste', 'medical.medical_waste']]],
];

export const product_industry = [
  [
    'agriculture_hunting_forestry_and_fishing',
    'industries.agriculture_hunting_forestry_and_fishing',
  ],
  ['air_transport', 'industries.air_transport'],
  [
    'basic_metals_and_fabricated_metal',
    'industries.basic_metals_and_fabricated_metal',
  ],
  [
    'chemicals_and_chemical_products',
    'industries.chemicals_and_chemical_products',
  ],
  [
    'coke_refined_petroleum_and_nuclear_fuel',
    'industries.coke_refined_petroleum_and_nuclear_fuel',
  ],
  [
    'computers_and_ofice_equipments',
    'industries.computers_and_ofice_equipments',
  ],
  ['construction', 'industries.construction'],
  ['education', 'industries.education'],
  [
    'electrical_and_optical_equipment',
    'industries.electrical_and_optical_equipment',
  ],
  ['financial_intermediation', 'industries.financial_intermediation'],
  ['food_beverages_and_tobacco', 'industries.food_beverages_and_tobacco'],
  ['furniture', 'industries.furniture'],
  ['health_and_social_work', 'industries.health_and_social_work'],
  ['hotels_and_restaurants', 'industries.hotels_and_restaurants'],
  ['inland_transport', 'industries.inland_transport'],
  ['leather_leather_and_footwear', 'industries.leather_leather_and_footwear'],
  [
    'machinery_not_elsewhere_classified',
    'industries.machinery_not_elsewhere_classified',
  ],
  [
    'manufacturing_not_elsewhere_classified_recycling',
    'industries.manufacturing_not_elsewhere_classified_recycling',
  ],
  ['mining_and_quarrying', 'industries.mining_and_quarrying'],
  ['office_consumables', 'industries.office_consumables'],
  ['other', 'industries.other'],
  [
    'other_community_social_and_personal_services',
    'industries.other_community_social_and_personal_services',
  ],
  ['other_non_metallic_mineral', 'industries.other_non_metallic_mineral'],
  [
    'other_supporting_and_auxiliary_transport_activities_activities_of_travel_agencies',
    'industries.other_supporting_and_auxiliary_transport_activities_activities_of_travel_agencies',
  ],
  ['post_and_telecommunications', 'industries.post_and_telecommunications'],
  [
    'private_households_with_employed_persons',
    'industries.private_households_with_employed_persons',
  ],
  [
    'public_admin_and_defense_compulsory_social_securitya',
    'industries.public_admin_and_defense_compulsory_social_securitya',
  ],
  [
    'pulp_paper_paper__printing_and_publishing',
    'industries.pulp_paper_paper__printing_and_publishing',
  ],
  ['real_estate_activities', 'industries.real_estate_activities'],
  [
    'renting_of_m_eq_and_other_business_activities',
    'industries.renting_of_m_eq_and_other_business_activities',
  ],
  [
    'retail_trade_except_of_motor_vehicles_and_motorcycles_repair_of_household_goods',
    'industries.retail_trade_except_of_motor_vehicles_and_motorcycles_repair_of_household_goods',
  ],
  ['rubber_and_plastics', 'industries.rubber_and_plastics'],
  [
    'sale_maintenance_and_repair_of_motor_vehicles_and_motorcycles_retail_sale_of_fuel',
    'industries.sale_maintenance_and_repair_of_motor_vehicles_and_motorcycles_retail_sale_of_fuel',
  ],
  ['textiles_and_textile_products', 'industries.textiles_and_textile_products'],
  ['transport_equipment', 'industries.transport_equipment'],
  ['water_transport', 'industries.water_transport'],
  [
    'wholesale_trade_and_commission_trade_except_of_motor_vehicles_and_motorcycles',
    'industries.wholesale_trade_and_commission_trade_except_of_motor_vehicles_and_motorcycles',
  ],
  [
    'wood_and_products_of_wood_and_cork',
    'industries.wood_and_products_of_wood_and_cork',
  ],
  [
    'services_with_a_low_equipment_level',
    'industries.services_with_a_low_equipment_level',
  ],
  [
    'services_with_high_equipment_level',
    'industries.services_with_high_equipment_level',
  ],
];

// there's no way we're translating this all by hand
export const translateTypeOfTransportation = (key: string) => {
  const baseKey = 'dropdowns.transport';
  const keys = key.split('.');
  return keys.map((key) => `$t(${baseKey}.${key})`).join(' - ');
};

export const transport_units = [
  ['van', 'transport.van'],
  ['hgv', 'transport.hgv'],
  [
    'freight_flights_short_haul_up_to_4h',
    'transport.freight_flights_short_haul_up_to_4h',
  ],
  [
    'freight_flights_long_haul_more_than_4h',
    'transport.freight_flights_long_haul_more_than_4h',
  ],
  ['freight_train', 'transport.freight_train'],
  ['sea_tanker_crude_tanker', 'transport.sea_tanker_crude_tanker'],
  ['sea_tanker_products_tanker', 'transport.sea_tanker_products_tanker'],
  ['sea_tanker_chemical_tanker', 'transport.sea_tanker_chemical_tanker'],
  ['sea_tanker_lng_tanker', 'transport.sea_tanker_lng_tanker'],
  ['sea_tanker_lpg_tanker', 'transport.sea_tanker_lpg_tanker'],
  ['cargo_ship_bulk_carrier', 'transport.cargo_ship_bulk_carrier'],
  ['cargo_ship_general_cargo', 'transport.cargo_ship_general_cargo'],
  ['cargo_ship_container_ship', 'transport.cargo_ship_container_ship'],
  ['cargo_ship_vehicle_transport', 'transport.cargo_ship_vehicle_transport'],
  ['cargo_ship_roro_ferry', 'transport.cargo_ship_roro_ferry'],
  ['cargo_ship_large_ropax_ferry', 'transport.cargo_ship_large_ropax_ferry'],
];

const transport_units_extras = [
  'hgv.artic_avg.laden_100pct',
  'hgv.artic_avg.laden_50pct',
  'hgv.artic_avg.laden_avg',
  'hgv.artic_over33t.laden_100pct',
  'hgv.artic_over33t.laden_50pct',
  'hgv.artic_over33t.laden_avg',
  'hgv.artic_upto33t.laden_100pct',
  'hgv.artic_upto33t.laden_50pct',
  'hgv.artic_upto33t.laden_avg',
  'hgv.hgv_avg.laden_100pct',
  'hgv.hgv_avg.laden_50pct',
  'hgv.rigid_avg.laden_100pct',
  'hgv.rigid_avg.laden_50pct',
  'hgv.rigid_avg.laden_avg',
  'hgv.rigid_over17t.laden_100pct',
  'hgv.rigid_over17t.laden_50pct',
  'hgv.rigid_over17t.laden_avg',
  'hgv.rigid_upto17t.laden_100pct',
  'hgv.rigid_upto17t.laden_50pct',
  'hgv.rigid_upto17t.laden_avg',
  'hgv.rigid_upto7t.laden_100pct',
  'hgv.rigid_upto7t.laden_50pct',
  'hgv.rigid_upto7t.laden_avg',
  'hgv_refrigerated.artic_avg.laden_100pct',
  'hgv_refrigerated.artic_avg.laden_50pct',
  'hgv_refrigerated.artic_avg.laden_avg',
  'hgv_refrigerated.artic_over33t.laden_100pct',
  'hgv_refrigerated.artic_over33t.laden_50pct',
  'hgv_refrigerated.artic_over33t.laden_avg',
  'hgv_refrigerated.artic_upto33t.laden_100pct',
  'hgv_refrigerated.artic_upto33t.laden_50pct',
  'hgv_refrigerated.artic_upto33t.laden_avg',
  'hgv_refrigerated.hgv_avg.laden_100pct',
  'hgv_refrigerated.hgv_avg.laden_50pct',
  'hgv_refrigerated.hgv_avg.laden_avg',
  'hgv_refrigerated.rigid_avg.laden_100pct',
  'hgv_refrigerated.rigid_avg.laden_50pct',
  'hgv_refrigerated.rigid_avg.laden_avg',
  'hgv_refrigerated.rigid_over17t.laden_100pct',
  'hgv_refrigerated.rigid_over17t.laden_50pct',
  'hgv_refrigerated.rigid_over17t.laden_avg',
  'hgv_refrigerated.rigid_upto17t.laden_100pct',
  'hgv_refrigerated.rigid_upto17t.laden_50pct',
  'hgv_refrigerated.rigid_upto17t.laden_avg',
  'hgv_refrigerated.rigid_upto7t.laden_100pct',
  'hgv_refrigerated.rigid_upto7t.laden_50pct',
  'hgv_refrigerated.rigid_upto7t.laden_avg',
  'van.class_1.diesel',
  'van.class_1.ev_battery',
  'van.class_1.petrol',
  'van.class_2.diesel',
  'van.class_2.ev_battery',
  'van.class_2.petrol',
  'van.class_3.diesel',
  'van.class_3.ev_battery',
  'van.class_3.petrol',
  'van.class_avg.cng',
  'van.class_avg.diesel',
  'van.class_avg.ev_battery',
  'van.class_avg.lpg',
  'van.class_avg.petrol',
];

transport_units.push(
  ...transport_units_extras.map((key) => [
    key,
    translateTypeOfTransportation(key),
  ])
);

export const unit_of_weight = [
  ['kg', 'kg'],
  ['tons', 'tons'],
];

export const type_of_vehicle = [
  ['other.manual_factor', 'other.manual_factor'],
  ['car_small', 'car_small'],
  ['car_medium', 'car_medium'],
  ['car_large', 'car_large'],
  ['car_average', 'car_average'],
  ['motorbike', 'motorbike'],
  ['van', 'van'],
];

export const type_of_fuel = [
  ['diesel', 'diesel'],
  ['petrol', 'petrol'],
  ['hybrid', 'hybrid'],
  ['cng', 'cng'],
  ['lpg', 'lpg'],
  ['unknown', 'unknown'],
  ['plug_in_hybrid_vehicle', 'plug_in_hybrid_vehicle'],
  ['other.manual_factor', 'other.manual_factor'],
];

export const unit_of_distance = [
  ['km', 'km'],
  ['miles', 'miles'],
];

export const vehicle_names_en = [
  ['Passenger Car', 'Passenger Car'],
  [
    'Light-duty Trucks (Vans, Pickup Trucks, SUVs)',
    'Light-duty Trucks (Vans, Pickup Trucks, SUVs)',
  ],
  ['Medium- and Heavy-duty Vehicles', 'Medium- and Heavy-duty Vehicles'],
  ['Bus', 'Bus'],
  ['Agricultural Equipment', 'Agricultural Equipment'],
  ['Ships and Boats', 'Ships and Boats'],
  ['Motorcycles', 'Motorcycles'],
  ['Other Non-Road Vehicles', 'Other Non-Road Vehicles'],
  ['Locomotives', 'Locomotives'],
  ['Construction/Mining Equipment', 'Construction/Mining Equipment'],
  ['Industrial/Commercial Equipment', 'Industrial/Commercial Equipment'],
  ['Lawn and Garden Equipment', 'Lawn and Garden Equipment'],
  ['Logging Equipment', 'Logging Equipment'],
  ['Railroad Equipment', 'Railroad Equipment'],
  ['Recreational Equipment', 'Recreational Equipment'],
  ['Electric Vehicle', 'Electric Vehicle'],
  ['other', 'other'],
];

export const vehicle_names_pl = [
  ['Samochód osobowy', 'Samochód osobowy'],
  [
    'Lekkie samochody ciężarowe (furgonetki, pickupy, SUV-y)',
    'Lekkie samochody ciężarowe (furgonetki, pickupy, SUV-y)',
  ],
  ['Pojazdy średnie i ciężkie', 'Pojazdy średnie i ciężkie'],
  ['Bus', 'Bus'],
  ['Sprzęt rolniczy', 'Sprzęt rolniczy'],
  ['Statki i łodzie', 'Statki i łodzie'],
  ['Motocykle', 'Motocykle'],
  [
    'Inne pojazdy nieporuszające się po drogach',
    'Inne pojazdy nieporuszające się po drogach',
  ],
  ['Lokomotywy', 'Lokomotywy'],
  ['Sprzęt budowlany/ górniczy', 'Sprzęt budowlany/ górniczy'],
  ['Urządzenia przemysłowe/komercyjne', 'Urządzenia przemysłowe/komercyjne'],
  [
    'Sprzęt do pielęgnacji trawników i ogrodów',
    'Sprzęt do pielęgnacji trawników i ogrodów',
  ],
  ['Sprzęt do pozyskiwania drewna', 'Sprzęt do pozyskiwania drewna'],
  ['Urządzenia kolejowe', 'Urządzenia kolejowe'],
  ['Sprzęt rekreacyjny', 'Sprzęt rekreacyjny'],
  ['Pojazd elektryczny', 'Pojazd elektryczny'],
  ['inne', 'inne'],
];
