import React, { useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

type ExpandableListItemProps = {
  defaultOpen?: boolean;
  text: string;
  children: React.ReactNode;
};

export default function ExpandableListItem(props: ExpandableListItemProps) {
  const [open, setOpen] = useState(props.defaultOpen ?? false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <ListItem>
        <ListItemButton
          onClick={toggleOpen}
          sx={open ? { backgroundColor: 'primary.light' } : undefined}
        >
          <ListItemIcon>{open ? <ExpandMore /> : <ExpandLess />}</ListItemIcon>
          <ListItemText primary={props.text} />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>{props.children}</List>
      </Collapse>
    </>
  );
}
