import React from 'react';
import { FormControl, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {
  ProductInformationProps,
  StyledTextField,
} from '../productInformation';
import { set } from 'lodash';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { SmallButton } from '../index.styles';

type LcaRegion = {
  iso_code: string;
  name: string;
};

export default function Location({
  setValues,
  values,
}: ProductInformationProps) {
  const handleLocation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, production_location: e.target.value });
  };

  const handleRegion = (e: { target: { value: string } }, i: number) => {
    const temp = { ...values };
    set(temp, `distribution_regions[${i}].iso_code`, e.target.value);
    setValues(temp);
  };

  const handleCountry = (e: { target: { value: string } }) => {
    setValues({ ...values, production_country: { iso_code: e.target.value } });
  };

  const addRegion = () => {
    setValues({
      ...values,
      distribution_regions: [
        ...values.distribution_regions,
        { iso_code: '', name: '' },
      ],
    });
  };

  const removeRegion = (i: number) => {
    setValues({
      ...values,
      distribution_regions: values.distribution_regions.splice(i - 1, 1),
    });
  };

  const { data: regionsData } = useQuery('getRegions', () => {
    return client.get<LcaRegion[]>('/web/lca/regions');
  });

  const { data: countriesData } = useQuery('getCountries', () => {
    return client.get<LcaRegion[]>('/web/countries/all');
  });
  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      {values.distribution_regions.map((_: any, i: number) => (
        <div
          key={i}
          style={{
            display: 'flex',
            gap: '24px',
            alignItems: 'center',
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Distribution region</InputLabel>
            <Select
              value={values.distribution_regions[i].iso_code}
              label="Distribution region"
              onChange={(event) => handleRegion(event, i)}
            >
              {regionsData?.data.map(
                (el: { iso_code: string; name: string }) => (
                  <MenuItem key={el.iso_code} value={el.iso_code}>
                    {el.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <SmallButton
            type="button"
            disabled={values.distribution_regions[i].iso_code === ''}
            onClick={() => removeRegion(i)}
          >
            remove
          </SmallButton>
        </div>
      ))}
      <div>
        <SmallButton
          type="button"
          onClick={addRegion}
          disabled={
            values.distribution_regions[values.distribution_regions.length - 1]
              .iso_code === ''
          }
        >
          Add another distribution region
        </SmallButton>
      </div>
      <FormControl fullWidth>
        <InputLabel>Country of production</InputLabel>
        <Select
          value={values.production_country.iso_code}
          label="Country of production"
          onChange={(event) => handleCountry(event)}
        >
          {countriesData?.data.map((el: { iso_code: string; name: string }) => (
            <MenuItem key={el.iso_code} value={el.iso_code}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <StyledTextField
        fullWidth
        label="Location of production"
        name="production_location"
        value={values.production_location}
        onChange={handleLocation}
      />
    </div>
  );
}
