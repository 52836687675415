import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { client } from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PasswordVerificationForm from 'src/components/PasswordVerificationForm';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ChangePasswordDialog(props: Props) {
  const { t } = useTranslation();
  const { open, onClose } = props;

  const [newPassword, setNewPassword] = React.useState<string>('');

  const handleClose = () => {
    setNewPassword('');
    onClose();
  };

  const handleSave = () =>
    client
      .post('/web/settings_v2/my-account/change-password', {
        new_password: newPassword,
        confirm_password: newPassword,
      })
      .then(() => {
        handleClose();
        toast.success(
          t('superadmin.myAccount.changePassword.success') as string
        );
      });
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Typography variant="h4" sx={{ p: '20px 0px' }}>
          {t('superadmin.myAccount.changePassword.title')}
        </Typography>
        <PasswordVerificationForm
          setNewPassword={setNewPassword}
          translationPrefix="superadmin.myAccount.changePassword"
        />
      </DialogContent>
      <DialogActions sx={{ m: '10px' }}>
        <Button size="small" color="secondary" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleSave}
          disabled={!newPassword}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
