import React, { useContext } from 'react';

import { Field as FieldType } from './index.types';
import { QuestionnaireContext } from '../Data/QuestionnaireContext';
import { Question } from '../Question/index.types';
import { QuestionAnswer } from '../types';
import { getFieldComponent } from './Fields';
import { FieldValue, Errors } from './Fields/types';
import _ from 'lodash';

type Props = {
  field: FieldType;
  questionAnswer: QuestionAnswer;
  question: Question;
  errors: Errors;
  onForceQuestionAnswerSave: () => void;
};

export default function Field(props: Props) {
  const { field, questionAnswer, errors, question, onForceQuestionAnswerSave } =
    props;

  const { setQuestionnaireAnswerState, setModifiedQuestionAnswers } =
    useContext(QuestionnaireContext);

  const handleChange = (value: FieldValue) => {
    // set modified values for local caching
    setModifiedQuestionAnswers((prevState) => ({
      ...prevState,
      [question.id]: {
        ...(prevState[question.id] || {}),
        [questionAnswer.id]: {
          ...questionAnswer,
          ...(prevState[question.id]?.[questionAnswer.id] || {}),
          record: { ...(questionAnswer.record || {}), [field.key]: value },
        },
      },
    }));

    // updating context state
    setQuestionnaireAnswerState((prevState) => {
      // Escaping undefined state
      if (!prevState) return prevState;

      // copying newState from prevState
      const newState = _.cloneDeep(prevState);

      // creating questionAnswers if does not exist
      // in general questionanswers should always exists in this case
      // but in TS it's defined as optional since in response.data
      // if the questionnaire has no questionanswers - this key will not appear.
      const newQuestionAnswers =
        newState.questionnaire.questions[question.key].questionanswers || {};

      // creating reacord if does not exit
      // in general record should always appear under questionanswer
      const updatedRecord = newQuestionAnswers[questionAnswer.id]?.record || {};

      // update Record
      updatedRecord[field.key] = value;

      return newState;
    });
  };

  const FieldComponent = getFieldComponent(field.visual_type);

  const _value = questionAnswer.record[field.key];
  const value = _value === null ? '' : _value;

  return (
    <FieldComponent
      field={field}
      value={value}
      onChange={handleChange}
      errors={errors}
      onForceQuestionAnswerSave={onForceQuestionAnswerSave}
    />
  );
}
