import { useQuery } from 'react-query';
import { client } from '../../../../utils/api-client';
import {
  EsgStakeholderSurveyBasicMetadataSchema,
  EsgStakeholderSurveyMetadataSchema,
} from './types';
import { useAppSelector } from '../../../../redux-file/hooks';

export const useBasicSurveyMeta = (token?: string) => {
  const endpointUrl = `/web/esg_stakeholders/stakeholders/survey_answer?token=${token}`;
  const query = useQuery(['stakeholder_survey_basic_meta', token], () =>
    client
      .get<EsgStakeholderSurveyBasicMetadataSchema>(endpointUrl)
      .then((response) => response.data)
  );

  return { endpointUrl, ...query };
};

export const useSurveyMeta = (token?: string) => {
  const endpointUrl = `/web/esg_stakeholders/stakeholders/survey_answer?token=${token}`;
  const { language } = useAppSelector((state) => state.platform);
  const query = useQuery(['stakeholder_survey_meta', token, language], () =>
    client
      .put<EsgStakeholderSurveyMetadataSchema>(endpointUrl)
      .then((response) => response.data)
  );

  return { endpointUrl, ...query };
};
